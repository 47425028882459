import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableHead, withStyles } from "@material-ui/core";
import language from "../../languages.json";

import { StickyTableCell } from "../../shared/constants/StickyTableCell.constant";

import * as Calculadora from "../../Helpers/CalculadoraPricing";

import { mostraZero } from "../../shared/utils/mostraZero";
import { mostraZeroDec } from "../../shared/utils/mostraZeroDec";
import { roundDown } from "../../shared/utils/roundDown";
import { porcentagem } from "../../shared/utils/porcentagem";
import { renderFoto } from "./renderFoto";
import { statusItem } from "../../shared/utils/statusItem";
import { cor } from "../../shared/utils/cor";

import { variacaoCustoOba } from "../../shared/utils/variacaoCustoOba";
import { possuiBandeira } from "../../shared/utils/possuiBandeira";
import { possuiBandeiraCamilo } from "../../shared/utils/possuiBandeiraCamilo";
import { VscDeviceCamera } from "react-icons/vsc";
import itensFiltro from "../../Helpers/ItensFiltro";
import languages from "../../languages.json";

export function renderTableModule(
  loader,
  rows,
  classes,
  difTotal,
  exportar,
  user,
  ordenar,
  rowsToRender,
  renderInputAtacado,
  renderInputAtacado3,
  renderInputAtacado4,
  renderInputAtacadoRedeLucas,
  handleConcorrente,
  renderConcorrente,
  sanitalize,
  quantidadeDeProdutosDatabelaDefault,
  selectedfiltro,
  renderSelectAll,
  rendergrupoArredondamentoHeader,
  rendergrupoArredondamentoBody,
  renderBackgroundColor,
  renderCheckbox,
  renderCheckboxSlider,
  renderBloqueados,
  renderInput,
  emptyRows,
  registros,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  totalDePaginas,
  TablePaginationActions,
  setPhotoCarousel,
  photoCarousel,
  lang
) {
  const checkIfIsPossuiBandeira = (flag) =>
    possuiBandeira(selectedfiltro, flag);
  //  const [lang, setLang] = React.useState(window.sessionStorage.getItem('language') || 'pt')

  const checkIfIsPossuiBandeiraCamilo = (schema, flag) =>
    possuiBandeiraCamilo(schema, selectedfiltro, flag);

  const checkPriceDifference = (preco_vigente_varejo, preco_atual_varejo) => {
    return Number(preco_vigente_varejo) < Number(preco_atual_varejo);
  };

  const getPhotos = async (codigo_pai) => {
    try {
      const photoCarousel = {
        codigo_pai: codigo_pai,
        db_schema: user.dbschema,
        nome_concorrente: "",
      };
      const response = await fetch(user.apiurl + "gestao/fotos", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(photoCarousel)
          .replaceAll("'", "")
          .replaceAll(" ", ""),
      });
      const photos = await response.json();
      const concorrentesMap = photos.options.reduce(
        (acc, { nome_concorrente_pesquisa }) => {
          const getConcorrente = photos.data.filter(
            (concorrente) =>
              concorrente.nome_concorrente_pesquisa ===
              nome_concorrente_pesquisa
          );

          acc[nome_concorrente_pesquisa] = getConcorrente;

          return acc;
        },
        {}
      );
      setPhotoCarousel(concorrentesMap);
    } catch (e) {
      console.error(e);
    }
  };

  if (loader) {
    return (
      <div className="loader_table_background">
        <div className="loader_table"></div>
      </div>
    );
  }
  if (rows.length === 0) {
    return (
      <h3 className="validados_null box--com--sombra">
        Não existem produtos validados.
      </h3>
    );
  }
  if (loader) {
    return (
      <div className="loader_table_background">
        <div className="loader_table"></div>
      </div>
    );
  }
  if (rows.length === 0) {
    return (
      <h3 className="validados_null box--com--sombra">
        Não existem produtos validados.
      </h3>
    );
  }
  return (
    <div className="tablenew">
      <form id="tabela-formulario">
        <TableContainer component={Paper} className="table-container">
          <Table
            stickyHeader
            className={classes.table}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <StickyTableCell className={classes.head}>
                  {difTotal === true && (
                    <TableCell
                      className="titulo_tabela sticky_table_cell"
                      scope="col"
                    >
                      V/B
                    </TableCell>
                  )}
                  {/*1*/}
                  {difTotal === false && (
                    <TableCell
                      className="page_cluster sticky_table_cell"
                      component="th"
                      scope="row"
                      style={{ maxWidth: "20rem" }}
                    >
                      {renderSelectAll()}
                    </TableCell>
                  )}
                  {/*1*/}
                  {exportar === true && (
                    <TableCell
                      className="page_cluster sticky_table_cell"
                      component="th"
                      scope="row"
                      style={{ maxWidth: "20rem" }}
                      onClick={(e) => ordenar("data_exportacao")}
                    >
                      Data de Exportação
                    </TableCell>
                  )}
                  {/*1*/}
                  <TableCell
                    className="page_cluster sticky_table_cell larger_cell"
                    component="th"
                    scope="row"
                    style={{ maxWidth: "20rem" }}
                    onClick={(e) => ordenar("cluster_simulador")}
                  >
                    {languages[lang].cluster}
                  </TableCell>
                  {/*39*/}
                  {["hndesc"].includes(user.dbschema) && (
                    <TableCell
                      className="page_cluster sticky_table_cell larger_cell"
                      component="th"
                      scope="col"
                      style={{ maxWidth: "20rem" }}
                      onClick={(e) => ordenar("preco_online")}
                    >
                      Canal
                    </TableCell>
                  )}
                  {/*39*/}
                  {/*2*/}
                  <TableCell
                    className="page_cluster sticky_table_cell"
                    component="th"
                    scope="row"
                    style={{ maxWidth: "20rem" }}
                    onClick={(e) => ordenar("cod_pai_proporcao")}
                  >
                    {languages[lang].codigo_principal_proporcional}
                  </TableCell>
                  {/*2*/}
                  <TableCell
                    className="page_pai sticky_table_cell"
                    component="th"
                    scope="row"
                    style={{ maxWidth: "20rem" }}
                    onClick={(e) => ordenar("codigo_pai")}
                  >
                    {languages[lang].codigo_pai}
                  </TableCell>
                  {/*3*/}
                  <TableCell
                    className="page_pai sticky_table_cell"
                    component="th"
                    scope="row"
                    onClick={(e) => ordenar("codigo_filhos")}
                  >
                    {languages[lang].codigo_sku}
                  </TableCell>
                  {/*4*/}
                  {["bigbox", "demo_pt", "demo"].includes(user.dbschema) && (
                    <TableCell
                      className="page_pai sticky_table_cell"
                      component="th"
                      scope="row"
                      style={{ maxWidth: "20rem" }}
                      onClick={(e) => ordenar("codigo_plu")}
                    >
                      PLU
                    </TableCell>
                  )}
                  <TableCell
                    className={"page_a larger_cell sticky_table_cell"}
                    component="th"
                    scope="row"
                    onClick={(e) => ordenar("descricao_produto")}
                  >
                    {languages[lang].descricao_produto}
                  </TableCell>

                  {[
                    "imec",
                    "rihappy_off",
                    "rihappy",
                    "grupo_nos",
                    "camilo",
                    "camilo_campeao",
                    "atack",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className={"page_a medium_cell sticky_table_cell"}
                      component="th"
                      scope="row"
                      onClick={(e) => ordenar("status_produto")}
                    >
                      {["camilo", "camilo_campeao"].includes(user.dbschema)
                        ? "Bloqueado"
                        : "Status do Produto"}
                    </TableCell>
                  )}
                  {["atack"].includes(user.dbschema) && (
                    <TableCell
                      className={"page_a medium_cell sticky_table_cell"}
                      scope="col"
                      onClick={(e) => ordenar("data_ultima_entrada")}
                    >
                      Data Última Entrada
                    </TableCell>
                  )}
                  {/*7*/}
                  <TableCell
                    className="page_pai sticky_table_cell"
                    scope="col"
                    onClick={(e) => ordenar("preco_vigente_varejo")}
                  >
                    {["giga", "roldao"].includes(user.dbschema)
                      ? "Preço Vigente Atacado"
                      : user.dbschema === "venancio"
                      ? "PMC"
                      : languages[lang].preco_vigente_varejo}
                  </TableCell>
                  <TableCell
                    className="page_pai sticky_table_cell"
                    scope="col"
                    onClick={(e) => ordenar("preco_atual_varejo")}
                  >
                    {["giga"].includes(user.dbschema)
                      ? "Preço Atacado Atual"
                      : languages[lang].preco_regular_atual}
                  </TableCell>
                  <TableCell
                    className="page_a larger_cell sticky_table_cell"
                    scope="col"
                  >
                    <span onClick={(e) => ordenar("sugestao_final")}>
                      {["giga", "roldao"].includes(user.dbschema)
                        ? "Novo Preço Regular Atacado"
                        : language[lang].novo_preco_regular_varejo}
                    </span>
                  </TableCell>
                  <TableCell
                    className="page_pai sticky_table_cell"
                    scope="col"
                    onClick={(e) => ordenar("custo_do_produto")}
                  >
                    {languages[lang].custo}
                  </TableCell>
                </StickyTableCell>
                {["mundopet", "independente"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("ean_simulador")}
                  >
                    EAN
                  </TableCell>
                )}
                {["dia", "palomax"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("marca")}
                  >
                    Marca
                  </TableCell>
                )}
                {/*6*/}
                {["dia"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("formato")}
                  >
                    Formato
                  </TableCell>
                )}
                {/*6*/}
                {/*7*/}
                {/*7*/}
                {["rihappy"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_minima")}
                  >
                    Preço Loja Física
                  </TableCell>
                )}
                {/*8*/}
                {/*9*/}
                {["mundopet"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("qtd_bonificada")}
                  >
                    Qtd. Bonificada
                  </TableCell>
                )}
                {["mundopet"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("custo_medio")}
                  >
                    CMV
                  </TableCell>
                )}
                {["camilo", "camilo_campeao"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("custo_tabela_fornecedor")}
                  >
                    Custo Tabela Fornecedor
                  </TableCell>
                )}
                {["redetop", "peruzzo"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("custo_medio")}
                  >
                    Custo Médio
                  </TableCell>
                )}
                {["oba"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_online")}
                  >
                    Variação Custo
                  </TableCell>
                )}
                {["camilo", "camilo_campeao"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("impostos_simulador")}
                  >
                    Custo Médio
                  </TableCell>
                )}
                {/*10*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("impostos_simulador")}
                >
                  {languages[lang].impostos}
                </TableCell>
                {/*7*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("pmz")}
                >
                  {languages[lang].pmz}
                </TableCell>
                {["oba"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("data_ultimo_custo")}
                  >
                    Data Alteração
                  </TableCell>
                )}
                {["mundopet"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_minima")}
                  >
                    Margem Mínima
                  </TableCell>
                )}
                {["mundopet"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_maxima")}
                  >
                    Margem Máxima
                  </TableCell>
                )}
                {/*7*/}
                {["bigbox", "demo_pt", "demo", "ikesaki", "redetop"].includes(
                  user.dbschema
                ) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("data_ultimo_custo")}
                  >
                    Data Última Entrada
                  </TableCell>
                )}
                {/*7*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("margem_objetiva_produto")}
                >
                  {languages[lang].margem_objetiva}
                </TableCell>
                {/*8*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("margem_atual_regular")}
                >
                  {languages[lang].margem_atual}
                </TableCell>
                {/*9*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("margem_nova")}
                >
                  {languages[lang].margem_nova}
                </TableCell>
                {"roldao".includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_atual_regular")}
                  >
                    Margem Contribuição
                  </TableCell>
                )}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("variacao_nova_margem")}
                >
                  Variação Nova Margem
                </TableCell>
                {/*10*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("sensibilidade_simulador")}
                >
                  {languages[lang].sensibilidade}
                </TableCell>
                {/* Exclusividade Rihappy:
                 * curva ABC ===  tem pesquisa
                 */}
                {["rihappy", "rihappy_off"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("curva_abc")}
                  >
                    curva ABC
                  </TableCell>
                )}
                {/*11*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("var_novo_preco")}
                >
                  {languages[lang].variacao_novo_preco}
                </TableCell>
                {/*12*/}
                {/*COLUNAS ATACADO*/}
                {["independente"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("ftp_fator")}
                  >
                    Fator Desconto
                  </TableCell>
                )}
                {/*13*/}
                {["roldao"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("quantidade_atacado_simulador")}
                  >
                    Quantidade Atacado
                  </TableCell>
                )}
                {(exportar ||
                  [
                    "demo_pt",
                    "demo",
                    "roldao",
                    "novaera",
                    "rihappy",
                    "rihappy_off",
                  ].includes(user.dbschema) ||
                  (user.dbschema === "rede_lucas"
                    ? checkIfIsPossuiBandeira("ATACADO")
                    : checkIfIsPossuiBandeira("Varejo") ||
                      checkIfIsPossuiBandeira())) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("ftp_fator")}
                  >
                    {["roldao"].includes(user.dbschema)
                      ? "Fator Varejo"
                      : ["rihappy", "rihappy_off"].includes(user.dbschema)
                      ? "Custo Tab Fornecedor"
                      : ["camilo", "camilo_campeao"].includes(user.dbschema)
                      ? "Fator Atacado 3"
                      : "Fator Atacado"}
                  </TableCell>
                )}
                {(user.dbschema === "rede_lucas"
                  ? checkIfIsPossuiBandeira("ATACADO")
                  : checkIfIsPossuiBandeira("Varejo") ||
                    (checkIfIsPossuiBandeira() &&
                      user.dbschema !== "camilo_campeao")) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_regular_atacado")}
                  >
                    Preço Atacado Atual
                  </TableCell>
                )}
                {/*13*/}
                {["independente"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("novo_preco_atacado")}
                  >
                    Novo Preço Desconto
                  </TableCell>
                )}
                {/*14*/}
                {(exportar ||
                  [
                    "giga",
                    "demo_pt",
                    "demo",
                    "roldao",
                    "novaera",
                    "rihappy",
                    "rihappy_off",
                  ].includes(user.dbschema) ||
                  (user.dbschema === "rede_lucas"
                    ? checkIfIsPossuiBandeira("ATACADO")
                    : checkIfIsPossuiBandeira("Varejo") ||
                      checkIfIsPossuiBandeira())) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("novo_preco_atacado")}
                  >
                    {["giga", "roldao"].includes(user.dbschema)
                      ? "Novo Preço Varejo"
                      : ["rihappy", "rihappy_off"].includes(user.dbschema)
                      ? "Novo Preço Tab Fornecedor"
                      : ["camilo", "camilo_campeao"].includes(user.dbschema)
                      ? "Novo Preço Atacado 3"
                      : "Novo Preço Atacado"}
                  </TableCell>
                )}
                {/*14*/}
                {["independente"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_nova_atacado")}
                  >
                    Margem Nova Desconto
                  </TableCell>
                )}
                {/*15*/}
                {(exportar ||
                  [
                    "demo_pt",
                    "demo",
                    "roldao",
                    "novaera",
                    "rihappy",
                    "rihappy_off",
                    "giga",
                  ].includes(user.dbschema) ||
                  (user.dbschema === "rede_lucas"
                    ? checkIfIsPossuiBandeira("ATACADO")
                    : checkIfIsPossuiBandeira("Varejo") ||
                      checkIfIsPossuiBandeira())) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_nova_atacado")}
                  >
                    {user.dbschema === "giga" || user.dbschema === "roldao"
                      ? "Margem Nova Varejo"
                      : user.dbschema === "rihappy" ||
                        user.dbschema === "rihappy_off"
                      ? "Margem Tab Fornecedor"
                      : ["camilo", "camilo_campeao"].includes(user.dbschema)
                      ? "Margem Nova Atacado 3"
                      : "Margem Nova Atacado"}
                  </TableCell>
                )}
                {/*15*/}
                {(exportar ||
                  ["demo_pt", "demo", "novaera", "giga"].includes(
                    user.dbschema
                  ) ||
                  (user.dbschema === "rede_lucas"
                    ? checkIfIsPossuiBandeira("ATACADO")
                    : checkIfIsPossuiBandeira("Varejo") ||
                      checkIfIsPossuiBandeira())) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("quantidade_atacado_simulador")}
                  >
                    {["camilo", "camilo_campeao"].includes(user.dbschema)
                      ? "Quantidade Atacado 3"
                      : "Quantidade Atacado"}
                  </TableCell>
                )}
                {((["rede_lucas"].includes(user.dbschema) &&
                  checkIfIsPossuiBandeira("ATACADO")) ||
                  checkIfIsPossuiBandeira("PRÁTICO") ||
                  checkIfIsPossuiBandeira("SUPERMERCADO")) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("margem_nova_atacado")}
                  >
                    Preço Club
                  </TableCell>
                )}
                {["rede_lucas"].includes(user.dbschema) &&
                  (checkIfIsPossuiBandeira("ATACADO") ||
                    checkIfIsPossuiBandeira("PRÁTICO") ||
                    checkIfIsPossuiBandeira("SUPERMERCADO")) && (
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("margem_nova_atacado")}
                    >
                      Margem Preço Club
                    </TableCell>
                  )}
                {checkIfIsPossuiBandeiraCamilo(user.dbschema) && (
                  <>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("novo_preco_atacado_3")}
                    >
                      Novo Preço Atacado 4
                    </TableCell>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("margem_nova_atacado3")}
                    >
                      Margem Atacado 4
                    </TableCell>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("fator_3")}
                    >
                      Fator Atacado 4
                    </TableCell>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("qtde_atacado_3")}
                    >
                      Quantidade Atacado 4
                    </TableCell>
                  </>
                )}
                {checkIfIsPossuiBandeiraCamilo(
                  user.dbschema,
                  user.dbschema !== "camilo_campeao" ? "Atacarejo" : "Atacado"
                ) && (
                  <>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("novo_preco_atacado_4")}
                    >
                      Novo Preço Atacado 5
                    </TableCell>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("margem_nova_atacado4")}
                    >
                      Margem Atacado 5
                    </TableCell>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("fator_4")}
                    >
                      Fator Atacado 5
                    </TableCell>
                    <TableCell
                      className="titulo_tabela"
                      scope="col"
                      onClick={(e) => ordenar("qtde_atacado_4")}
                    >
                      Quantidade Atacado 5
                    </TableCell>
                  </>
                )}
                {/*15*/}
                {/*ATACADO FIM*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("preco_primario")}
                >
                  {languages[lang].preco_concorrente_principal}
                </TableCell>
                {/*16*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("preco_secundario")}
                >
                  {languages[lang].preco_concorrente_secundario}
                </TableCell>
                {/*17*/}
                {[
                  "independente",
                  "condor",
                  "demo_es",
                  "demo_pt",
                  "demo",
                  "novaera",
                  "dia",
                  "ikesaki",
                  "semar",
                  "mambo",
                  "stmarche",
                  "peruzzo",
                  "mundopet",
                  "oba",
                  "mercebairro",
                  "venancio",
                  "smboa",
                  "telhanorte",
                  "rihappy",
                  "rihappy_off",
                  "nagumo",
                  "hndesc",
                  "unissul",
                  "floresta",
                  "grupo_nos",
                  "roldao",
                  "superpao",
                  "rede_lucas",
                  "asun",
                  "ayumi",
                  "vivenci",
                  "zorzon",
                  "lopes",
                  "camilo",
                  "camilo_campeao",
                  "estrela",
                  "atack",
                ].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_terciario")}
                  >
                    {languages[lang].preco_concorrente_terciario}
                  </TableCell>
                )}
                {/*17*/}
                {[
                  "mambo",
                  "condor",
                  "novaera",
                  "oba",
                  "dia",
                  "venancio",
                  "nagumo",
                  "grupo_nos",
                  "mercebairro",
                  "roldao",
                  "rihappy",
                  "vivenci",
                  "zorzon",
                  "dia",
                  "atack",
                  "semar",
                  "estrela",
                ].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_quaternario")}
                  >
                    {languages[lang].preco_concorrente_quaternario}
                  </TableCell>
                )}
                {/*17*/}
                {[
                  "mambo",
                  "condor",
                  "oba",
                  "dia",
                  "venancio",
                  "grupo_nos",
                  "mercebairro",
                  "roldao",
                  "rihappy",
                  "vivenci",
                  "zorzon",
                  "atack",
                  "semar",
                  "estrela",
                ].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_quinto")}
                  >
                    {languages[lang].preco_concorrente_quinario}
                  </TableCell>
                )}
                {/*17*/}
                {[
                  "mambo",
                  "condor",
                  "oba",
                  "dia",
                  "venancio",
                  "grupo_nos",
                  "mercebairro",
                  "roldao",
                  "rihappy",
                  "dia",
                  "atack",
                ].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_sexto")}
                  >
                    {languages[lang].preco_concorrente_senario}
                  </TableCell>
                )}
                {/*17*/}
                {[
                  "mambo",
                  "oba",
                  "grupo_nos",
                  "rihappy",
                  "dia",
                  "condor",
                  "atack",
                ].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_setimo")}
                  >
                    Preço Concorrente Setenário
                  </TableCell>
                )}
                {["oba", "grupo_nos", "dia", "condor"].includes(
                  user.dbschema
                ) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_octonario")}
                  >
                    Preço Concorrente Octonário
                  </TableCell>
                )}
                {["oba", "dia"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_nonario")}
                  >
                    Preço Concorrente Nonário
                  </TableCell>
                )}
                {["oba", "dia"].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_decenario")}
                  >
                    Preço Concorrente Decenário
                  </TableCell>
                )}
                {/*17*/}
                {/* TODO - 4 */}
                {[
                  "rihappy",
                  "demo_pt",
                  "demo",
                  "sjudastadeu",
                  "bigbox",
                  "roldao",
                  "independente",
                  "princesa",
                  "grupo_nos",
                  "lopes",
                  "camilo",
                  "camilo_campeao",
                ].includes(user.dbschema) && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("preco_concorrente_estudo")}
                  >
                    Preço Concorrente Estudo
                  </TableCell>
                )}
                {/*18*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  // onClick={(e) => ordenar("preco_medio_conc")}
                >
                  {languages[lang].preco_medio_concorrente}
                </TableCell>
                {/*18*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("indice_meta")}
                >
                  {languages[lang].indice_meta}
                </TableCell>
                {/*7*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("indice_regular_atual")}
                >
                  {languages[lang].indice_regular_atual}
                </TableCell>
                {/*150*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("indice_novo_preco_regular")}
                >
                  {languages[lang].indice_novo_preco_regular}
                </TableCell>
                {/*26*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("estoque")}
                >
                  {languages[lang].estoque}
                </TableCell>
                {/*19*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("nome_departamento")}
                >
                  {languages[lang].departamento}
                </TableCell>
                {/*20*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("nome_secao")}
                >
                  {" "}
                  {languages[lang].secao}
                </TableCell>
                {/*21*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("nome_grupo")}
                >
                  {languages[lang].grupo}
                </TableCell>
                {/*22*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("nome_subgrupo")}
                >
                  {languages[lang].subgrupo}
                </TableCell>
                {/*23*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("papel_categoria_simulador")}
                >
                  {languages[lang].papel_categoria}
                </TableCell>
                {/*24*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("vendas")}
                >
                  {languages[lang].quantidade_de_vendas_regular_atual}
                </TableCell>
                {/*27*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("qtde_vendas_estimada")}
                >
                  {languages[lang].quantidade_de_vendas_regular_estimada}
                </TableCell>
                {user.dbschema === "oba" && (
                  <TableCell
                    className="titulo_tabela"
                    scope="col"
                    onClick={(e) => ordenar("faturamento")}
                  >
                    Faturamento
                  </TableCell>
                )}
                {/*28*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("diferenca_preco_unitario")}
                >
                  {languages[lang].diferenca_unitario}
                </TableCell>
                {/*29*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("diferenca_total")}
                >
                  {languages[lang].diferenca_total} R$
                </TableCell>
                {/*30*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("preco_atual_varejo")}
                >
                  {languages[lang].preco_inicial}
                </TableCell>
                {/*31*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("politica_pricing")}
                >
                  {languages[lang].politica_pricing_mix}
                </TableCell>
                {/*32*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("margem_min_max")}
                >
                  {languages[lang].margem_minima_maxima}
                </TableCell>
                {/*33*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("sugestao_escala")}
                >
                  {languages[lang].preco_escala}
                </TableCell>
                {/*35*/}
                {rendergrupoArredondamentoHeader()}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("preco_min_max")}
                >
                  {languages[lang].preco_minimo_maximo}
                </TableCell>
                {/*37*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("sugestao_proporcionalidade")}
                >
                  {languages[lang].preco_proporcional}
                </TableCell>
                {/*7*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("sugestao_final")}
                >
                  Preço Final
                </TableCell>
                {/*7*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("ftp_fator")}
                >
                  {languages[lang].fator_propor_min}
                </TableCell>
                {/*7*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("ftp_fator_max")}
                >
                  {languages[lang].fator_propor_max}
                </TableCell>
                {/*7*/}
                {["grupo_nos"].includes(user.dbschema) && (
                  <TableCell className="col_acao" scope="col">
                    Foto Concorrente
                  </TableCell>
                )}

                {user.dbschema !== "grupo_nos" ? (
                  <>
                    <TableCell className="col_acao" scope="col">
                      {languages[lang].foto_conc_1}
                    </TableCell>
                    {/*38*/}
                    <TableCell className="col_acao" scope="col">
                      {languages[lang].foto_conc_2}
                    </TableCell>
                  </>
                ) : (
                  <></>
                )}
                {/*39*/}
                {[
                  "independente",
                  "novaera",
                  "demo_es",
                  "demo_pt",
                  "demo",
                  "dia",
                  "ikesaki",
                  "semar",
                  "mambo",
                  "stmarche",
                  "peruzzo",
                  "mundopet",
                  "oba",
                ].includes(user.dbschema) && (
                  <TableCell className="col_acao" scope="col">
                    Foto Conc 3
                  </TableCell>
                )}
                {/* 39 */}
                {["mambo", "novaera", "oba"].includes(user.dbschema) && (
                  <TableCell className="col_acao" scope="col">
                    Foto Conc 4
                  </TableCell>
                )}
                {/* 39 */}
                {["mambo"].includes(user.dbschema) && (
                  <TableCell className="col_acao" scope="col">
                    Foto Conc 5
                  </TableCell>
                )}
                {/* 39 */}
                {["mambo"].includes(user.dbschema) && (
                  <TableCell className="col_acao" scope="col">
                    Foto Conc 6
                  </TableCell>
                )}
                {/* 39 */}
                {["mambo"].includes(user.dbschema) && (
                  <TableCell className="col_acao" scope="col">
                    Foto Conc 7
                  </TableCell>
                )}
                {/* 39 */}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("nome_escala")}
                >
                  {languages[lang].nome_escala}
                </TableCell>
                {/*39*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("posicao_escala")}
                >
                  {languages[lang].posicao_escala}
                </TableCell>
                {/*39*/}
                <TableCell
                  className="titulo_tabela"
                  scope="col"
                  onClick={(e) => ordenar("porcentagem_escala")}
                >
                  {languages[lang].porcentagem_escala}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={renderBackgroundColor()}>
              {rowsToRender.map((row, index) => (
                <TableRow key={row.id_editavel + index}>
                  <StickyTableCell
                    className={[classes.body, renderBackgroundColor()]}
                  >
                    {difTotal === true && (
                      <TableCell
                        className="titulo_tabela sticky_table_cell"
                        scope="col"
                      ></TableCell>
                    )}
                    <TableCell
                      className="page_cluster sticky_table_cell"
                      component="th"
                      scope="row"
                      style={{ width: "40px" }}
                    >
                      {renderCheckbox(row, index)}
                      {renderCheckboxSlider(row, index)}
                      {renderBloqueados(row, index)}
                    </TableCell>
                    {/*1*/}
                    {exportar === true && (
                      <TableCell
                        className="page_cluster sticky_table_cell"
                        component="th"
                        scope="row"
                      >
                        {row.data_exportacao}
                      </TableCell>
                    )}
                    {/*1*/}
                    <TableCell
                      className="page_cluster sticky_table_cell larger_cell"
                      component="th"
                      scope="row"
                    >
                      {row.cluster_simulador}
                      {/*2*/}
                    </TableCell>
                    {user.dbschema === "hndesc" && (
                      <TableCell
                        className="page_cluster sticky_table_cell larger_cell"
                        component="th"
                        scope="row"
                      >
                        {row.preco_online}
                      </TableCell>
                    )}
                    <TableCell
                      className="page_cluster sticky_table_cell"
                      component="th"
                      scope="row"
                    >
                      {row.cod_pai_proporcao}
                      {/*2*/}
                    </TableCell>
                    <TableCell
                      className="page_pai sticky_table_cell"
                      component="th"
                      scope="row"
                    >
                      {row.codigo_pai}
                      {/*3*/}
                    </TableCell>
                    <TableCell
                      className="page_pai sticky_table_cell"
                      component="th"
                      scope="row"
                    >
                      {row.codigo_filhos}
                      {/*4*/}
                    </TableCell>

                    {["bigbox", "demo_pt", "demo"].includes(user.dbschema) && (
                      <TableCell
                        className="page_pai  sticky_table_cell"
                        component="th"
                        scope="row"
                      >
                        {row.codigo_plu}
                        {/*5*/}
                      </TableCell>
                    )}
                    <TableCell
                      style={
                        row.tem_preco_min_max === "SIM"
                          ? { fontWeight: "bold" }
                          : {}
                      }
                      className={
                        statusItem(row.status_do_item) +
                        " page_a" +
                        " larger_cell sticky_table_cell"
                      }
                      component="th"
                      scope="row"
                    >
                      {row.descricao_produto}
                      {/*6*/}
                    </TableCell>
                    {[
                      "imec",
                      "rihappy_off",
                      "rihappy",
                      "grupo_nos",
                      "camilo",
                      "camilo_campeao",
                      "atack",
                    ].includes(user.dbschema) && (
                      <TableCell
                        className="page_cluster sticky_table_cell medium_cell"
                        component="th"
                        scope="row"
                      >
                        {row.status_produto}
                      </TableCell>
                    )}
                    {["atack"].includes(user.dbschema) && (
                      <TableCell
                        className="page_cluster sticky_table_cell medium_cell"
                        component="th"
                        scope="row"
                      >
                        {row.data_ultima_entrada}
                      </TableCell>
                    )}
                    {user.dbschema != "independente" && (
                      <TableCell
                        className={`${
                          user.dbschema === "avenida"
                            ? `${
                                checkPriceDifference(
                                  row.preco_vigente_varejo,
                                  row.preco_atual_varejo
                                )
                                  ? "Spromocao page_cluster sticky_table_cell"
                                  : "Npromocao page_cluster sticky_table_cell"
                              }`
                            : row.promocao_s_ou_n +
                              "promocao page_cluster sticky_table_cell"
                        }`}
                        component="th"
                        scope="row"
                      >
                        {mostraZero(sanitalize(row.preco_vigente_varejo))}
                        {/*7*/}
                      </TableCell>
                    )}
                    <TableCell
                      className="page_cluster sticky_table_cell"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.preco_atual_varejo))}
                    </TableCell>
                    <TableCell
                      className={
                        row.valorValido +
                        " " +
                        "_th page_a larger_cell sticky_table_cell"
                      }
                      component="th"
                      scope="row"
                    >
                      {row.seta}
                      {renderInput(row, index)}
                    </TableCell>
                    <TableCell
                      className="page_cluster page_custo sticky_table_cell"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.custo_do_produto))}
                      {/*10*/}
                    </TableCell>
                  </StickyTableCell>
                  {["mundopet", "independente"].includes(user.dbschema) && (
                    <TableCell className="page_pai" component="th" scope="row">
                      {row.ean_simulador}
                      {/*6*/}
                    </TableCell>
                  )}
                  {["dia", "palomax"].includes(user.dbschema) && (
                    <TableCell className="page_pai" component="th" scope="row">
                      {row.marca}
                      {/*6*/}
                    </TableCell>
                  )}
                  {["dia"].includes(user.dbschema) && (
                    <TableCell className="page_pai" component="th" scope="row">
                      {row.formato}
                      {/*6*/}
                    </TableCell>
                  )}
                  {["independente"].includes(user.dbschema) && (
                    <TableCell
                      className={row.promocao_s_ou_n + "promocao page_a"}
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.preco_vigente_varejo))}
                      {/*7*/}
                    </TableCell>
                  )}
                  {["venancio"].includes(user.dbschema) && (
                    <TableCell
                      className={row.promocao_s_ou_n + "promocao page_a"}
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.preco_vigente_venancio))}
                    </TableCell>
                  )}
                  {/* Exclusividade Rihappy:
                   * Preço Loja Fisica ===  margem_minima
                   */}
                  {["rihappy"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {row.margem_minima}
                    </TableCell>
                  )}
                  {/*8*/}
                  {/*9*/}
                  {["mundopet"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {mostraZero(sanitalize(row.qtd_bonificada))}
                    </TableCell>
                  )}
                  {["mundopet"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {mostraZero(sanitalize(row.custo_medio))}
                    </TableCell>
                  )}
                  {["camilo", "camilo_campeao"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.custo_tabela_fornecedor))}
                      {/*10*/}
                    </TableCell>
                  )}
                  {["redetop", "peruzzo"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.custo_medio))}
                    </TableCell>
                  )}
                  {/* OBA ___ AQUI */}
                  {["oba"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {variacaoCustoOba(row.preco_online)}
                    </TableCell>
                  )}
                  {["camilo", "camilo_campeao"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {row.custo_medio}
                    </TableCell>
                  )}
                  <TableCell className="page_custo" component="th" scope="row">
                    {user.dbschema === "grupo_nos" &&
                    row.impostos_simulador === "0"
                      ? "0%"
                      : roundDown(row.impostos_simulador, 3).toFixed(2)}
                    {porcentagem(row.impostos_simulador)}
                    {/*7*/}
                  </TableCell>
                  <TableCell className="page_custo" component="th" scope="row">
                    {mostraZero(sanitalize(row.pmz, 2))}
                  </TableCell>
                  {["oba"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {String(row.data_ultimo_custo)}
                    </TableCell>
                  )}
                  {["mundopet"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.margem_minima, 2))}
                    </TableCell>
                  )}
                  {["mundopet"].includes(user.dbschema) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.margem_maxima, 2))}
                    </TableCell>
                  )}
                  {/*7*/}
                  {["bigbox", "demo_pt", "demo", "ikesaki", "redetop"].includes(
                    user.dbschema
                  ) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {row.data_ultimo_custo}
                      {/*7*/}
                    </TableCell>
                  )}
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZeroDec(row.margem_objetiva_produto * 100)}
                    {porcentagem(row.margem_objetiva_produto)}
                    {/*8*/}
                  </TableCell>
                  <TableCell
                    className={"page_a " + Calculadora.MargemAtualCor(row)}
                    component="th"
                    scope="row"
                  >
                    {Math.round(row.margem_atual_regular) !== 0
                      ? mostraZeroDec(roundDown(row.margem_atual_regular, 1))
                      : 0}
                    %{/*8*/}
                  </TableCell>
                  <TableCell
                    className={
                      "page_a " + Calculadora.NovaMargemCor(row.margem_nova)
                    }
                    component="th"
                    scope="row"
                  >
                    {mostraZeroDec(roundDown(row.margem_nova, 1)) || 0.0}%
                    {/*10*/}
                  </TableCell>
                  {"roldao".includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {row.margem_contrib &&
                        `${Number(row.margem_contrib).toFixed(2)}%`}
                    </TableCell>
                  )}
                  <TableCell className="page_custo" component="th" scope="row">
                    {Number(row.variacao_nova_margem).toFixed(1) === "0.0"
                      ? ""
                      : `${Number(row.variacao_nova_margem).toFixed(1)}%`}
                    {/*7*/}
                  </TableCell>
                  <TableCell
                    className={cor(row.sensibilidade_simulador) + " page_a"}
                    component="th"
                    scope="row"
                  >
                    {row.sensibilidade_simulador}
                    {/*11*/}
                  </TableCell>
                  {/*  Exclusividade Rihappy: TEM PESQUISA ==== CURVA ABC */}
                  {["rihappy", "rihappy_off"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {row.curva_abc}
                    </TableCell>
                  )}
                  <TableCell
                    className={
                      row.valorValido2 +
                      " " +
                      row.flag_pai_ou_filho.toLowerCase() +
                      "_th page_novovalor"
                    }
                    component="th"
                    scope="row"
                  >
                    {mostraZeroDec(roundDown(row.variacao_preco_novo, 1))}%
                    {/* {porcentagem(row.variacao_preco_novo)} */}
                    {/*12*/}
                  </TableCell>
                  {["roldao"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {row.quantidade_atacado_simulador}
                    </TableCell>
                  )}
                  {/*COLUNAS ATACADO*/}
                  {(exportar ||
                    [
                      "demo_pt",
                      "demo",
                      "roldao",
                      "novaera",
                      "rihappy",
                      "rihappy_off",
                      "independente",
                    ].includes(user.dbschema) ||
                    (user.dbschema === "rede_lucas"
                      ? checkIfIsPossuiBandeira("ATACADO")
                      : checkIfIsPossuiBandeira("Varejo") ||
                        checkIfIsPossuiBandeira())) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {roundDown(row.fator_atacado_simulador ?? 0, 2) ?? 0}
                    </TableCell>
                  )}
                  {/* TODO Valor atacado */}
                  {(user.dbschema === "rede_lucas"
                    ? checkIfIsPossuiBandeira("ATACADO")
                    : checkIfIsPossuiBandeira("Varejo") ||
                      (checkIfIsPossuiBandeira() &&
                        user.dbschema !== "camilo_campeao")) && (
                    <TableCell
                      className="page_custo"
                      component="th"
                      scope="row"
                    >
                      {mostraZero(sanitalize(row.preco_regular_atacado))}
                    </TableCell>
                  )}
                  {(exportar ||
                    [
                      "demo_pt",
                      "demo",
                      "roldao",
                      "novaera",
                      "rihappy",
                      "rihappy_off",
                      "independente",
                      "giga",
                    ].includes(user.dbschema) ||
                    (user.dbschema === "rede_lucas"
                      ? checkIfIsPossuiBandeira("ATACADO")
                      : checkIfIsPossuiBandeira("Varejo") ||
                        checkIfIsPossuiBandeira())) && (
                    <TableCell
                      className={
                        row.valorValidoAtacado + " page_a page_novovalor"
                      }
                      component="th"
                      scope="row"
                    >
                      {Math.floor(row.novo_preco_atacado) === 0 ||
                      row.preco_regular_atacado === undefined
                        ? ""
                        : row.seta_atacado}
                      {user.dbschema === "bigbox"
                        ? mostraZero(roundDown(row.novo_preco_atacado, 2)) ||
                          0.0
                        : renderInputAtacado(row, index)}
                    </TableCell>
                  )}
                  {/*14*/}
                  {(exportar ||
                    [
                      "demo_pt",
                      "demo",
                      "roldao",
                      "novaera",
                      "rihappy",
                      "rihappy_off",
                      "giga",
                    ].includes(user.dbschema) ||
                    (user.dbschema === "rede_lucas"
                      ? checkIfIsPossuiBandeira("ATACADO")
                      : checkIfIsPossuiBandeira("Varejo") ||
                        checkIfIsPossuiBandeira())) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {Number(row.margem_nova_atacado).toFixed(1) || 0.0}%
                    </TableCell>
                  )}
                  {/*14*/}
                  {["independente"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {roundDown(row.margem_nova_atacado, 1) || 0.0}%
                    </TableCell>
                  )}
                  {/*14*/}
                  {(exportar ||
                    ["demo_pt", "demo", "novaera", "giga"].includes(
                      user.dbschema
                    ) ||
                    (user.dbschema === "rede_lucas"
                      ? checkIfIsPossuiBandeira("ATACADO")
                      : checkIfIsPossuiBandeira("Varejo") ||
                        checkIfIsPossuiBandeira())) && (
                    <TableCell className="page_a" component="th" scope="row">
                      {row.quantidade_atacado_simulador}
                    </TableCell>
                  )}
                  {["rede_lucas"].includes(user.dbschema) &&
                    (checkIfIsPossuiBandeira("ATACADO") ||
                      checkIfIsPossuiBandeira("PRÁTICO") ||
                      checkIfIsPossuiBandeira("SUPERMERCADO")) && (
                      <TableCell className="page_a" component="th" scope="row">
                        {renderInputAtacadoRedeLucas(row, index)}
                      </TableCell>
                    )}
                  {["rede_lucas"].includes(user.dbschema) &&
                    (checkIfIsPossuiBandeira("ATACADO") ||
                      checkIfIsPossuiBandeira("PRÁTICO") ||
                      checkIfIsPossuiBandeira("SUPERMERCADO")) && (
                      <TableCell className="page_a" component="th" scope="row">
                        {Number(row.margem_nova_atacado3).toFixed(1)}%
                      </TableCell>
                    )}
                  {checkIfIsPossuiBandeiraCamilo(user.dbschema) && (
                    <>
                      <TableCell className="page_a" component="th" scope="row">
                        {renderInputAtacado3(row, index)}
                      </TableCell>
                      <TableCell className="page_a" component="th" scope="row">
                        {Number(row.margem_nova_atacado3).toFixed(1) || 0.0}%
                      </TableCell>
                      <TableCell className="page_a" component="th" scope="row">
                        {row.fator_3}
                      </TableCell>
                      <TableCell className="page_a" component="th" scope="row">
                        {row.qtde_atacado_3}
                      </TableCell>
                    </>
                  )}
                  {checkIfIsPossuiBandeiraCamilo(
                    user.dbschema,
                    user.dbschema !== "camilo_campeao" ? "Atacarejo" : "Atacado"
                  ) && (
                    <>
                      <TableCell className="page_a" component="th" scope="row">
                        {renderInputAtacado4(row, index)}
                      </TableCell>
                      <TableCell className="page_a" component="th" scope="row">
                        {Number(row.margem_nova_atacado4).toFixed(1) || 0.0}%
                      </TableCell>
                      <TableCell className="page_a" component="th" scope="row">
                        {row.fator_4}
                      </TableCell>
                      <TableCell className="page_a" component="th" scope="row">
                        {row.qtde_atacado_4}
                      </TableCell>
                    </>
                  )}
                  {/*15*/}
                  {/*ATACADO FIM*/}
                  <TableCell
                    className="page_a tooltip-table concorrente_select"
                    component="th"
                    scope="row"
                    onClick={(e) => {
                      handleConcorrente(row, "PRIMARIO", index);
                    }}
                  >
                    {renderConcorrente(row, "PRIMARIO")}
                    {mostraZero(sanitalize(row.preco_primario))}
                  </TableCell>
                  {/*16*/}
                  <TableCell
                    className="page_a tooltip-table concorrente_select"
                    component="th"
                    scope="row"
                    onClick={(e) => {
                      handleConcorrente(row, "SECUNDARIO", index);
                    }}
                  >
                    {renderConcorrente(row, "SECUNDARIO")}
                    {mostraZero(sanitalize(row.preco_secundario))}
                  </TableCell>
                  {/*17*/}
                  {[
                    "independente",
                    "condor",
                    "novaera",
                    "demo_es",
                    "demo_pt",
                    "demo",
                    "dia",
                    "ikesaki",
                    "semar",
                    "mambo",
                    "stmarche",
                    "peruzzo",
                    "mundopet",
                    "oba",
                    "mercebairro",
                    "venancio",
                    "smboa",
                    "telhanorte",
                    "rihappy",
                    "rihappy_off",
                    "unissul",
                    "nagumo",
                    "hndesc",
                    "floresta",
                    "grupo_nos",
                    "roldao",
                    "superpao",
                    "rede_lucas",
                    "asun",
                    "ayumi",
                    "vivenci",
                    "zorzon",
                    "lopes",
                    "camilo",
                    "camilo_campeao",
                    "estrela",
                    "atack",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "TERCIARIO", index);
                      }}
                    >
                      {renderConcorrente(row, "TERCIARIO")}
                      {mostraZero(sanitalize(row.preco_terciario))}
                    </TableCell>
                  )}
                  {/*17*/}
                  {[
                    "mambo",
                    "condor",
                    "novaera",
                    "oba",
                    "dia",
                    "venancio",
                    "nagumo",
                    "grupo_nos",
                    "mercebairro",
                    "roldao",
                    "rihappy",
                    "vivenci",
                    "dia",
                    "zorzon",
                    "atack",
                    "semar",
                    "estrela",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "QUATERNARIO", index);
                      }}
                    >
                      {renderConcorrente(row, "QUATERNARIO")}
                      {mostraZero(sanitalize(row.preco_quaternario))}
                    </TableCell>
                  )}
                  {/*17*/}
                  {[
                    "mambo",
                    "condor",
                    "oba",
                    "dia",
                    "venancio",
                    "grupo_nos",
                    "mercebairro",
                    "roldao",
                    "rihappy",
                    "vivenci",
                    "zorzon",
                    "dia",
                    "atack",
                    "semar",
                    "estrela",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "QUINARIO", index);
                      }}
                    >
                      {renderConcorrente(row, "QUINARIO")}
                      {mostraZero(sanitalize(row.preco_quinto))}
                    </TableCell>
                  )}
                  {/*17*/}
                  {[
                    "mambo",
                    "condor",
                    "oba",
                    "dia",
                    "venancio",
                    "grupo_nos",
                    "mercebairro",
                    "roldao",
                    "rihappy",
                    "dia",
                    "atack",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "SENARIO", index);
                      }}
                    >
                      {renderConcorrente(row, "SENARIO")}
                      {mostraZero(sanitalize(row.preco_sexto))}
                    </TableCell>
                  )}
                  {/*17*/}
                  {[
                    "mambo",
                    "oba",
                    "grupo_nos",
                    "rihappy",
                    "dia",
                    "condor",
                    "atack",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "SETIMO", index);
                      }}
                    >
                      {renderConcorrente(row, "SETIMO")}
                      {mostraZero(sanitalize(row.preco_setimo))}
                    </TableCell>
                  )}
                  {["oba", "grupo_nos", "dia", "condor"].includes(
                    user.dbschema
                  ) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "OITAVO", index);
                      }}
                    >
                      {renderConcorrente(row, "OITAVO")}
                      {mostraZero(sanitalize(row.preco_octonario))}
                    </TableCell>
                  )}
                  {["oba", "dia"].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "NONO", index);
                      }}
                    >
                      {renderConcorrente(row, "NONO")}
                      {mostraZero(sanitalize(row.preco_nonario))}
                    </TableCell>
                  )}
                  {["oba", "dia"].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "DECIMO", index);
                      }}
                    >
                      {renderConcorrente(row, "DECIMO")}
                      {mostraZero(sanitalize(row.preco_decenario))}
                    </TableCell>
                  )}
                  {[
                    "rihappy",
                    "demo_pt",
                    "demo",
                    "sjudastadeu",
                    "bigbox",
                    "roldao",
                    "independente",
                    "princesa",
                    "grupo_nos",
                    "lopes",
                    "camilo",
                    "camilo_campeao",
                  ].includes(user.dbschema) && (
                    <TableCell
                      className="page_a tooltip-table concorrente_select"
                      component="th"
                      scope="row"
                      onClick={(e) => {
                        handleConcorrente(row, "ESTUDO", index);
                      }}
                    >
                      {renderConcorrente(row, "ESTUDO")}
                      {mostraZero(sanitalize(row.preco_concorrente_estudo))}
                    </TableCell>
                  )}
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(row.preco_medio_conc)}
                  </TableCell>
                  {/*18*/}
                  <TableCell className="page_custo" component="th" scope="row">
                    {mostraZeroDec(sanitalize(row.indice_meta))}
                    {porcentagem(row.indice_meta)}
                    {/*7*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZeroDec(roundDown(row.indiceatual, 1))}
                    {porcentagem(row.indiceatual)}
                    {/*150*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZeroDec(roundDown(row.indice_novo_preco, 1))}
                    {porcentagem(row.indice_novo_preco)}
                    {/*26*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.estoque}
                    {/*19*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.nome_departamento}
                    {/*20*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.nome_secao}
                    {/*21*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.nome_grupo}
                    {/*22*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.nome_subgrupo}
                    {/*23*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.papel_categoria_simulador}
                    {/*24*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {roundDown(row.vendas, 0)}
                    {/*27*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {roundDown(row.qtde_vendas_estimada, 0) || 0}
                    {/*28*/}
                  </TableCell>
                  {user.dbschema === "oba" && (
                    <TableCell className="page_a" component="th" scope="row">
                      {roundDown(row.faturamento, 0) || 0}
                    </TableCell>
                  )}
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(roundDown(row.diferenca_unitario, 2))}
                    {/*29*/}
                  </TableCell>{" "}
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(roundDown(row.diferenca_total, 2))}
                    {/*30*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(roundDown(row.preco_atual_varejo, 2))}
                    {/*31 preço inicial*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(sanitalize(row.politica_pricing))}
                    {/*32 politica pricing*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(sanitalize(row.margem_min_max))}
                    {/*33 margem min max*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(sanitalize(row.sugestao_escala))}
                    {/*35 preço escala*/}
                  </TableCell>
                  {rendergrupoArredondamentoBody(row)}
                  <TableCell className="page_a" component="th" scope="row">
                    {mostraZero(roundDown(row.sugestao_preco_min_max, 2))}
                    {/*37 preco min max*/}
                  </TableCell>
                  <TableCell className="page_custo" component="th" scope="row">
                    {row.sugestao_proporcionalidade}
                    {/*7*/}
                  </TableCell>
                  <TableCell className="page_custo" component="th" scope="row">
                    {row.sugestao_final}
                    {/*7*/}
                  </TableCell>
                  <TableCell className="page_custo" component="th" scope="row">
                    {mostraZero(sanitalize(row.ftp_fator))}
                    {/*7*/}
                  </TableCell>
                  <TableCell className="page_custo" component="th" scope="row">
                    {mostraZero(sanitalize(row.ftp_fator_max))}
                    {/*7*/}
                  </TableCell>
                  {["grupo_nos"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      <div className="tabela_acao">
                        {row.tem_foto !== "0" ? (
                          <button
                            data-target="#photo-carousel"
                            data-toggle="modal"
                            onClick={() => {
                              getPhotos(row.codigo_pai);
                            }}
                          >
                            <VscDeviceCamera></VscDeviceCamera>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </TableCell>
                  )}
                  {user.dbschema !== "grupo_nos" ? (
                    <>
                      <TableCell className="page_a" component="th" scope="row">
                        <div className="tabela_acao">
                          <div className="tooltip-table">
                            {renderFoto(row.foto_primario)}
                          </div>
                        </div>
                      </TableCell>
                      {/*38*/}
                      <TableCell className="page_a" component="th" scope="row">
                        <div className="tabela_acao">
                          <div className="tooltip-table">
                            {renderFoto(row.foto_secundario)}
                          </div>
                        </div>
                      </TableCell>
                    </>
                  ) : (
                    <></>
                  )}
                  {/*39*/}
                  {[
                    "independente",
                    "novaera",
                    "demo_es",
                    "demo_pt",
                    "demo",
                    "dia",
                    "ikesaki",
                    "semar",
                    "mambo",
                    "stmarche",
                    "peruzzo",
                    "mundopet",
                    "nagumo",
                    "oba",
                  ].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      <div className="tabela_acao">
                        <div className="tooltip-table">
                          {renderFoto(row.foto_terciario)}
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {["mambo", "novaera", "oba"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      <div className="tabela_acao">
                        <div className="tooltip-table">
                          {renderFoto(
                            row.foto_quaternario === "0"
                              ? ""
                              : row.foto_quaternario
                          )}
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {["mambo"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      <div className="tabela_acao">
                        <div className="tooltip-table">
                          {renderFoto(row.foto_quinto)}
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {["mambo"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      <div className="tabela_acao">
                        <div className="tooltip-table">
                          {renderFoto(row.foto_sexto)}
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {["mambo"].includes(user.dbschema) && (
                    <TableCell className="page_a" component="th" scope="row">
                      <div className="tabela_acao">
                        <div className="tooltip-table">
                          {renderFoto(row.foto_setimo)}
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {/* 39 */}
                  <TableCell className="page_a" component="th" scope="row">
                    {row.nome_escala}
                    {/*37 nome escala*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {row.posicao_escala}
                    {/*37 posicao escala*/}
                  </TableCell>
                  <TableCell className="page_a" component="th" scope="row">
                    {sanitalize(row.porcentagem_escala) * 100 || ""}
                    {/*37 porcentagem escala*/}
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[quantidadeDeProdutosDatabelaDefault]}
                  colSpan={6}
                  count={parseInt(registros)}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>

            <caption className="registro_pag">
              {page + 1} de {totalDePaginas}
            </caption>
          </Table>
        </TableContainer>
      </form>
    </div>
  );
}
