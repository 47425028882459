import React from "react";

import CsvDownloader from "react-csv-downloader";
import DownloadArqCsv from "../DownloadArqCsv";
import DownloadArqXlsx from "../DownloadArqXlsx";

import { dataBR } from "../../shared/utils/dataBR";
import language from "../../languages.json";

export function renderFilhosModule(
  exportar,
  user,
  dadosExportar,
  editados,
  difTotal,
  bloqueados,
  filtroBandeira,
  itensBloqueados,
  separator,
  lang,
  exportData
) {
  const fileName = ["asun"].includes(user.dbschema)
    ? "pricemet.csv"
    : "exportacao_preco_" + dataBR() + ".csv";

  if (exportar === true) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-9 csv_col">
            <h3 className="validados_null">Relatório de Itens Exportados</h3>
          </div>
          <div className="col-3 csv_col direita">
            {(user.dbschema != "dia" || user.dbschema != "oba") && (
              <CsvDownloader
                bom={false}
                datas={dadosExportar}
                wrapColumnChar=""
                filename={fileName}
                separator={separator()}
                noHeader={user.dbschema === "avenida" ? true : false}
                style={{
                  boxShadow: "inset 0px 1px 0px 0px #e184f3",
                  backgroundColor: "red",
                  borderRadius: "6px",
                  border: "red",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#ffffff",
                  fontSize: "15px",
                  padding: "6px 24px",
                  height: "40px",
                  marginRight: "12px",
                  lineHeight: "middle",
                  marginTop: "12px",
                  width: "320px",
                  textDecoration: "none",
                  textAlign: "center",
                }}
              >
                Exportar CSV
              </CsvDownloader>
            )}

            {/* {user.dbschema === "asun" &&
              localStorage.getItem("exportApiTokenAsun") && (
                <button onClick={exportData}>Exportar Preços</button>
              )} */}

            {user.dbschema === "grupo_nos" && (
              <DownloadArqXlsx schema={user.dbschema}></DownloadArqXlsx>
            )}

            {user.dbschema === "oba" && (
              <DownloadArqXlsx schema={user.dbschema}></DownloadArqXlsx>
            )}
            {user.dbschema === "dia" && <DownloadArqCsv></DownloadArqCsv>}
          </div>
        </div>
      </div>
    );
  }

  if (editados === true) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h3 className="validados_null">Relatório de Itens Validados</h3>
          </div>
        </div>
      </div>
    );
  }

  if (difTotal === true && bloqueados === false) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-8">
            <h3 className="validados_null">Relatório de Itens para Análise</h3>
          </div>
          <div className="col-4">
            {/* TODO - ALTERAÇÃO ITENS BLOQUEADOS EFETUADO AQUI */}
            <button
              type="button"
              class="btn btn-danger btn_bloqueados"
              onClick={() => itensBloqueados()}
            >
              Itens Bloqueados
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (bloqueados === true) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-8">
            <h3 className="validados_null">Relatório de Itens Bloqueados</h3>
          </div>
          <div className="col-4">
            <button
              type="button"
              class="btn btn-danger btn_bloqueados"
              data-toggle="modal"
              data-target="#modalLimparBloqueados"
            >
              Limpar Itens Bloqueados
            </button>
          </div>
          {/* <div className='col-2 block'>
                            <button type="button" class="btn btn-danger btn_bloqueados" onClick={itensBloqueados}>Itens Bloqueados</button>
                        </div> */}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="col pais_filhos">
        <input
          className="form-check-input"
          defaultChecked={true}
          type="radio"
          name="flag_pai_ou_filho"
          onChange={filtroBandeira}
          value="PAI"
          id="pai"
        />
        <label className="filhos" htmlFor="pai">
          {language[lang].itens_pais}
        </label>
      </div>
      <div className="col pais_filhos center">
        <input
          className="form-check-input"
          type="radio"
          name="flag_pai_ou_filho"
          onChange={filtroBandeira}
          value="FILHO"
          id="filhos"
        />
        <label className="filhos" htmlFor="filhos">
          {language[lang].itens_filhos}
        </label>
      </div>
      <div className="col pais_filhos right">
        <input
          className="form-check-input"
          type="radio"
          name="flag_pai_ou_filho"
          onChange={filtroBandeira}
          value="FTP"
          id="ftp"
        />
        <label className="filhos" htmlFor="ftp">
          {language[lang].itens_filhos_proporcionais}
        </label>
      </div>
    </>
  );
}
