/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import * as Calculadora from "../Helpers/CalculadoraPricing";
import Modal from "./Modal";
import itensFiltro from "../Helpers/ItensFiltro";
import * as apiHelper from "../Helpers/Api";
import Toastify from "toastify-js";

import { useAuth } from "../contexts/AuthContext";

import MultipleSelect from "./Mult";
import { HiOutlineFilter } from "react-icons/hi";
import { TbClipboardText } from "react-icons/tb";
import { BsSliders } from "react-icons/bs";
import { VscGraphLine } from "react-icons/vsc";
import { AiOutlinePercentage } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
// constants
import { sliderInicial } from "../shared/constants/sliderInicial.constant";
import { filtrosDefault } from "../shared/constants/filtrosDefault.constant";
import { quantidadeDeProdutosDatabelaDefault } from "../shared/constants/quantidadeDeProdutosDatabelaDefault.constant";
import { useStyles2 } from "../shared/constants/useStyles2.constant";
import { atacadistas } from "../shared/constants/atacadistas.constant";

// functions
import { sanitalize } from "../shared/utils/sanitalize";
import { difMrgReg } from "../shared/utils/difMrgReg";
import { roundDown } from "../shared/utils/roundDown";
import { mostraZeroDec } from "../shared/utils/mostraZeroDec";
import { clean } from "../shared/utils/clean";
import { isIntModule } from "../shared/utils/isInt";
import { itensFiltroSemEspaco } from "../shared/utils/itensFiltroSemEspaco";

import { renderDataAtualizacaoModule } from "../shared/utils/renderDataAtualizacao";
import { renderXModule } from "./Table/renderX";
import { renderConcorrenteModule } from "./Table/renderConcorrente";
import { renderInputAtacadoModule } from "./Table/renderInputAtacado";
import { renderInputAtacadoModule3 } from "./Table/renderInputAtacado3";
import { renderInputAtacadoModule4 } from "./Table/renderInputAtacado4";
import { renderInputAtacadoRedeLucasModule } from "./Table/renderInputAtacadoRedeLucas";
import { renderInputModule } from "./Table/renderInput";
import { renderCheckboxModule } from "./Table/renderCheckbox";
import { renderBloqueadosModule } from "./Table/renderBloqueados";
import { renderBackgroundColorModule } from "../shared/utils/renderBackgroundColor";
import { separatorModule } from "../shared/utils/separator";
import { renderDropDownModule } from "./Table/renderDropDown";
import { SetasNovoPrecoModule } from "./Table/setasNovoPreco";
import { SetasNovoPrecoAtacadoModule } from "./Table/setasNovoPrecoAtacado";
import { rowsAdapterModule } from "../shared/utils/rowsAdapter";
import { renderExportButtonModule } from "./Table/renderExportButton";
import { renderTableModule } from "./Table/renderTable";
import { rendergrupoArredondamentoBodyModule } from "./Table/rendergrupoArredondamentoBody";
import { renderFilhosModule } from "./Table/renderFilhos";
import { renderSelectAllModule } from "./Table/renderSelectAll";
import { renderCheckboxSliderModule } from "./Table/renderCheckboxSlider";
import { rendergrupoArredondamentoHeaderModule } from "./Table/rendergrupoArredondamentoHeader";
import { TablePaginationActionsModule } from "./Table/TablePaginationActions";
import languages from "../languages.json";

const TablePaginationActions = (props) => TablePaginationActionsModule(props);

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePrice() {
  const { user: usuario } = useAuth();
  const [tb, setTb] = useState([]);
  const [user, setUser] = useState();
  const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API + "/api/v2");
  // const [filtrosDaAPI, setFiltrosDaAPI] = useState ([]);
  const [filtrosTravados, setFiltrosTravados] = useState({});
  const [produtos, setProdutos] = useState([]);
  const [registros, setRegistros] = useState(150);
  const [totalDePaginas, setTotalDePaginas] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [slider, setSlider] = useState([]);
  const [sliderValores, setSliderValores] = useState(sliderInicial);
  const [search, setSearch] = useState("");
  const [selectedfiltro, setSelectedfiltro] = useState(filtrosDefault);
  // const [ordenaçao, setOrdenacao] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [filhos, setFilhos] = useState("PAI");
  const [rows, setRows] = useState([]);
  const [difTotal, setDifTotal] = useState(false);
  const [dadosExportar, setDadosExportar] = useState([]);
  const [exportar, setExportar] = useState(false);
  const [editados, setEditados] = useState(false);
  const [bloqueados, setBloqueados] = useState(false);
  const [validados, setValidados] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [rowsDefault, setRowsDefault] = useState([]);
  const [rowsExportacao, setRowsExportacao] = useState([]);
  const [totalizadores, setTotalizadores] = useState([]);
  const [itensValidados, setItensValidados] = useState(false);
  const [page, setPage] = useState(0);
  const [photoCarousel, setPhotoCarousel] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(
    quantidadeDeProdutosDatabelaDefault
  );
  const [grupoArredondamento, setGrupoArredondamento] = useState([
    "bigbox",
    "dia",
    "giassi",
    "imec",
    "independente",
    "mambo",
    "novaera",
    "peruzzo",
    "semar",
    "stmarche",
  ]);
  const [open, setOpen] = useState(false);
  const [apiExportData, setApiExportData] = useState([]);
  const classes = useStyles2();
  const [lang, setLang] = useState(
    window.sessionStorage.getItem("language") || "pt"
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - 0 * rowsPerPage);

  const handleChangePage = async (event, newPage) => {
    setLoader(true);

    let novoFiltro = selectedfiltro;
    let precos;

    novoFiltro.pagina = newPage + 1;
    setSelectedfiltro(novoFiltro);
    setPage(newPage);

    if (orderBy !== "") {
      try {
        if (selectedfiltro.slider) {
          try {
            const payload = {
              ...selectedfiltro,
              order_by: orderBy,
              direction: orderDirection,
            };

            const response = await fetch(
              user.apiurl +
                "gestao/classificador/slider-filtro-order/" +
                user.user.id,
              {
                headers: {
                  "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
                },
                method: "POST",
                body: itensFiltro(payload),
              }
            );

            precos = await response.json();
          } catch (e) {}
        } else {
          const payload = {
            ...novoFiltro,
            order_by: orderBy,
            direction: orderDirection,
          };

          const response = await fetch(
            user.apiurl + "gestao/order/filtro/" + user.user.id,
            {
              headers: {
                "Content-Type":
                  "application/x-www-form-urlencoded; charset=UTF-8",
              },
              method: "POST",
              body: itensFiltro(payload),
            }
          );

          precos = await response.json();
        }
      } catch (e) {}
    } else {
      if (selectedfiltro.slider) {
        precos = await apiHelper.precosSlider(
          novoFiltro,
          user.apiurl,
          user.user.id
        );
      } else {
        if (itensValidados) {
          await itensEditados(String(novoFiltro.pagina));
          return;
        } else {
          precos = await apiHelper.precos(
            novoFiltro,
            user.apiurl,
            user.user.id
          );
        }
      }
    }

    let precosObj = rowsAdapter(
      precos.data,
      atacadistas.includes(user?.dbschema)
    );

    setRows(precosObj);
    setLoader(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(
      parseInt(event.target.value, quantidadeDeProdutosDatabelaDefault)
    );
    setPage(0);
  };

  const SetasNovoPreco = (valor, row) => SetasNovoPrecoModule(valor, row);
  const SetasNovoPrecoAtacado = (valor, row, atacado = false) =>
    SetasNovoPrecoAtacadoModule(valor, row, atacado);

  function bindDosValores(e, index, atacado = false) {
    const valor = e.target.value;

    let rowsAtualizada = [...rows];

    if (!atacado) {
      rowsAtualizada[index].preco_decisao = valor;
    } else {
      rowsAtualizada[index].preco_decisao_atacado = valor;
    }

    setRows(rowsAtualizada);
  }

  function bindDosValores3(e, index, atacado = false) {
    const valor = e.target.value;

    let rowsAtualizada = [...rows];

    if (!atacado) {
      rowsAtualizada[index].preco_decisao_atacado_3 = valor;
    } else {
      rowsAtualizada[index].preco_decisao_atacado_3 = valor;
    }

    setRows(rowsAtualizada);
  }

  function bindDosValores4(e, index, atacado = false) {
    const valor = e.target.value;

    let rowsAtualizada = [...rows];

    if (!atacado) {
      rowsAtualizada[index].preco_decisao_atacado_4 = valor;
    } else {
      rowsAtualizada[index].preco_decisao_atacado_4 = valor;
    }

    setRows(rowsAtualizada);
  }

  function bindDosValoresRedeLucas(e, index, atacado = false) {
    const valor = e.target.value;

    let rowsAtualizada = [...rows];

    if (!atacado) {
      rowsAtualizada[index].preco_decisao_atacado_3 = valor;
    } else {
      rowsAtualizada[index].preco_decisao_atacado_3 = valor;
    }

    setRows(rowsAtualizada);
  }

  async function atualizaValores(e, index, atacado = false) {
    let valor = parseFloat(e.target.value, 2);

    let rowsAtualizada = [...rows];

    if (isNaN(valor)) {
      if (!atacado) {
        valor = rowsAtualizada[index].sugestao_final;
        rowsAtualizada[index].preco_decisao_atacado =
          rowsAtualizada[index].preco_decisao_atacado_default;
      } else {
        valor = rowsAtualizada[index].novo_preco_atacado;
        rowsAtualizada[index].preco_decisao_atacado =
          rowsAtualizada[index].novo_preco_atacado;
      }
    }

    console.log(atacado);

    const salvar = atacado
      ? {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: rowsAtualizada[index].exportado,
          novo_preco_atacado: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        }
      : {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: "0",
          preco_decisao: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        };

    const apiSalvar = await fetch(apiUrl + "gestao/update", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltroSemEspaco(salvar).replaceAll(" ", ""),
    });

    const resultadoUpdate = await apiSalvar.json();

    resultadoUpdate.data.forEach(function (value, posicao) {
      const cluster_simulador = value.update_values.cluster_simulador;
      const codigo_filhos = value.update_values.codigo_filhos;
      rowsAtualizada.forEach(function (produto, i) {
        if (!produto) {
          return;
        }
        if (
          codigo_filhos === produto.codigo_filhos &&
          cluster_simulador === produto.cluster_simulador
        ) {
          rowsAtualizada[i] = value.update_values;
        }
      });
    });
    rowsAtualizada = rowsAdapter(
      rowsAtualizada,
      atacadistas.includes(user?.dbschema)
    );

    if (!atacado) {
      rowsAtualizada[index].preco_decisao = valor;
      rowsAtualizada[index].variacao_preco_novo =
        resultadoUpdate.data[0].update_values.var_novo_preco;

      if (user.dbschema === "lopes") {
        rowsAtualizada[index].margem_nova = rowsAtualizada[index].margem_nova;
      } else {
        rowsAtualizada[index].margem_nova =
          resultadoUpdate.data[0].update_values.margem_nova;
      }

      rowsAtualizada[index].novo_preco_atacado =
        rowsAtualizada[index].preco_decisao_atacado;

      rowsAtualizada[index].indice_novo_preco =
        rowsAtualizada[index].indice_novo_preco_regular;

      rowsAtualizada[index].indiceatual =
        rowsAtualizada[index].indice_regular_atual;

      rowsAtualizada[index].indice_novo_preco =
        resultadoUpdate.data[0].update_values.indice_novo_preco_regular;

      rowsAtualizada[index].diferenca_unitario =
        resultadoUpdate.data[0].update_values.diferenca_preco_unitario;

      rowsAtualizada[index].diferenca_total =
        resultadoUpdate.data[0].update_values.diferenca_preco_total;

      rowsAtualizada[index].qtde_vendas_estimada =
        resultadoUpdate.data[0].update_values.qtde_vendas_estimada;
    } else {
      rowsAtualizada[index].preco_decisao_atacado = valor;
    }

    if (!atacado) {
      rowsAtualizada[index].seta = SetasNovoPreco(valor, rowsAtualizada[index]);
    } else {
      rowsAtualizada[index].seta_atacado = SetasNovoPrecoAtacado(
        valor,
        rowsAtualizada[index],
        atacado
      );
    }

    rowsAtualizada[index].valorValido = Calculadora.valorValido(
      rowsAtualizada[index]
    );
    rowsAtualizada[index].valorValidoAtacado = Calculadora.valorValidoAtacado(
      rowsAtualizada[index],
      atacadistas.includes(user?.dbschema)
    );
    if (user.dbschema === "ikesaki") {
      rowsAtualizada[index].valorValido2 = Calculadora.valorValido2(
        rowsAtualizada[index].var_novo_preco
      );
    }
    if (
      resultadoUpdate.data[0] &&
      resultadoUpdate.data[0].update_preco_decisao
    ) {
      rowsAtualizada[index].preco_decisao_atacado =
        resultadoUpdate.data[0].update_preco_decisao;
    }

    if (resultadoUpdate.data.update_preco_decisao) {
      rowsAtualizada[index].preco_decisao_atacado =
        resultadoUpdate.data.update_preco_decisao;
    }

    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );

    setRows(rowsAtualizada);
    setTotalizadores(totalizadores.data[0]);
  }

  async function atualizaValores3(e, index, atacado = false) {
    let valor = parseFloat(e.target.value, 2);

    let rowsAtualizada = [...rows];

    if (isNaN(valor)) {
      if (!atacado) {
        valor = rowsAtualizada[index].preco_min_max;
        rowsAtualizada[index].preco_decisao_atacado_3 =
          rowsAtualizada[index].preco_decisao_atacado_default;
      } else {
        valor = rowsAtualizada[index].novo_preco_atacado_3;
        rowsAtualizada[index].preco_decisao_atacado_3 =
          rowsAtualizada[index].novo_preco_atacado_3;
      }
    }

    const salvar = atacado
      ? {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: rowsAtualizada[index].exportado,
          novo_preco_atacado_3: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        }
      : {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: "0",
          preco_decisao_atacado_3: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        };

    const apiSalvar = await fetch(apiUrl + "gestao/update", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltroSemEspaco(salvar).replaceAll(" ", ""),
    });

    const resultadoUpdate = await apiSalvar.json();

    resultadoUpdate.data.forEach(function (value, posicao) {
      const cluster_simulador = value.update_values.cluster_simulador;
      const codigo_filhos = value.update_values.codigo_filhos;
      rowsAtualizada.forEach(function (produto, i) {
        if (!produto) {
          return;
        }
        if (
          codigo_filhos === produto.codigo_filhos &&
          cluster_simulador === produto.cluster_simulador
        ) {
          rowsAtualizada[i] = value.update_values;
        }
      });
    });
    rowsAtualizada = rowsAdapter(
      rowsAtualizada,
      atacadistas.includes(user?.dbschema)
    );

    if (!atacado) {
      rowsAtualizada[index].preco_decisao_atacado_3 = valor;
      rowsAtualizada[index].variacao_preco_novo =
        resultadoUpdate.data[0].update_values.var_novo_preco;

      rowsAtualizada[index].margem_nova =
        resultadoUpdate.data[0].update_values.margem_nova;

      rowsAtualizada[index].novo_preco_atacado_3 =
        rowsAtualizada[index].preco_decisao_atacado;

      rowsAtualizada[index].indice_novo_preco =
        rowsAtualizada[index].indice_novo_preco_regular;

      rowsAtualizada[index].indiceatual =
        rowsAtualizada[index].indice_regular_atual;

      rowsAtualizada[index].indice_novo_preco =
        resultadoUpdate.data[0].update_values.indice_novo_preco_regular;

      rowsAtualizada[index].diferenca_unitario =
        resultadoUpdate.data[0].update_values.diferenca_preco_unitario;

      rowsAtualizada[index].diferenca_total =
        resultadoUpdate.data[0].update_values.diferenca_preco_total;

      rowsAtualizada[index].qtde_vendas_estimada =
        resultadoUpdate.data[0].update_values.qtde_vendas_estimada;
    } else {
      rowsAtualizada[index].preco_decisao_atacado_3 = valor;
    }

    rowsAtualizada[index].valorValido = Calculadora.valorValido(
      rowsAtualizada[index]
    );
    rowsAtualizada[index].valorValidoAtacado = Calculadora.valorValidoAtacado(
      rowsAtualizada[index],
      atacadistas.includes(user?.dbschema)
    );
    if (user.dbschema === "ikesaki") {
      rowsAtualizada[index].valorValido2 = Calculadora.valorValido2(
        rowsAtualizada[index].var_novo_preco
      );
    }
    if (
      resultadoUpdate.data[0] &&
      resultadoUpdate.data[0].update_preco_decisao
    ) {
      rowsAtualizada[index].preco_decisao_atacado_3 =
        resultadoUpdate.data[0].update_preco_decisao;
    }

    if (resultadoUpdate.data.update_preco_decisao) {
      rowsAtualizada[index].preco_decisao_atacado_3 =
        resultadoUpdate.data.update_preco_decisao;
    }

    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );

    setRows(rowsAtualizada);
    setTotalizadores(totalizadores.data[0]);
  }

  async function atualizaValores4(e, index, atacado = false) {
    let valor = parseFloat(e.target.value, 2);

    let rowsAtualizada = [...rows];

    if (isNaN(valor)) {
      if (!atacado) {
        valor = rowsAtualizada[index].preco_min_max;
        rowsAtualizada[index].preco_decisao_atacado_4 =
          rowsAtualizada[index].preco_decisao_atacado_default;
      } else {
        valor = rowsAtualizada[index].novo_preco_atacado_4;
        rowsAtualizada[index].preco_decisao_atacado_4 =
          rowsAtualizada[index].novo_preco_atacado_4;
      }
    }

    const salvar = atacado
      ? {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: rowsAtualizada[index].exportado,
          novo_preco_atacado_4: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        }
      : {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: "0",
          preco_decisao_atacado_4: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        };

    const apiSalvar = await fetch(apiUrl + "gestao/update", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltroSemEspaco(salvar).replaceAll(" ", ""),
    });

    const resultadoUpdate = await apiSalvar.json();

    resultadoUpdate.data.forEach(function (value, posicao) {
      const cluster_simulador = value.update_values.cluster_simulador;
      const codigo_filhos = value.update_values.codigo_filhos;
      rowsAtualizada.forEach(function (produto, i) {
        if (!produto) {
          return;
        }
        if (
          codigo_filhos === produto.codigo_filhos &&
          cluster_simulador === produto.cluster_simulador
        ) {
          rowsAtualizada[i] = value.update_values;
        }
      });
    });
    rowsAtualizada = rowsAdapter(
      rowsAtualizada,
      atacadistas.includes(user?.dbschema)
    );

    if (!atacado) {
      rowsAtualizada[index].preco_decisao_atacado_4 = valor;
      rowsAtualizada[index].variacao_preco_novo =
        resultadoUpdate.data[0].update_values.var_novo_preco;

      rowsAtualizada[index].margem_nova =
        resultadoUpdate.data[0].update_values.margem_nova;

      rowsAtualizada[index].novo_preco_atacado_4 =
        rowsAtualizada[index].preco_decisao_atacado_4;

      rowsAtualizada[index].indice_novo_preco =
        rowsAtualizada[index].indice_novo_preco_regular;

      rowsAtualizada[index].indiceatual =
        rowsAtualizada[index].indice_regular_atual;

      rowsAtualizada[index].indice_novo_preco =
        resultadoUpdate.data[0].update_values.indice_novo_preco_regular;

      rowsAtualizada[index].diferenca_unitario =
        resultadoUpdate.data[0].update_values.diferenca_preco_unitario;

      rowsAtualizada[index].diferenca_total =
        resultadoUpdate.data[0].update_values.diferenca_preco_total;

      rowsAtualizada[index].qtde_vendas_estimada =
        resultadoUpdate.data[0].update_values.qtde_vendas_estimada;
    } else {
      rowsAtualizada[index].preco_decisao_atacado_4 = valor;
    }

    rowsAtualizada[index].valorValido = Calculadora.valorValido(
      rowsAtualizada[index]
    );
    rowsAtualizada[index].valorValidoAtacado = Calculadora.valorValidoAtacado(
      rowsAtualizada[index],
      atacadistas.includes(user?.dbschema)
    );
    if (user.dbschema === "ikesaki") {
      rowsAtualizada[index].valorValido2 = Calculadora.valorValido2(
        rowsAtualizada[index].var_novo_preco
      );
    }
    if (
      resultadoUpdate.data[0] &&
      resultadoUpdate.data[0].update_preco_decisao
    ) {
      rowsAtualizada[index].preco_decisao_atacado_4 =
        resultadoUpdate.data[0].update_preco_decisao;
    }

    if (resultadoUpdate.data.update_preco_decisao) {
      rowsAtualizada[index].preco_decisao_atacado_4 =
        resultadoUpdate.data.update_preco_decisao;
    }

    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );

    setRows(rowsAtualizada);
    setTotalizadores(totalizadores.data[0]);
  }

  async function atualizaValoresRedeLucas(e, index, atacado = false) {
    let valor = parseFloat(e.target.value, 2);

    let rowsAtualizada = [...rows];

    if (isNaN(valor)) {
      if (!atacado) {
        valor = rowsAtualizada[index].preco_min_max;
        rowsAtualizada[index].preco_decisao_atacado_3 =
          rowsAtualizada[index].preco_decisao_atacado_default;
      } else {
        valor = rowsAtualizada[index].novo_preco_atacado_3;
        rowsAtualizada[index].preco_decisao_atacado_3 =
          rowsAtualizada[index].novo_preco_atacado_3;
      }
    }

    const salvar = atacado
      ? {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: rowsAtualizada[index].exportado,
          novo_preco_atacado_3: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        }
      : {
          cluster: rowsAtualizada[index].cluster_simulador,
          codigo_pai: rowsAtualizada[index].codigo_pai,
          analizado: "1",
          exportado: "0",
          preco_decisao_atacado_3: valor,
          uid: user.uid,
          db_schema: user.dbschema,
        };

    const apiSalvar = await fetch(apiUrl + "gestao/update", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltroSemEspaco(salvar).replaceAll(" ", ""),
    });

    const resultadoUpdate = await apiSalvar.json();

    resultadoUpdate.data.forEach(function (value, posicao) {
      const cluster_simulador = value.update_values.cluster_simulador;
      const codigo_filhos = value.update_values.codigo_filhos;
      rowsAtualizada.forEach(function (produto, i) {
        if (!produto) {
          return;
        }
        if (
          codigo_filhos === produto.codigo_filhos &&
          cluster_simulador === produto.cluster_simulador
        ) {
          rowsAtualizada[i] = value.update_values;
        }
      });
    });
    rowsAtualizada = rowsAdapter(
      rowsAtualizada,
      atacadistas.includes(user?.dbschema)
    );

    if (!atacado) {
      rowsAtualizada[index].preco_decisao_atacado_4 = valor;
      rowsAtualizada[index].variacao_preco_novo =
        resultadoUpdate.data[0].update_values.var_novo_preco;

      rowsAtualizada[index].margem_nova =
        resultadoUpdate.data[0].update_values.margem_nova;

      rowsAtualizada[index].novo_preco_atacado_4 =
        rowsAtualizada[index].preco_decisao_atacado_4;

      rowsAtualizada[index].indice_novo_preco =
        rowsAtualizada[index].indice_novo_preco_regular;

      rowsAtualizada[index].indiceatual =
        rowsAtualizada[index].indice_regular_atual;

      rowsAtualizada[index].indice_novo_preco =
        resultadoUpdate.data[0].update_values.indice_novo_preco_regular;

      rowsAtualizada[index].diferenca_unitario =
        resultadoUpdate.data[0].update_values.diferenca_preco_unitario;

      rowsAtualizada[index].diferenca_total =
        resultadoUpdate.data[0].update_values.diferenca_preco_total;

      rowsAtualizada[index].qtde_vendas_estimada =
        resultadoUpdate.data[0].update_values.qtde_vendas_estimada;
    } else {
      rowsAtualizada[index].preco_decisao_atacado_4 = valor;
    }

    rowsAtualizada[index].valorValido = Calculadora.valorValido(
      rowsAtualizada[index]
    );
    rowsAtualizada[index].valorValidoAtacado = Calculadora.valorValidoAtacado(
      rowsAtualizada[index],
      atacadistas.includes(user?.dbschema)
    );
    if (user.dbschema === "ikesaki") {
      rowsAtualizada[index].valorValido2 = Calculadora.valorValido2(
        rowsAtualizada[index].var_novo_preco
      );
    }
    if (
      resultadoUpdate.data[0] &&
      resultadoUpdate.data[0].update_preco_decisao
    ) {
      rowsAtualizada[index].preco_decisao_atacado_4 =
        resultadoUpdate.data[0].update_preco_decisao;
    }

    if (resultadoUpdate.data.update_preco_decisao) {
      rowsAtualizada[index].preco_decisao_atacado_4 =
        resultadoUpdate.data.update_preco_decisao;
    }

    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );

    setRows(rowsAtualizada);
    setTotalizadores(totalizadores.data[0]);
  }

  const separator = () => separatorModule(user);

  async function exportarDados() {
    const dataInput = document.querySelector("#data").value;
    if (!dataInput) {
      Toastify({
        text: "Selecione a data para exportação",
        duration: 6000,
        newWindow: true,
        close: true,
        stopOnFocus: true,
        onClick: function () {},
      }).showToast();
      return;
    }
    document.querySelector(".modal_close").click();
    setLoader(true);
    const data = new Date(dataInput);
    const dataDeExportacao = new Date(data.setDate(data.getDate() + 1));

    const exportar = {
      db_schema: user.dbschema,
      uid: user.uid,
      data: dataDeExportacao.toLocaleDateString(),
    };

    const exportDataColumns = await fetch(user.apiurl + "gestao/export", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(exportar),
    });

    const exportDataJson = await exportDataColumns.json();
    await setApiExportData(exportDataJson.data);

    setSelectAll(false);

    const apiExportarCSV = await fetch(
      process.env.REACT_APP_API + "/api/v2/gestao/file-download",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          db_schema: user.dbschema,
          data: dataDeExportacao.toLocaleDateString(),
        }),
      }
    );

    const dadosExportarApi = await apiExportarCSV.json();
    const dadosExportarFix = dadosExportarApi.data.map((data) => {
      return {
        ...data,
        embalagem: 1,
        empresa:
          Number(data.empresa) < 10
            ? `0${Number(data.empresa)}`
            : Number(data.empresa),
      };
    });
    user.dbschema == "avenida"
      ? setDadosExportar(dadosExportarFix)
      : setDadosExportar(dadosExportarApi.data);

    setExportar(true);

    const listaExportados = {
      uid: user.uid,
      registros: "150",
      pagina: "1",
      db_schema: user.dbschema,
    };

    const apiListaExportados = await fetch(
      user.apiurl + "gestao/update/lista-itens-exportados",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(listaExportados),
      }
    );

    const itensExportados = await apiListaExportados.json();
    let rowsNew = await rowsAdapter(
      itensExportados.data,
      atacadistas.includes(user?.dbschema)
    );
    setRegistros(itensExportados.registros);
    setTotalDePaginas(itensExportados.paginas);
    setLoader(false);
    setRowsExportacao(rowsNew);
    TotalizadoresIndividuais();
  }

  async function exportarDadosApiAsun() {
    try {
      const apiExportarPrecoLogin = await fetch(
        process.env.REACT_APP_API_EXPORT_ASUN + "/api/v1/auth/login",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            company: 1,
            username: "PRICEMET",
            password: "teste@2024",
          }),
        }
      );
      const json = await apiExportarPrecoLogin.json();
      const token = `${json.token_type} ${json.access_token}`;
      await localStorage.setItem("exportApiTokenAsun", token);
    } catch (err) {
      console.error(err);
    }
  }

  async function exportarDadosApiSemar() {
    try {
      const apiExportarPrecoLogin = await fetch(
        process.env.REACT_APP_API_EXPORT_SEMAR +
          "/semar-homolog/seguranca/login/login.action",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            username: "Pricemet.api",
            password: "Pricemet@Api",
          }),
        }
      );
      const json = await apiExportarPrecoLogin.json();
      const token = `Bearer ${json.access_token}`;
      await localStorage.setItem("exportApiTokenSemar", token);
    } catch (err) {
      console.error(err);
    }
  }

  function proxCampo(e, index, atacado = false, render_input) {
    if (e.key === "Enter") {
      const form = e.target.form;
      const enter = Array.prototype.indexOf.call(form, e.target);
      if (form.elements[enter - 1].checked === false) {
        form.elements[enter - 1].click();
      }
      const arrInputs = Object.keys(form.elements)
        .map((key) => form.elements[key])
        .filter(
          (x) => x.className === e.target.className && x.type !== "checkbox"
        );

      const inputToJump = Object.keys(form.elements)
        .map((key) => form.elements[key])
        .filter(
          (x) =>
            x.className === "undefined type_valor render_input_1" &&
            x.type !== "checkbox"
        );

      switch (render_input) {
        case "render_input_2":
          atualizaValores(e, index, atacado);
          break;
        case "render_input_3":
          atualizaValores3(e, index, atacado);
          break;
        case "render_input_4":
          atualizaValores4(e, index, atacado);
          break;
        case "render_input_5":
          atualizaValoresRedeLucas(e, index, atacado);
          break;
        default:
          atualizaValores(e, index, atacado);
      }

      const i = inputToJump.indexOf.call(arrInputs, e.target);
      inputToJump[i + 1].focus();
      e.preventDefault();
    }
    let rowsAtualizada = [...rows];

    setRows(rowsAtualizada);
  }

  async function resetarTabela() {
    setLoader(true);
    let precos = await apiHelper.precos(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );
    let precosObj = rowsAdapter(
      precos.data,
      atacadistas.includes(user?.dbschema)
    );
    setRows(precosObj);
    setSearch("");
    document.getElementById("search-formulario").value = "";
    const totalizadoresResultado = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );
    setTotalizadores(totalizadoresResultado.data[0]);
    setExportar(false);
    setEditados(false);
    setDifTotal(false);
    setLoader(false);
    setBloqueados(false);
  }

  async function fetchMinMax() {
    // const userApi = await fetch(
    //   "https://apipp.prixsia.com/api/users/config/" +
    //     Auth.user.attributes.email
    // );

    // const userObj = await userApi.json();

    const apiMinMaxSlider = await fetch(
      user.apiurl + "gestao/classificador/valores-min-max-sliders",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({ ...selectedfiltro, analizados: editados ? 1 : 0 }),
      }
    );

    const minMax = apiMinMaxSlider.json();

    let dataA = minMax.data.data_ultimo_custo_minimo.split("/");
    minMax.data.dataInicial = new Date(
      parseInt(dataA[2]),
      parseInt(dataA[1]) - 1,
      parseInt(dataA[0])
    );

    let dataB = minMax.data.data_ultimo_custo_maximo.split("/");
    minMax.data.dataFinal = new Date(
      parseInt(dataB[2]),
      parseInt(dataB[1]) - 1,
      parseInt(dataB[0])
    );
    setSlider(minMax.data);
  }

  async function getUser() {
    const userApi = await fetch(
      process.env.REACT_APP_API + "/api/users/config/" + usuario.email
      //"https://apipp.prixsia.com/api/users/config/" + Auth.user.attributes.email
    );

    const userObj = await userApi.json();

    userObj.data.apiurl = userObj.data.system.url;
    userObj.data.uid = userObj?.data?.user?.idexport ?? " ";
    userObj.data.dbschema = userObj.data.system.schema;

    if (userObj.data.dbschema === "swift") {
      window.location = "/swift";
    }

    if (userObj.data.user.idexport !== "") {
      userObj.data.uid = userObj.data.user.idexport;
    }

    return userObj.data;
  }

  useEffect(() => {
    async function fetchMyAPI() {
      const userApi = await fetch(
        process.env.REACT_APP_API + "/api/users/config/" + usuario.email
      );

      const userObj = await userApi.json();

      userObj.data.apiurl = userObj.data.system.url;
      userObj.data.uid = userObj?.data?.user?.idexport ?? " ";
      userObj.data.dbschema = userObj.data.system.schema;

      if (userObj.data.dbschema === "swift") {
        window.location = "/swift";
      }

      setUser(userObj.data);
      selectedfiltro.db_schema = userObj.data.system.schema;

      setSelectedfiltro({ ...selectedfiltro });
      setApiUrl(userObj.data.system.url);

      // const response = await fetch(user.data.listUsers.items[0].apiurl.replace('/v1', '') + "users/config/fernando.antonio@bigbox.com.br");
      const response = await fetch(
        process.env.REACT_APP_API + "/api/users/config/" + usuario.email

        //"https://apipp.prixsia.com/api/users/config/" +
        //  Auth.user.attributes.email
      );
      const userData = await response.json();

      const configFiltro = userData.data.filters;
      let temFiltro = false;
      let userFiltersConfig = JSON.stringify(userData.data.filters).replaceAll(
        ",",
        ";"
      );

      let filtrosApiFiltro = selectedfiltro;

      Object.keys(configFiltro).forEach((key) => {
        if (!!configFiltro[key]) {
          temFiltro = true;
          if (typeof configFiltro[key] === "string") {
            configFiltro[key] = [configFiltro[key]];
            filtrosApiFiltro[key] = configFiltro[key];
          }
        }
      });

      setFiltrosTravados(clean(configFiltro));
      // configFiltro.nome_bandeira = configFiltro.bandeira;
      const filtrosNoFormato = [{ ...configFiltro }];
      setTb(filtrosNoFormato);

      filtrosApiFiltro.userFilters = userFiltersConfig;
      // if(!temFiltro) {
      const novosFiltros = await fetch(
        `${userObj.data.system.url}gestao/filtro-dependentes/${userObj.data.user.id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "POST",
          body: itensFiltro(filtrosApiFiltro).replaceAll(" ", ""),
        }
      );

      const filtroApi = await novosFiltros.json();
      setTb(filtroApi.data);
      // }
      const apiGestao = await fetch(
        userObj.data.system.url + "gestao/filtro/" + userObj.data.user.id,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "POST",
          body: itensFiltro({
            ...selectedfiltro,
            analizados: editados ? 1 : 0,
          }).replaceAll(" ", ""),
        }
      );

      const gestaoResultado = await apiGestao.json();

      userObj.data.orderBy = gestaoResultado.orderBy;
      //userObj.data.columns = gestaoResultado.columns || undefined
      const totalizadoresResultado = await apiHelper.totalizadores(
        selectedfiltro,
        userObj.data.system.url,
        userObj.data.user.id
      );

      let rows = gestaoResultado.data;
      let rowsNew = rowsAdapter(rows, userObj.data.system.schema === "roldao");

      setTotalizadores(totalizadoresResultado.data[0]);
      // setFiltrosDaAPI(JSON.parse(JSON.stringify(data.data)));
      setProdutos(gestaoResultado);
      setRowsDefault(rows);
      setRows(rowsNew);
      setRegistros(gestaoResultado.registros);
      setTotalDePaginas(gestaoResultado.paginas);

      const apiMinMaxSlider = await fetch(
        userObj.data.system.url +
          "gestao/classificador/valores-min-max-sliders",
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "POST",
          body: itensFiltro({
            ...selectedfiltro,
            analizados: editados ? 1 : 0,
          }),
        }
      );

      const minMax = await apiMinMaxSlider.json();

      if (minMax.data) {
        let dataA = minMax.data.data_ultimo_custo_minimo.split("/");
        minMax.data.dataInicial = new Date(
          parseInt(dataA[2]),
          parseInt(dataA[1]) - 1,
          parseInt(dataA[0])
        );

        let dataB = minMax.data.data_ultimo_custo_maximo.split("/");
        minMax.data.dataFinal = new Date(
          parseInt(dataB[2]),
          parseInt(dataB[1]) - 1,
          parseInt(dataB[0])
        );
        setSlider(minMax.data);
      }
    }

    fetchMyAPI();
    // Não remover a linha abaixo para evitar auto correção e loop de loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setSliderDate(valor, chave) {
    let sliderObjeto = slider;
    sliderObjeto[chave] = valor.toLocaleDateString();

    if (chave === "data_ultimo_custo_minimo") {
      sliderObjeto.dataInicial = valor;
    } else {
      sliderObjeto.dataFinal = valor;
    }
    setSlider(sliderObjeto);
    handleFiltrosSlider(slider.dataInicial, slider.dataFinal, 4);
  }

  async function renderSlider() {
    if (showSlider === true) {
      setShowSlider(false);
      return;
    }
    setShowSlider(true);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rowsAdapter = (rows, atacado = false) =>
    rowsAdapterModule(rows, atacado);

  const isInt = (value) => isIntModule(value);

  const ordenar = async (key) => {
    setLoader(true);

    let direction = "asc";

    if (key === orderBy) {
      direction = orderDirection === "asc" ? "desc" : "asc";
    }

    if (selectedfiltro.slider) {
      try {
        const payload = { ...selectedfiltro, order_by: key, direction };

        const response = await fetch(
          user.apiurl +
            "gestao/classificador/slider-filtro-order/" +
            user.user.id,
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "POST",
            body: itensFiltro(payload),
          }
        );

        const data = await response.json();
        let rows = data.data;

        if (data.registros) {
          setRegistros(data.registros);
          setTotalDePaginas(data.paginas);
        }
        if (!rows) {
          return;
        }

        let rowsNew = rowsAdapter(rows, atacadistas.includes(user?.dbschema));
        setRows(rowsNew);
      } catch (e) {}
    } else {
      try {
        const payload = { ...selectedfiltro, order_by: key, direction };

        const response = await fetch(
          user.apiurl + "gestao/order/filtro/" + user.user.id,
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "POST",
            body: itensFiltro(payload),
          }
        );

        const data = await response.json();
        let rows = data.data;

        if (data.registros) {
          setRegistros(data.registros);
          setTotalDePaginas(data.paginas);
        }
        if (!rows) {
          return;
        }

        let rowsNew = rowsAdapter(rows, atacadistas.includes(user?.dbschema));
        setRows(rowsNew);
      } catch (e) {}
    }

    setOrderBy(key);
    setOrderDirection(direction);
    setLoader(false);
  };

  const renderExportButton = () => renderExportButtonModule(user, lang);

  const renderDataAtualizacao = () => renderDataAtualizacaoModule(rows);

  const renderX = () => renderXModule(search, limparSearchDoX);

  // component
  function renderFilters(filter, descricao) {
    if (exportar === true) {
      return;
    }

    if (!tb || !tb[0][filter]) {
      return (
        <select
          className="opcao"
          name={filter}
          id={filter}
          multiple
          value={selectedfiltro[filter] || ""}
          onChange={filtroBandeira}
        >
          <option value="">{descricao}</option>
        </select>
      );
    }
    return (
      <MultipleSelect
        data={tb[0][filter]}
        handle={handleFiltroDependentes}
        descripton={descricao}
        filter={filter}
      />
    );
  }

  const renderBackgroundColor = () =>
    renderBackgroundColorModule(exportar, difTotal, bloqueados);

  // TODO  LIMPEZA EDITADOS AQUI....

  async function apiLimpeza() {
    setLoader(true);
    const apiReset = {
      db_schema: user.dbschema,
      uid: user.uid,
    };

    const apiPesquisaConc = await fetch(
      user.apiurl + "gestao/update/reset-editados",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(apiReset),
      }
    );
    await apiPesquisaConc;
    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );
    setTotalizadores(totalizadores.data[0]);
    setLoader(false);
  }

  function limparBloqueados() {
    let rowsBloqueados = [...rows];
    rows.map((produto, index) => {
      rowsBloqueados[index].analizado = 0;
    });
    let rowsNew = rowsAdapter(
      rowsBloqueados,
      atacadistas.includes(user?.dbschema)
    );
    setRows(rowsNew);
  }

  async function apiLimpezaBloqueados() {
    setLoader(true);
    const apiReset = {
      db_schema: user.dbschema,
      uid: user.uid,
    };
    const apiResetBloqueados = await fetch(
      user.apiurl + "gestao/update/reset-bloqueados",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(apiReset),
      }
    );
    await apiResetBloqueados;
    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );
    setTotalizadores(totalizadores.data[0]);
    setLoader(false);
    limparBloqueados();
  }

  async function handleConcorrente(row, posicao, i) {
    var rowsPesquisa = [...rows];
    const pesquisaConc = {
      cluster: row.cluster_simulador,
      codigo_pai: row.codigo_pai,
      tipo_concorrente: posicao,
      db_schema: user.dbschema,
    };
    if (rowsPesquisa[i].pesquisasExibiPosicao[posicao] === true) {
      rowsPesquisa[i].pesquisasExibiPosicao[posicao] = false;
      setRows(rowsPesquisa);
      return;
    }

    const apiPesquisaConc = await fetch(user.apiurl + "gestao/pesquisas", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(pesquisaConc).replaceAll("'", "").replaceAll(" ", ""),
    });
    const precoConc = await apiPesquisaConc.json();
    if (precoConc.data === null || !apiPesquisaConc.ok) {
      return;
    }

    const { data: prices } = precoConc;

    const priceArray = prices.map((value) => {
      const parts = value.data_pesquisa.split("/");
      const formattedDate = new Date(parts[2], parts[1] - 1, parts[0]);

      const dateInMs = formattedDate.getTime();

      return { ...value, dateInMs };
    });

    const sortedArray = priceArray.sort((a, b) => {
      return b.dateInMs - a.dateInMs;
    });

    rows.map((produto, index) => {
      if (produto.id === row.id) {
        rowsPesquisa[index].pesquisas = sortedArray;
      }
    });

    var rowsNew = rowsAdapter(
      rowsPesquisa,
      atacadistas.includes(user?.dbschema)
    );

    rowsNew[i].pesquisasExibiPosicao[posicao] = true;
    setRows(rowsNew);
  }

  const renderConcorrente = (row, posicao) =>
    renderConcorrenteModule(row, posicao, user.dbschema);

  function handleSelectAll(e) {
    if (selectedfiltro.bandeira === null && selectedfiltro.cluster === null) {
      Toastify({
        text: "Selecione a Bandeira ou o Cluster",
        duration: 300000,
        newWindow: true,
        close: true,
        stopOnFocus: true,
        onClick: function () {},
      }).showToast();
      setSelectAll(false);
      return;
    }

    let newSelect = [...rows];
    newSelect.map((row, index) => {
      row.analizado = e.target.checked;
    });
    setRows(newSelect);
    enviaTudoParaAPI(e.target.checked);
    setSelectAll(e.target.checked);
  }

  async function resetarFiltros(update = false) {
    setItensValidados(false);
    setLoader(true);
    const newFilters = {
      grupo: null,
      fornecedor: null,
      produto: null,
      bandeira: null,
      // nome_bandeira:null,
      sensibilidade: null,
      tem_pesquisa: null,
      papel_categoria: null,
      sub_grupo: null,
      cluster: null,
      departamento: null,
      secao: null,
      escala: null,
      comprador: null,
      nivel_5: null,
      registros: "150",
      pagina: "1",
      analizados: update ? 1 : 0,
      db_schema: user.dbschema,
    };

    newFilters.userFilters = JSON.stringify(newFilters).replaceAll(",", ";");
    setSelectedfiltro(newFilters);

    const totalizadores = await apiHelper.totalizadores(
      newFilters,
      user.apiurl,
      user.user.id
    );
    setTotalizadores(totalizadores.data[0]);
    setRows(rowsDefault);

    setOrderBy("");
    setOrderDirection("asc");

    const usuario = await getUser();
    const configFiltro = usuario.filters;
    let temFiltro = false;

    Object.keys(configFiltro).forEach((key) => {
      if (!!configFiltro[key]) {
        temFiltro = true;
        if (typeof configFiltro[key] === "string") {
          configFiltro[key] = [configFiltro[key]];
        }
      }
    });

    setFiltrosTravados(clean(configFiltro));
    // configFiltro.nome_bandeira = configFiltro.bandeira;
    const filtrosNoFormato = [{ ...configFiltro }];
    setTb(filtrosNoFormato);
    // if(!temFiltro) {
    const novosFiltrosX = await fetch(
      user.system.url + "gestao/filtro-dependentes/" + user.user?.id,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...filtrosDefault,
          analizados: update ? 1 : 0,
        }).replaceAll(" ", ""),
      }
    );

    const filtroApiX = await novosFiltrosX.json();

    setTb(filtroApiX.data);
    // }

    setLoader(false);
  }

  // async function resetarFiltrosDoX() {
  //   setLoader(true);
  //   setSelectedfiltro({
  //     grupo: null,
  //     fornecedor: null,
  //     produto: null,
  //     bandeira: null,
  //     // nome_bandeira:null,
  //     sensibilidade: null,
  //     tem_pesquisa: null,
  //     papel_categoria: null,
  //     sub_grupo: null,
  //     cluster: selectedfiltro.cluster,
  //     departamento: null,
  //     secao: null,
  //     registros: "150",
  //     pagina: "1",
  //     db_schema: user.dbschema,
  //   });

  //   const totalizadores = await apiHelper.totalizadores(
  //     {
  //       grupo: null,
  //       fornecedor: null,
  //       produto: null,
  //       bandeira: null,
  //       // nome_bandeira:null,
  //       sensibilidade: null,
  //       tem_pesquisa: null,
  //       papel_categoria: null,
  //       sub_grupo: null,
  //       cluster: null,
  //       departamento: null,
  //       secao: null,
  //       registros: "150",
  //       pagina: "1",
  //       db_schema: user.dbschema,
  //     },
  //     user.apiurl,
  //     user.user.id
  //   );
  //   setTotalizadores(totalizadores.data[0]);

  //   const configFiltro = user.filters;
  //   let temFiltro = false;

  //   Object.keys(configFiltro).forEach((key) => {
  //     if (!!configFiltro[key]) {
  //       temFiltro = true;
  //       if (typeof configFiltro[key] === "string") {
  //         configFiltro[key] = [configFiltro[key]];
  //       }
  //     }
  //   });

  //   setFiltrosTravados(clean(configFiltro));
  //   // configFiltro.nome_bandeira = configFiltro.bandeira;
  //   const filtrosNoFormato = [{ ...configFiltro }];
  //   setTb(filtrosNoFormato);
  //   let filktro = { ...filtrosDefault };
  //   filktro.cluster = selectedfiltro.cluster;
  //   if (!temFiltro) {
  //     const novosFiltrosX = await fetch(
  //       user.system.url + "gestao/filtro-dependentes/" + user.user.id,
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //         },
  //         method: "POST",
  //         body: itensFiltro(filktro).replaceAll(" ", ""),
  //       }
  //     );

  //     const filtroApiX = await novosFiltrosX.json();

  //     setTb(filtroApiX.data);
  //   }
  //   setLoader(false);
  // }

  const handleSelectField = async (e, index) => {
    let rowsAtualizada = [...rows];
    let value = "0";
    if (e.target.checked) {
      value = "1";
    }

    rowsAtualizada[index].analizado = value;

    setRows(rowsAtualizada);

    const salvar = {
      cluster: rowsAtualizada[index].cluster_simulador,
      codigo_pai: rowsAtualizada[index].codigo_pai,
      analizado: e.target.checked ? "1" : "0",
      exportado: "0",
      preco_decisao:
        rowsAtualizada[index].preco_decisao ||
        rowsAtualizada[index].preco_min_max,
      uid: user.uid,
      db_schema: user.dbschema,
    };

    await Promise.all([
      fetch(user.apiurl + "gestao/classificador/valores-min-max-sliders", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...selectedfiltro,
          analizados: editados ? 1 : 0,
        }),
      }),
      fetch(user.apiurl + "gestao/update", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltroSemEspaco(salvar).replaceAll(" ", ""),
      }),
    ]).then(async ([apiMinMaxSlider]) => {
      const minMax = await apiMinMaxSlider.json();

      if (minMax?.data && minMax?.data?.data_ultimo_custo_minimo) {
        let dataA = minMax.data.data_ultimo_custo_minimo.split("/");
        minMax.data.dataInicial = new Date(
          parseInt(dataA[2]),
          parseInt(dataA[1]) - 1,
          parseInt(dataA[0])
        );
      }
      if (minMax?.data && minMax?.data?.data_ultimo_custo_maximo) {
        let dataB = minMax.data.data_ultimo_custo_maximo.split("/");
        minMax.data.dataFinal = new Date(
          parseInt(dataB[2]),
          parseInt(dataB[1]) - 1,
          parseInt(dataB[0])
        );
      }
      setSlider(minMax.data);
    });

    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );

    setTotalizadores(totalizadores.data[0]);
  };
  // function limparSearch () {
  //     setSearch("")
  //     document.getElementById("search-formulario").reset();
  //     setRows(rowsDefault);
  //     resetarFiltros()
  // }

  async function limparSearchDoX() {
    setSearch("");
    document.getElementById("search-formulario").value = "";
    const filtrosSearch = await apiHelper.precos(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );
    let rowsSearch = rowsAdapter(
      filtrosSearch.data,
      atacadistas.includes(user?.dbschema)
    );
    setRows(rowsSearch);
    // resetarFiltrosDoX() tira placeholder do filtro selecionado
  }

  async function filtroBusca(e) {
    const inputText = e.target.value;
    setSearch(inputText);

    if (e.key !== "Enter") {
      return;
    }
    setLoader(true);

    let clusterSlider = selectedfiltro.cluster;
    if (selectedfiltro.cluster === null) {
      clusterSlider = "";
    }

    if (inputText.length) {
      const apiSearch = await fetch(user.apiurl + "gestao/search", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...selectedfiltro,
          // grupo: selectedfiltro.grupo,
          // fornecedor: selectedfiltro.fornecedor,
          // produto: selectedfiltro.produto,
          // bandeira: selectedfiltro.bandeira,
          // sensibilidade: selectedfiltro.sensibilidade,
          // tem_pesquisa: selectedfiltro.tem_pesquisa,
          // papel_categoria: selectedfiltro.papel_categoria,
          // sub_grupo: selectedfiltro.sub_grupo,
          // departamento: selectedfiltro.departamento,
          // secao: selectedfiltro.secao,
          // escala: selectedfiltro.escala,
          // comprador: selectedfiltro.comprador,

          db_schema: user.dbschema,
          texto: inputText?.toUpperCase(),
          // cluster: selectedfiltro.cluster,
          clientId: user.user.id,
        }),
      });
      // const apiSearch = await fetch(
      //   user.apiurl +
      //     "gestao/search/" +
      //     user.dbschema +
      //     "/" +
      //     inputText.toUpperCase() +
      //     "/" +
      //     clusterSlider +
      //     "?clientId=" + user.user.id,
      //   {
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     },
      //   }
      // );
      const data = await apiSearch.json();
      let rowsNew = rowsAdapter(
        data.data,
        atacadistas.includes(user?.dbschema)
      );
      setRows(rowsNew);
      setRegistros(data.registros);
      setTotalDePaginas(data.paginas);
    } else {
      Toastify({
        text: "Pesquisa não pode estar vazia",
        duration: 5000,
        close: true,
        stopOnFocus: true,
        backgroundColor:
          "linear-gradient(115deg, #f0c750 0, #f1be46 25%, #f2b53c 50%, #f3ab33 75%, #f4a22b 100%)",
        onClick: function () {},
      }).showToast();
    }
    setLoader(false);
  }

  async function handleFiltrosSlider(valorabaixo, valoracima, sliderNumero) {
    let sliderObjeto = [...sliderValores];
    sliderObjeto[sliderNumero - 1].abaixo_de = valorabaixo;
    sliderObjeto[sliderNumero - 1].acima_de = valoracima;
    setSliderValores(sliderObjeto);
  }

  async function filtrarRangeData() {
    let filtrosSlider = {
      ...selectedfiltro,
      slider: 4,
      data_ini: slider.data_ultimo_custo_minimo,
      data_fim: slider.data_ultimo_custo_maximo,
      analizado: editados ? 1 : 0,
    };
    setLoader(true);

    const apiFiltrosSlider = await fetch(
      user.apiurl + "gestao/classificador/slider-filtro/" + user.user.id,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(filtrosSlider).replaceAll(" ", ""),
      }
    );

    const data = await apiFiltrosSlider.json();
    setDifTotal(true);
    let rowsNew = rowsAdapter(data.data);
    setRows(rowsNew);
    setLoader(false);
    setRegistros(data.registros);
    setTotalDePaginas(data.paginas);
    setShowSlider(false);
    setSelectedfiltro(filtrosSlider);
  }

  async function filtrarSlider(sliderNumero) {
    let sliderObjeto = [...sliderValores];
    let filtrosSlider = {
      ...selectedfiltro,

      slider: sliderNumero,
      maioriguala: "0",
      abaixo_de: sliderObjeto[sliderNumero - 1].abaixo_de,
      acima_de: sliderObjeto[sliderNumero - 1].acima_de,
      pagina: 1,
      analizados: editados ? 1 : 0,
    };
    setLoader(true);
    const apiFiltrosSlider = await fetch(
      user.apiurl + "gestao/classificador/slider-filtro/" + user.user.id,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(filtrosSlider).replaceAll(" ", ""),
      }
    );

    const data = await apiFiltrosSlider.json();
    setDifTotal(true);
    let rowsNew = rowsAdapter(data.data, atacadistas.includes(user?.dbschema));
    setRows(rowsNew);
    setLoader(false);
    setRegistros(data.registros);
    setPage(0);
    setTotalDePaginas(data.paginas);
    setShowSlider(false);
    setSelectedfiltro(filtrosSlider);
  }

  async function handleFiltroDependentes(chave, valor, update = false) {
    if (!update) {
      updateSelectedFilters(chave, valor);
    } else {
      setSelectedfiltro(filtrosDefault);
    }

    let userFiltersConfig = JSON.stringify(user.filters).replaceAll(",", ";");

    const filtroPayload = !update
      ? { ...selectedfiltro }
      : { ...filtrosDefault };

    Object.keys(filtrosTravados).forEach((key) => {
      filtroPayload[key] = filtrosTravados[key];
    });

    let filtrosApiFiltro = {
      ...filtroPayload,
      analizados: update || editados ? 1 : 0,
    };

    filtrosApiFiltro.userFilters = userFiltersConfig;
    const novosFiltros = await fetch(
      user.apiurl + "gestao/filtro-dependentes/" + user.user.id,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(filtrosApiFiltro).replaceAll(" ", ""),
      }
    );

    const filtroApi = await novosFiltros.json();

    let filtros = [...tb];

    filtros[0].bandeira = filtroApi.data[0].bandeira;
    filtros[0].cluster = filtroApi.data[0].cluster;
    filtros[0].departamento = filtroApi.data[0].departamento;
    filtros[0].secao = filtroApi.data[0].secao;
    filtros[0].grupo = filtroApi.data[0].grupo;
    filtros[0].sub_grupo = filtroApi.data[0].sub_grupo;
    filtros[0].fornecedor = filtroApi.data[0].fornecedor;
    filtros[0].papel_categoria = filtroApi.data[0].papel_categoria;
    filtros[0].sensibilidade = filtroApi.data[0].sensibilidade;
    filtros[0].escala = filtroApi.data[0].escala;
    filtros[0].comprador = filtroApi.data[0].comprador;
    filtros[0].nivel_5 = filtroApi.data[0].nivel_5;

    filtros[0].tem_pesquisa = filtroApi.data[0].tem_pesquisa;

    setTb(filtros);
  }

  async function filtroBandeira(e = null) {
    setSelectAll(false);
    setItensValidados((prev) => (e?.edit == true ? true : false ?? prev));
    setEditados((prev) => (e?.edit == true ? true : false ?? prev));

    const itensValidacao = e?.edit ?? false;

    let url = user.apiurl + "gestao/filtro/" + user.user.id;

    if (e?.target?.name && e?.target?.value) {
      updateSelectedFilters(e.target.name, e.target.value);

      if (e && e.target.name === "flag_pai_ou_filho") {
        let valor = e.target.value;
        setFilhos(valor);
        if (valor === "FILHO") {
          url = user.apiurl + `gestao/filtro/paisefilhos/${user.user.id}`;
        }
        if (valor === "FTP") {
          url = user.apiurl + `gestao/filtro/proporcionais/${user.user.id}`;
        }
      }
    }
    setLoader(true);

    const page = e?.page?.target ? e?.page ?? 1 : 1;

    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro({
        ...selectedfiltro,
        pagina: page,
        analizados: e?.edit ? 1 : 0,
      }),
    });

    const data = await response.json();

    let rowsFiltrada = data.data;

    if (data.registros) {
      setRegistros(data.registros);
      setSelectedfiltro((prevState) => ({ ...prevState, pagina: "1" })); // Reset page
      setPage(0);
      setTotalDePaginas(data.paginas);
    }
    if (!rowsFiltrada) {
      return;
    }

    let rowsNew = rowsAdapter(
      rowsFiltrada,
      atacadistas.includes(user?.dbschema)
    );
    setRows(rowsNew);

    const apiTotalizadores = await fetch(
      user.apiurl + "gestao/totalizadores/" + user.user.id,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...selectedfiltro,
          analizados: itensValidacao ? 1 : 0,
        }),
      }
    );

    const totalizadoresResultado = await apiTotalizadores.json();

    if (totalizadoresResultado.data) {
      setTotalizadores(totalizadoresResultado.data[0]);
    }

    const apiMinMaxSlider = await fetch(
      user.apiurl + "gestao/classificador/valores-min-max-sliders",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...selectedfiltro,
          // itensValidacao: itensValidacao ? 1 : 0,
          analizados: editados ? 1 : 0,
        }),
      }
    );

    const minMax = await apiMinMaxSlider.json();

    if (minMax.data && minMax.data.data_ultimo_custo_minimo) {
      let dataA = minMax.data.data_ultimo_custo_minimo.split("/");
      minMax.data.dataInicial = new Date(dataA[2], dataA[1] - 1, dataA[0]);

      let dataB = minMax.data.data_ultimo_custo_maximo.split("/");
      minMax.data.dataFinal = new Date(dataB[2], dataB[1] - 1, dataB[0]);
    }
    setSlider(minMax.data);

    // setTotalDePaginas(data.paginas);

    setLoader(false);
  }

  async function updateSelectedFilters(chave, valor) {
    if (selectedfiltro[chave] === null && valor.length === 0) {
      return;
    }
    if (selectedfiltro[chave] === valor) {
      return;
    }

    setSelectAll(false);
    selectedfiltro[chave] = valor;
    selectedfiltro.registros = 150;
    // selectedfiltro.sub_grupo = selectedfiltro.subgrupo || null;
    // selectedfiltro.bandeira = selectedfiltro.nome_bandeira || null;
    selectedfiltro.pagina = page + 1;

    if (valor.length === 0) {
      selectedfiltro[chave] = null;
    }

    let peso = {
      bandeira: 0,
      cluster: 1,
      departamento: 2,
      secao: 3,
      grupo: 4,
      sub_grupo: 5,
      fornecedor: 6,
      papel_categoria: 7,
      sensibilidade: 8,
      tem_pesquisa: 9,
      nivel_5: 10,
    };

    let pesoAtual = peso[chave];
    for (let filtro in peso) {
      let valorPeso = peso[filtro];
      if (valorPeso > pesoAtual) {
        // selectedfiltro[filtro]=null
      }
    }
  }

  async function TotalizadoresIndividuais() {
    const totalizadoresEditados = {
      db_schema: user.dbschema,
    };
    const apiTotalizadoresIndividuais = await fetch(
      user.apiurl + "gestao/totalizadores-editados",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({ ...selectedfiltro, analizados: editados ? 1 : 0 }),
      }
    );
    const individuais = await apiTotalizadoresIndividuais.json();
    setTotalizadores(individuais.data[0]);
  }

  async function itensBloqueados(pag = null) {
    setLoader(true);
    const itensListaBloqueados = {
      grupo: null,
      fornecedor: null,
      produto: null,
      bandeira: null,
      sensibilidade: null,
      tem_pesquisa: null,
      papel_categoria: null,
      sub_grupo: null,
      cluster: null,
      departamento: null,
      secao: null,
      comprador: null,
      escala: null,
      registros: "150",
      pagina: pag || "1",
      uid: user.uid,
      db_schema: user.dbschema,
    };
    const apiBloqueados = await fetch(
      user.apiurl + "gestao/update/lista-itens-bloqueados",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro(itensListaBloqueados),
      }
    );

    const bloqueados = await apiBloqueados.json();
    let rowsNew = rowsAdapter(
      bloqueados.data,
      atacadistas.includes(user?.dbschema)
    );
    setBloqueados(true);
    TotalizadoresIndividuais();
    setRows(rowsNew);
    setLoader(false);
    setRegistros(bloqueados.registros);
    setTotalDePaginas(bloqueados.paginas);
  }

  async function itensEditados(pag = 1) {
    await resetarFiltros(true);
    filtroBandeira({ page: pag, edit: true });
    handleFiltroDependentes("", "", true);
  }

  async function limparCamposQueNaoTiveremSelecionados() {
    await apiLimpeza();
    const child = document.querySelectorAll(".page_novovalor");
    setLoader(true);

    const apiGestao = await fetch(
      user.system.url + "gestao/filtro/" + user.user.id,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...selectedfiltro,
          analizados: editados ? 1 : 0,
        }).replaceAll(" ", ""),
      }
    );

    const gestaoResultado = await apiGestao.json();

    let rows = gestaoResultado.data;
    let rowsNew = rowsAdapter(rows, atacadistas.includes(user?.dbschema));

    setRows(rowsNew);
    setSelectAll(false);
    setLoader(false);
  }

  const renderInputAtacado = (row, index) =>
    renderInputAtacadoModule(
      row,
      index,
      bloqueados,
      exportar,
      editados,
      filhos,
      bindDosValores,
      proxCampo,
      user
    );

  const renderInputAtacado3 = (row, index) =>
    renderInputAtacadoModule3(
      row,
      index,
      bloqueados,
      exportar,
      editados,
      filhos,
      bindDosValores3,
      proxCampo
    );

  const renderInputAtacado4 = (row, index) =>
    renderInputAtacadoModule4(
      row,
      index,
      bloqueados,
      exportar,
      editados,
      filhos,
      bindDosValores4,
      proxCampo
    );

  const renderInputAtacadoRedeLucas = (row, index) =>
    renderInputAtacadoRedeLucasModule(
      row,
      index,
      bloqueados,
      exportar,
      editados,
      filhos,
      bindDosValoresRedeLucas,
      proxCampo
    );

  const renderInput = (row, index) =>
    renderInputModule(
      row,
      index,
      bloqueados,
      exportar,
      editados,
      filhos,
      bindDosValores,
      proxCampo
    );

  const renderCheckbox = (row, index) =>
    renderCheckboxModule(
      row,
      index,
      bloqueados,
      filhos,
      exportar,
      editados,
      handleSelectField
    );

  const renderBloqueados = (row, index) =>
    renderBloqueadosModule(row, index, difTotal);

  const handleCheckboxSlider = (e, index) => {
    let rowsAtualizada = [...rows];
    let value = "0";
    if (rowsAtualizada[index].analizado !== "2") {
      value = "2";
    }
    rowsAtualizada[index].analizado = value;
    setRows(rowsAtualizada);

    const salvar = {
      cluster: rowsAtualizada[index].cluster_simulador,
      codigo_pai: rowsAtualizada[index].codigo_pai,
      analizado: rows[index].analizado,
      exportado: "0",
      preco_decisao:
        rowsAtualizada[index].preco_decisao ||
        rowsAtualizada[index].preco_min_max,
      uid: user.uid,
      db_schema: user.dbschema,
    };

    Promise.all([
      fetch(user.apiurl + "gestao/classificador/valores-min-max-sliders", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltro({
          ...selectedfiltro,
          analizados: editados ? 1 : 0,
        }),
      }),
      fetch(user.apiurl + "gestao/update", {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "POST",
        body: itensFiltroSemEspaco(salvar).replaceAll(" ", ""),
      }),
    ]).then(([apiMinMaxSlider]) => {
      const minMax = apiMinMaxSlider.json();

      if (minMax?.data && minMax?.data?.data_ultimo_custo_minimo) {
        let dataA = minMax.data.data_ultimo_custo_minimo.split("/");

        minMax.data.dataInicial = new Date(
          parseInt(dataA[2]),
          parseInt(dataA[1]) - 1,
          parseInt(dataA[0])
        );
      }

      if (minMax?.data && minMax?.data?.data_ultimo_custo_maximo) {
        let dataB = minMax.data.data_ultimo_custo_maximo.split("/");
        minMax.data.dataFinal = new Date(
          parseInt(dataB[2]),
          parseInt(dataB[1]) - 1,
          parseInt(dataB[0])
        );
      }
      setSlider(minMax?.data);
    });
  };

  const renderCheckboxSlider = (row, index) =>
    renderCheckboxSliderModule(
      row,
      index,
      rows,
      filhos,
      bloqueados,
      handleCheckboxSlider,
      difTotal
    );

  async function enviaTudoParaAPI(valor) {
    setLoader(true);
    const checkboxes = {
      grupo: selectedfiltro.grupo,
      fornecedor: selectedfiltro.fornecedor,
      produto: selectedfiltro.produto,
      bandeira: selectedfiltro.bandeira,
      sensibilidade: selectedfiltro.sensibilidade,
      tem_pesquisa: selectedfiltro.tem_pesquisa,
      papel_categoria: selectedfiltro.papel_categoria,
      sub_grupo: selectedfiltro.sub_grupo,
      cluster: selectedfiltro.cluster,
      departamento: selectedfiltro.departamento,
      secao: selectedfiltro.secao,
      analizado: valor ? "1" : "0",
      uid: user.uid,
      db_schema: user.dbschema,
    };

    await fetch(user.apiurl + "gestao/update/checkboxes", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(checkboxes),
    });
    setSelectAll(true);
    setLoader(false);
    const totalizadores = await apiHelper.totalizadores(
      selectedfiltro,
      user.apiurl,
      user.user.id
    );
    setTotalizadores(totalizadores.data[0]);
  }

  async function exportData() {
    const apiToken = localStorage.getItem("exportApiToken");
    try {
      if (apiToken) {
        await fetch(
          process.env.REACT_APP_API_EXPORT +
            "/PrecificacaoAPI/api/v1/Preco/inclusao-preco-simples",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: apiToken,
            },
            method: "POST",
            body: JSON.stringify([...apiExportData]),
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  const renderSelectAll = () =>
    renderSelectAllModule(
      exportar,
      editados,
      selectedfiltro,
      selectAll,
      handleSelectAll
    );

  const renderFilhos = () =>
    renderFilhosModule(
      exportar,
      user,
      dadosExportar,
      editados,
      difTotal,
      bloqueados,
      filtroBandeira,
      itensBloqueados,
      separator,
      lang,
      exportData
    );

  if (!produtos.data) {
    return <div className="loader"></div>;
  }

  const rendergrupoArredondamentoHeader = () =>
    rendergrupoArredondamentoHeaderModule(ordenar);

  const rendergrupoArredondamentoBody = (row) =>
    rendergrupoArredondamentoBodyModule(row);

  const renderTable = () =>
    renderTableModule(
      loader,
      rows,
      classes,
      difTotal,
      exportar,
      user,
      ordenar,
      rowsToRender,
      renderInputAtacado,
      renderInputAtacado3,
      renderInputAtacado4,
      renderInputAtacadoRedeLucas,
      handleConcorrente,
      renderConcorrente,
      sanitalize,
      quantidadeDeProdutosDatabelaDefault,
      selectedfiltro,
      renderSelectAll,
      rendergrupoArredondamentoHeader,
      rendergrupoArredondamentoBody,
      renderBackgroundColor,
      renderCheckbox,
      renderCheckboxSlider,
      renderBloqueados,
      renderInput,
      emptyRows,
      registros,
      rowsPerPage,
      page,
      handleChangePage,
      handleChangeRowsPerPage,
      totalDePaginas,
      TablePaginationActions,
      setPhotoCarousel,
      photoCarousel,
      lang
    );

  let rowsToRender = [...rows];

  if (exportar && rowsExportacao.length > 0) {
    rowsToRender = rowsExportacao;
  }

  const renderDropDown = () =>
    renderDropDownModule(
      languages,
      lang,
      showSlider,
      slider,
      handleFiltrosSlider,
      user,
      filtrarRangeData,
      setSliderDate,
      filtrarSlider,
      sliderValores,
      user
    );
  return (
    <div className="filtro">
      <div className="container-fluid">
        <div className="row" style={{ displey: "flex" }}>
          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <TbClipboardText
                    style={{ fontSize: "25px" }}
                  ></TbClipboardText>
                </div>
                <div className="col-10">
                  {languages[lang].alteracao_de_precos}
                </div>
              </div>
              <div className="row">
                <div className="col-8">Total alterações</div>
                <div className="col-4 azul_ text-right">
                  {sanitalize(
                    totalizadores.itens_preco_alterado
                  ).toLocaleString(3)}
                </div>
              </div>
              <div className="row">
                <div className="col-8" style={{ fontSize: "1.2rem" }}>
                  {languages[lang].total_exportacao}
                </div>

                <div className="col-4 verde_ text-right">
                  {sanitalize(totalizadores.itens_editados)}
                </div>
              </div>
            </div>
          </div>

          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <VscGraphLine style={{ fontSize: "25px" }}></VscGraphLine>
                </div>
                <div className="col-10">{languages[lang].margem_objetiva}</div>
              </div>
              <div className="row">
                <div className="col-12  text-center">
                  <p className="span_tot">
                    <span
                      className="total_num1 azul_"
                      style={{ fontSize: "28px" }}
                    >
                      {(
                        parseFloat(totalizadores.margem_objetiva_ponderada2) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <VscGraphLine style={{ fontSize: "25px" }}></VscGraphLine>
                </div>
                <div className="col-10" style={{ fontSize: "1.3rem" }}>
                  {languages[lang].margem_projetada_atual}
                </div>
              </div>
              <div className="row">
                <div className="col-12  text-center">
                  <span
                    className="total_num1 azul_"
                    style={{ fontSize: "28px" }}
                  >
                    {(
                      parseFloat(totalizadores.margem_atual_ponderada2) * 100
                    ).toFixed(2)}
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <VscGraphLine style={{ fontSize: "25px" }}></VscGraphLine>
                </div>
                <div className="col-10" style={{ fontSize: "1.3rem" }}>
                  {languages[lang].margem_projetada_sugestao}
                </div>
              </div>
              <div className="row">
                <div className="col-12  text-center">
                  <span
                    className="total_num1 azul_"
                    style={{ fontSize: "28px" }}
                  >
                    {(
                      parseFloat(totalizadores.margem_nova_ponderada2) * 100
                    ).toFixed(2)}
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <VscGraphLine style={{ fontSize: "25px" }}></VscGraphLine>
                </div>
                <div className="col-10" style={{ fontSize: "1.3rem" }}>
                  {languages[lang].margem_projetada_final}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <span
                    className="total_num1 azul_"
                    style={{ fontSize: "28px" }}
                  >
                    {(parseFloat(totalizadores.margem_real) * 100).toFixed(2)}%
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <AiOutlinePercentage
                    style={{ fontSize: "25px" }}
                  ></AiOutlinePercentage>
                </div>
                <div className="col-10">
                  {languages[lang].indice_preco_atual}
                </div>
              </div>
              <div className="row">
                <div className="col-8">Médio</div>
                <div className="col-4 azul_">
                  {mostraZeroDec(
                    roundDown(
                      totalizadores.indice_competitividade_atual_medio,
                      1
                    )
                  )}
                  %
                </div>
              </div>
              <div className="row">
                <div className="col-8">Ponderado</div>
                <div className="col-4 verde_">
                  {mostraZeroDec(
                    roundDown(
                      totalizadores.indice_competitividade_atual_ponderado,
                      1
                    )
                  )}
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <AiOutlinePercentage
                    style={{ fontSize: "25px" }}
                  ></AiOutlinePercentage>
                </div>
                <div className="col-10">
                  {languages[lang].indice_preco_sugerido}
                </div>
              </div>
              <div className="row">
                <div className="col-8">Médio</div>
                <div className="col-4 azul_">
                  {mostraZeroDec(
                    roundDown(
                      totalizadores.indice_competitividade_novo_medio,
                      1
                    )
                  )}
                  %
                </div>
              </div>
              <div className="row">
                <div className="col-8">Ponderado</div>
                <div className="col-4 verde_">
                  {mostraZeroDec(
                    roundDown(
                      totalizadores.indice_competitividade_novo_ponderado,
                      1
                    )
                  )}
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="col big_number">
            <div className="totalizadores box--com--sombra totais branco">
              <div className="row">
                <div className="col-2">
                  <AiOutlinePercentage
                    style={{ fontSize: "25px" }}
                  ></AiOutlinePercentage>
                </div>
                <div className="col-10">
                  {languages[lang].indice_preco_final}
                </div>
              </div>
              <div className="row">
                <div className="col-8">Médio</div>
                <div className="col-4 azul_">
                  {mostraZeroDec(
                    roundDown(totalizadores.indice_final_medio, 1)
                  )}
                  %
                </div>
              </div>
              <div className="row">
                <div className="col-8">Ponderado</div>
                <div className="col-4 verde_">
                  {mostraZeroDec(
                    roundDown(totalizadores.indice_ponderado_sugestao, 1)
                  )}
                  %
                </div>
              </div>
            </div>
          </div>

          <div className="col big_number">
            <div
              className={
                difMrgReg(totalizadores.diferenca_total) +
                " item totalizadores box--com--sombra totais" +
                "butt"
              }
            >
              <div className="row">
                <div className="col-2">
                  <MdAttachMoney
                    style={{ fontSize: "25px", color: "#fff" }}
                  ></MdAttachMoney>
                </div>
                <div className="col-10 branco_">
                  {languages[lang].dif_margem_regular}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="span_tot">
                    <span className="total_num branco_">
                      {roundDown(
                        totalizadores.diferenca_total,
                        2
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid filter-line">
        <div className="row align-items-center">
          <div className="col-6 col-lg-1">
            {/* tela grande = 1 linha com 12 col
            tela media = 2 linhas com 1 linha com 3 col e outra com 4 col 
            tela pequena = 3 com 1 com 2, 1 com 1 e 1 com 4 */}

            <div
              className="filtro_titulo filtro-blue"
              style={{ display: "inline-flex", cursor: "pointer" }}
              onClick={() => {
                const section = document.getElementById("filter-selection");
                if (section.style.display == "none") {
                  section.style.display = "block";
                } else {
                  section.style.display = "none";
                }
              }}
            >
              <HiOutlineFilter style={{ marginTop: "5px" }}></HiOutlineFilter>
              &nbsp;Filtros
            </div>
          </div>
          <div className="col-6 col-lg-2 col-xl text-nowrap">
            <div
              className="filtro_titulo filtro-blue-block"
              style={{ cursor: "pointer" }}
              onClick={renderSlider}
            >
              {/* <img
                className="filtro_tit_img"
                alt="some value"
                src="/image/filtro.png"
              ></img> */}
              {/*<div className="dropdown" >
                 <p
                  
                  aria-haspopup="true"
                  aria-expanded="false"
                > */}
              <BsSliders></BsSliders>&nbsp;{languages[lang].filtro_dinamico}
              {/* <span className="glyphicon glyphicon-menu-down filtro_seta"></span> */}
              {/* </p> 
                
              </div>*/}
            </div>
            {renderDropDown()}
          </div>
          <div className="col-12 col-lg-9 col-xl-2 mt-2 mt-lg-0 center">
            <div className="form-group mb-0">
              <input
                className="search"
                id="search-formulario"
                onKeyDown={filtroBusca}
                placeholder="Buscar item"
              />
              {renderX()}
            </div>
          </div>

          {[
            "vivenci",
            "redetop",
            "grupo_nos",
            "lopes",
            "camilo",
            "camilo_campeao",
            "atack",
          ].includes(user.dbschema) ? (
            <></>
          ) : (
            <div className="col mt-2 mt-xl-0 text-nowrap">
              <button
                type="button"
                className="btn btn-secondary btn_actions"
                style={{
                  marginBottom: "0px",
                }}
                data-toggle="modal"
                data-target="#modalLimpar"
              >
                {languages[lang].limpar_alteracoes}
              </button>
            </div>
          )}
          <div className="col mt-2 mt-xl-0 text-nowrap">
            <button
              type="button"
              className="btn btn-secondary btn_actions"
              style={{ marginBottom: "0px" }}
              onClick={() => resetarFiltros(false)}
            >
              {languages[lang].limpar_filtros}
            </button>
          </div>
          <div className="col mt-2 mt-xl-0 text-nowrap">
            <button
              type="button"
              className="btn btn-secondary btn_actions"
              style={{ marginBottom: "0px" }}
              data-toggle="modal"
              data-target="#modalDefault"
              onClick={() => setItensValidados(false)}
            >
              {languages[lang].tabela_padrao}
            </button>
          </div>
          {/* <div className="col mt-2 mt-xl-0 text-nowrap">
            <button
              type="button"
              className="btn btn-secondary btn_actions"
              style={{ marginBottom: "0px" }}
              data-toggle="modal"
              data-target="#order-by"
            >
              Ordenar Por
            </button>
          </div> */}
          {editados ? (
            ""
          ) : (
            <div className="col mt-2 mt-xl-0 text-nowrap">
              <button
                type="button"
                className="btn btn-secondary btn_actions"
                style={{ marginBottom: "0px" }}
                data-toggle="modal"
                data-target="#transfer"
              >
                {languages[lang].ordenar_tabela}
              </button>
            </div>
          )}
          <div className="col mt-2 mt-xl-0 text-nowrap">
            <button
              type="button"
              className="btn btn-secondary btn_actions"
              style={{ marginBottom: "0px" }}
              onClick={() => itensEditados()}
            >
              {languages[lang].itens_validados}
            </button>
          </div>
          {Boolean(user.user.permission) ? renderExportButton() : ""}
        </div>
      </div>

      <section id="filter-selection" style={{ display: "none" }}>
        <div className="container-flex">
          <div className="item">
            {renderFilters("bandeira", languages[lang].bandeira)}
            {renderFilters("cluster", languages[lang].cluster)}
          </div>
          <div className="item">
            {renderFilters("departamento", languages[lang].nivel1)}
            {renderFilters("secao", languages[lang].nivel2)}
          </div>
          <div className="item">
            {renderFilters("grupo", languages[lang].nivel3)}
            {renderFilters("sub_grupo", languages[lang].nivel4)}
          </div>
          {[
            "vivenci",
            "lopes",
            "camilo",
            "camilo_campeao",
            "superlagoa",
            "atack",
            "floresta",
          ].includes(user.dbschema)
            ? renderFilters("nivel_5", languages[lang].nivel5)
            : renderFilters("sub_grupo", languages[lang].nivel5)}
          {user.dbschema != "stmarche" && user.dbschema != "mundopet" && (
            <div className="item">
              {renderFilters("fornecedor", languages[lang].fornecedor)}
              {renderFilters(
                "papel_categoria",
                languages[lang].papel_categoria
              )}
            </div>
          )}
          {user.dbschema === "stmarche" && (
            <div className="item">
              {renderFilters("fornecedor", "Característica")}

              {renderFilters(
                "papel_categoria",
                languages[lang].papel_categoria
              )}
            </div>
          )}
          {user.dbschema === "mundopet" && (
            <div className="item">
              {renderFilters("fornecedor", "Fabricante")}
              {renderFilters(
                "papel_categoria",
                languages[lang].papel_categoria
              )}
            </div>
          )}
          <div className="item">
            {renderFilters("sensibilidade", languages[lang].sensibilidade)}
            {["grupo_nos"].includes(user.dbschema)
              ? renderFilters("escala", "Tem Pesquisas")
              : renderFilters("escala", "Escala")}

            {user.dbschema === "oba" &&
              renderFilters("tem_pesquisa", "Tem Pesquisa")}
            {user.dbschema != "oba" && (
              <button
                type="button"
                className=" btn btn-dark"
                onClick={editados ? itensEditados : filtroBandeira}
              >
                {languages[lang].filtrar_tabela}
              </button>
            )}
          </div>

          {user.dbschema === "oba" && (
            <div className="item">
              <button
                type="button"
                className=" btn btn-dark"
                onClick={editados ? itensEditados : filtroBandeira}
              >
                {languages[lang].filtrar_tabela}
              </button>
            </div>
          )}
        </div>
      </section>

      <div className="container-fluid">
        <div className="row">{renderFilhos()}</div>
      </div>
      {renderTable()}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-12 right">
            <p
              className="data_at"
              style={{ marginTop: "10px", color: "#0a5da6", fontSize: "12px" }}
            >
              <span className="glyphicon glyphicon-calendar"></span>&nbsp;Data
              de Atualização:&nbsp;{renderDataAtualizacao()}
            </p>
          </div>
        </div>
      </div>
      <Modal
        filtroBandeira={filtroBandeira}
        resetarTabela={resetarTabela}
        limparCamposQueNaoTiveremSelecionados={
          limparCamposQueNaoTiveremSelecionados
        }
        apiLimpezaBloqueados={apiLimpezaBloqueados}
        exportarDados={exportarDados}
        exportarDadosApiAsun={exportarDadosApiAsun}
        exportarDadosApiSemar={exportarDadosApiSemar}
        user={user}
        photoCarousel={photoCarousel && photoCarousel}
      />
    </div>
  );
}

export default TablePrice;
