import React, { useContext, useEffect } from "react";
import GlobalState from "../contexts/GlobalState";
// import { Auth } from "aws-amplify";
import exportFromJSON from "export-from-json";
import Modal from "./Modal";
import { useAuth } from "../contexts/AuthContext";
import languages from '../languages.json'

var schema = "";

async function startDownload(db_x) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ db_schema: schema, limit: "" }),
  };

  if (db_x === "swift") {
    const response = await fetch(
      `${process.env.REACT_APP_API_SWIFT}/api/v2/reports/tabela-gestao-xls`,
      requestOptions
    );
    //response = await fetch('https://bigboxapi.prixsia.com/api/v2/reports/tabela-gestao-xls', requestOptions)
    relatorio = await response.json();
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_API}/api/v2/reports/tabela-gestao-xls`,
      requestOptions
    );
    //var response = await fetch('https://apipp.prixsia.com/api/v2/reports/tabela-gestao-xls', requestOptions)
    var relatorio = await response.json();
  }

  if (relatorio.status != "error") {
    function separator() {
      return ";";
    }

    function adicionaZero(numero) {
      if (numero <= 9) return "0" + numero;
      else return numero;
    }

    var newDate = new Date();
    var nomeArquivo =
      newDate.getFullYear() +
      "" +
      adicionaZero(newDate.getMonth() + 1) +
      "" +
      adicionaZero(newDate.getDate()) +
      "" +
      newDate.getHours() +
      "" +
      newDate.getMinutes() +
      " - Tabela Gestão Preços";

    const data = relatorio.data;
    const fileName = nomeArquivo;
    const exportType = exportFromJSON.types.csv;
    const delimiter = separator();

    exportFromJSON({ data, fileName, exportType, delimiter: ";" });
  }
}

async function linkToDashboard(email) {
  const requestClick = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  };

  const response = await (
    await fetch(`${process.env.REACT_APP_API}/api/v2/qlik-url`, requestClick)
  ).json();
  if (response) {
    return window.open(response.url);
  } else {
    alert(`HTTP-Error: ${response.status} - ${response.statusText}`);
  }
}

function Navbar() {
  const language = window.sessionStorage.getItem('language')
  const { signOut: logout } = useAuth();
  const state = useContext(GlobalState)[0];
  const [lang, setLang] = React.useState(language || 'pt')

  async function signOut() {
    try {
      logout();
      return;
    } catch (error) {
    }
    window.location.reload();
  }

  if (state.value) {
    schema = state.value.system.schema;
  }

  return (
    <div className="wrap--navbar">
      <div id="mySidenav" className={"sidenav"}>
        <div id="main">
          <p>
            <img
              className="menu_icons"
              src="/image/home.png"
              alt="some value"
            ></img>
            <a className="redirect" href="/agendamentos">
              { languages[lang].home }
            </a>
          </p>
          <p>
            <span className="glyphicon glyphicon-tasks menu_icons menu_size"></span>
            <a className="redirect" href="/parametros">
            { languages[lang].parametros }
            </a>
          </p>
          <p>
            <span className="glyphicon glyphicon-shopping-cart menu_icons menu_size"></span>
            <a className="redirect" href="/tabela">
            { languages[lang].gestao_de_precos }
            </a>
          </p>
          <p>
            <span className="glyphicon glyphicon-stats menu_icons menu_size"></span>
            <a
              className="redirect"
              href="#"
              onClick={() => linkToDashboard(state.value.user.email)}
            >
              { languages[lang].analytics }
            </a>
          </p>
          <p>
            <span className="bi bi-file-earmark-arrow-down menu_icons menu_size"></span>
            <a
              className="redirect"
              href="#"
              onClick={() => startDownload(schema)}
            >
              { languages[lang].donwload_tabela_de_precos }
            </a>
          </p>
          <div className="menu_sidebar2">
            <p>
              <img
                className="menu_icons conf"
                src="/image/configuracoes.png"
                alt="some value"
              ></img>
              <a className="redirect" href="/account">
              Perfil
              </a>
            </p>
            {/* <p><img className='menu_icons2' src='/image/ajuda.png' alt='some value'></img>
                                Ajuda
                            </p> */}
            <p onClick={signOut}>
              <img
                className="menu_icons"
                src="/image/sair.png"
                alt="some value"
              ></img>
              Sair
            </p>
          </div>
        </div>
      </div>
      <Modal onClick={startDownload} />
    </div>
  );
}

export default Navbar;
