import React, { useState } from "react";
import "react-tabulator/lib/styles.css";

function RenderDropdownBigNumbers(props) {
  const [showTotalizadores, setShowTotalizadores] = useState(false);

  function difMrgReg(dif) {
    var cor = "";
    if (dif >= 0) {
      cor = "verde";
    }
    if (dif <= 0) {
      cor = "tomato";
    }
    return cor;
  }

  function renderTotalizadores() {
    if (!showTotalizadores) {
      return;
    }
    return (
      <div className="row">
        <div className="col big_numbers">
          <div className="totalizadores_sf box--com--sombra totais azul_neutro sf">
            <div className="indice_box">
              {/* <p className='totalizadores_nome'></p> */}
              <p className="totalizadores_nome exp">
                Itens para Exportação
                <span
                  className="totalizadores_resultados sugestao click"
                  onClick={props.salvar}
                >
                  {props.totalizadores.itens_para_exportacao}
                </span>
              </p>
              <p className="totalizadores_nome item_alterado ">
                Itens com Alteração de Preço
                <span className="totalizadores_resultados sugestao">
                  {props.totalizadores.itens_com_alteracao_de_preco}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col big_numbers">
          <div className="totalizadores_sf box--com--sombra totais laranja">
            <p className="totalizadores_margem">Mrg Proj Preço Atual </p>
            <p className="span_tot">
              <span className="totalizadores_resultados sugestao">
                {(
                  parseFloat(props.totalizadores.margem_objetiva) * 100
                ).toFixed(2)}
                %
              </span>
            </p>
          </div>
        </div>
        <div className="col big_numbers">
          <div className="totalizadores_sf box--com--sombra totais laranja">
            <p className="totalizadores_margem">Mrg Proj. Estoque 10 dias </p>
            <p className="span_tot">
              <span className="totalizadores_resultados sugestao">
                {(parseFloat(props.totalizadores.margem_atual) * 100).toFixed(
                  2
                )}
                %
              </span>
            </p>
          </div>
        </div>
        <div className="col big_numbers">
          <div className="totalizadores_sf box--com--sombra totais laranja">
            <p className="totalizadores_margem">Mrg Proj. Estoque 20 dias</p>
            <p className="span_tot">
              <span className="totalizadores_resultados sugestao">
                {(
                  parseFloat(props.totalizadores.margem_sugestao) * 100
                ).toFixed(2)}
                %
              </span>
            </p>
          </div>
        </div>
        <div className="col big_numbers">
          <div className="totalizadores_sf box--com--sombra totais laranja">
            <p className="totalizadores_margem">Mrg Proj. Estoque 30 dias </p>
            <p className="span_tot">
              <span className="totalizadores_resultados sugestao">
                {(parseFloat(props.totalizadores.margem_final) * 100).toFixed(
                  2
                )}
                %
              </span>
            </p>
          </div>
        </div>
        <div className="col big_numbers sf">
          <div
            className={
              difMrgReg(parseFloat(props.totalizadores.diferenca_total)) +
              " totalizadores_sf box--com--sombra totais"
            }
          >
            <p className="totalizadores_margem">Dif Margem Regular R$</p>
            <p className="span_tot">
              <span className="totalizadores_resultados sugestao">
                {parseFloat(props.totalizadores.diferenca_total).toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!props.totalizadores.indice_competitividade_atual_medio) {
    return <></>;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col big_numbers">
            <div className="totalizadores_sf box--com--sombra totais verde">
              <p className="totalizadores_nome">Índice Preço Atual</p>
              <div className="big_numbers_box">
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[1].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_atual_medio[1]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[2].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_atual_medio[2]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[0].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_atual_medio[0]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col big_numbers">
            <div className="totalizadores_sf box--com--sombra totais verde">
              <p className="totalizadores_nome">Índice Preço Sugerido</p>
              <div className="big_numbers_box">
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[1].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_novo_medio[1]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[2].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_novo_medio[2]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[0].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_novo_medio[0]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col big_numbers">
            <div className="totalizadores_sf box--com--sombra totais verde">
              <p className="totalizadores_nome">Índice Preço Final</p>
              <div className="big_numbers_box">
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[1].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_final_medio[1]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[2].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_final_medio[2]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <p className="total_box tipos">
                  {props.totalizadores.indice_competitividade_atual_medio[0].format}
                  <span className="totalizadores_resultados num">
                    {parseFloat(
                      props.totalizadores.indice_competitividade_final_medio[0]
                        .value * 100
                    ).toFixed(1)}
                    %
                  </span>
                </p>
                <span
                  className="glyphicon glyphicon-menu-down seta_tot"
                  onClick={() => setShowTotalizadores(!showTotalizadores)}
                ></span>
              </div>
            </div>
          </div>
        </div>
        {renderTotalizadores()}
        <div className="container-fluid">
          <div className="row">{props.renderCSV()}</div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col pais_filhos_swift">
            <input
              className="form-check-input"
              defaultChecked={true}
              type="radio"
              name="flag_pai_ou_filho"
              onClick={props.handleFilters}
              value="PAI"
              id="pai"
            />
            <label className="filhos" htmlFor="pai">
              Itens Pais
            </label>
          </div>
          <div className="col pais_filhos_swift right">
            <input
              className="form-check-input"
              type="radio"
              name="flag_pai_ou_filho"
              onClick={props.filtroBandeira}
              value="FTP"
              id="ftp"
            />
            <label className="filhos" htmlFor="ftp">
              Itens Filhos Proporcionais
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default RenderDropdownBigNumbers;
