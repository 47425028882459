import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import Layout from "./AuthLayout";
import { Redirect } from "react-router-dom";
const Login = () => {
  const [notifyIsOpen, setNotifyIsOpen] = useState(false);
  const { signIn } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    if (password !== "" && username !== "") {
      const res = await signIn({ email: username, password: password });

      setNotifyIsOpen(!res.success);
      if (res.resetPassword) {
        return (window.location = "/reset-password");
      }
    } else {
      return alert("Preencha todos os campos!");
    }
  };
  return (
    <Layout>
      <div className="login__box" id="login">
        {notifyIsOpen && (
          <div className="notify">
            Op´s não foi possível fazer login, verifique seu usuário!
            <button
              className="close_notify"
              onClick={() => setNotifyIsOpen(false)}
            >
              X
            </button>
          </div>
        )}
        <form className="login__form">
          <h2>Acessar sua conta</h2>
          <label htmlFor="usuario">
            <span>Usuário</span>
            <input
              className="login__input"
              id="usuario"
              name="usuario"
              type="text"
              placeholder="Insira seu Usuário"
              value={username}
              onChange={({ currentTarget }) =>
                setUsername(String(currentTarget.value))
              }
              required
            />
          </label>

          <label htmlFor="senha">
            <span>Senha</span>
            <input
              className="login__input"
              id="senha"
              name="senha"
              type="password"
              value={password}
              placeholder="Digite sua Senha"
              onChange={({ currentTarget }) =>
                setPassword(String(currentTarget.value))
              }
              required
            />
          </label>
          <button onClick={(e) => handleLogin(e)}>Acessar</button>
        </form>
        <div className="login__form" style={{ textAlign: "center" }}>
          <p
            onClick={() => {
              return (window.location = "/reset-password");
            }}
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            Esqueci Minha Senha
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
