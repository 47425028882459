import moment from "moment";

export function renderDataAtualizacaoModule(rows) {
  if (!rows[0]) {
    return;
  }

  const formattedDate = moment(rows[0].dt_insert).format(
    "DD/MM/YYYY, HH:mm:ss"
  );
  return formattedDate;
}
