import React, { useState, useEffect } from 'react';
import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import * as apiHelper from '../Helpers/ApiSwift';
import * as adapter from '../Helpers/adapter';
import FiltroDropdown from './FiltroDropdown';
import FiltroDropdownBigNumbers from './FiltroDropdownBigNumbers';
import Modal from './Modal';
import Toastify from 'toastify-js';
import CsvDownloader from 'react-csv-downloader';


function Swift () {
  
  const [tb, setTb] = useState ([]);
  const [selectedfiltro, setSelectedfiltro] = useState (apiHelper.getFiltrosDefault());
  const [rows, setRows] = useState ([]);
  const [totalizadores, setTotalizadores] = useState ([]);
  const [loader, setLoader] = useState (false);
  const [search, setSearch] = useState ('');
  const [editados, setEditados] = useState (false);
  const [validados, setValidados] = useState (true);
  const [exportados, setExportados] = useState (false);
  const [tabelaDownload, setTabelaDownload] = useState ([]);
  const [selectAll, setSelectAll] = useState (false);
  const [chaveDaTabela, setChaveDaTabela] = useState (0);


  useEffect(() => {

    async function fetchMyAPI() {

      setarTabela ()
      
      const totalizadoresApi = await apiHelper.totalizadores(selectedfiltro);
      setTotalizadores(totalizadoresApi.data)
      
      const filtrosApi = await apiHelper.filtroDependentes(selectedfiltro);
      setTb(filtrosApi.data)
      setValidados (false);
    }
      fetchMyAPI()
    
  }, [])

  async function setarTabela () {
    try {
      const tabelaApi = await apiHelper.tabela(apiHelper.getFiltrosDefault());
      var tabela = [];
      var adapted = {};
      tabelaApi.data.map((row, index) => {
        adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
        tabela.push (adapted)
      })
      setRows(tabela)        
    } catch (error) {
    }
  }

  async function setarTabelaFiltrada () {
    setLoader(true)
    try {
      const tabelaApi = await apiHelper.tabela(selectedfiltro);
      var tabela = [];
      var adapted = {};
      tabelaApi.data.map((row, index) => {
        adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
        tabela.push (adapted)
      })
      setRows(tabela)        
    } catch (error) {
    }
    setLoader(false)
  }
  
  async function salvar(cell, valor) {
    const data = cell._cell.row.data;
    var adapted = {...data, checkbox: valor}
    var id = data.id;
    cell._cell.row.table.updateRow(id, adapted)
    const el = document.querySelector('.tabulator-tableHolder');
    const scrollTopo = el.scrollTop - 5;
    await apiHelper.getUpdateToggle(data, valor) 
    await atualizaTotalizadores()
    el.scrollTop = scrollTopo;    
  }

  var  checkboxFormatter = function (cell, formatterParams) {
    const id = cell._cell.row.data.id;

    if(cell._cell.row.data.flag_pai_ou_filho==="FILHO") {
      return;
    }
    if(cell._cell.row.data.checkbox) {
      return `<input type="checkbox" data-id="${id}" checked>`;
    }
    return `<input type="checkbox"  data-id="${id}">`;
  }
    
  var fatorFormatter = function (cell, formatterParams) {
    if(cell.getValue()) {
      return `<div class="fator">${cell.getValue()}</div>`;
    }
  }

  var precosPorcent = function (cell, formatterParams) {
    if(cell.getValue()==null) {
      return '<span></span>';
    }
      return `<span>${cell.getValue()}%</span>`;
  }

  var precosFormatter = function (cell, formatterParams) {
    if(cell.getValue()=="0.0%") {
      return '<span></span>';
    }
      return `<span>${cell.getValue()}</span>`;
  }

  var cores = function (cell, formatterParams) {
    if(!cell.getValue()) {
      return '<span></span>';
    }
    var cor = '';
    var sensib = cell.getValue()
    switch (sensib) {
      case 'SENSÍVEL':
        cor = 'amarelo';
      break;
      case 'SUPER SENSÍVEL':
        cor = 'vermelho';
        break;      
      case 'PRIMEIRO PRECO':
        cor = 'cinza';
        break;
      case 'TOP NÃO SENSÍVEL' :
        cor = 'azul_claro';
        break;        
      default:
        break;
      } 
    return `<div class="${cor} color-tabulator">${sensib}</div>`;
  }
    
  function sanitalize (valor){
    if(!valor) return;
    return  parseFloat( valor.replace(',', '.') )  
  }
  
  var fotoDoProduto = function(cell, formatterParams){ 
    const valor = cell.getValue();
    if(!valor) {
      return '';
    }
    return `<a href="${valor}" target=”_blank”><span class="glyphicon glyphicon-camera fotos"></span></a>`    
  }

  var printIcon = function(cell, formatterParams){ 
  if(!cell.getValue()) {
    return '<span></span>';
  }
  var marcacao = "icones";
  if(cell._cell.row.data.flag_pai_ou_filho === "FILHO") {
    marcacao = ""
  }
  const valor = cell.getValue()
  const preco_atual_varejo = sanitalize(cell._cell.row.data.preco_atual_varejo);
  const preco_min_max = sanitalize(cell._cell.row.data.preco_min_max);
  if (valor > preco_atual_varejo){
    return `<div class="${marcacao}" data-price-position="${cell._cell.row.data.id}"><span class="glyphicon glyphicon-arrow-up seta_valor verde_"></span>${cell.getValue()}</div>`;
  } 
  if (valor < preco_atual_varejo){
    return `<div class="${marcacao}" data-price-position="${cell._cell.row.data.id}"><span class="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>${cell.getValue()}</div>`;
  }
  if (valor == preco_atual_varejo){
    return `<div class="${marcacao}" data-price-position="${cell._cell.row.data.id}"><span class="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>${cell.getValue()}</div>`;
  }
  if (preco_atual_varejo > preco_min_max){
    return `<div class="${marcacao}" data-price-position="${cell._cell.row.data.id}"><span class="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>${cell.getValue()}</div>`;
  }
  if (preco_atual_varejo < preco_min_max){
    return `<div class="${marcacao}" data-price-position="${cell._cell.row.data.id}"><span class="glyphicon glyphicon-arrow-up seta_valor verde_"></span>${cell.getValue()}</div>`;
  }
  if (preco_atual_varejo === preco_min_max){
    return `<div class="${marcacao}" data-price-position="${cell._cell.row.data.id}"><span class="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>${cell.getValue()}</div>`;
  }
};

  
  var editorNovoPreco = function(cell, onRendered, success, cancel){

    //create and style input
    var cellValue = cell.getValue();
    var input = document.createElement("input");

    input.setAttribute("type", "number");
    input.setAttribute("maxlength", "6");

    input.style.padding = "4px";
    input.style.width = "100%";
    input.style.boxSizing = "border-box";

    
    input.placeholder = cell._cell.row.data.preco_default;
    input.value = cellValue;

    onRendered(function(){
      input.focus();
      input.style.height = "100%";
    });    
  
    function onChange(){
      if(input.value != cellValue){
        const retorno = parseFloat(input.value);
        success(retorno.toLocaleString(undefined, {minimumFractionDigits: 2}));
      }else{
        cancel();
      }
    }

    //submit new value on blur or change
    input.addEventListener("change", onChange);
    input.addEventListener("blur", onChange);

    //submit new value on enter
    input.addEventListener("keydown", async function(e){

      if(e.keyCode == 13) {
        var valor = input.value;
        e.preventDefault();

        if(input.value === ""){
          input.value = cell._cell.row.data.preco_default;
        }
        if(input.value != cellValue) {  
          try {
            const el = document.querySelector('.tabulator-tableHolder');
            const scrollTopo = el.scrollTop - 5;
            success(input.value);
            var id = cell._cell.row.data.id;
            var newRow = await apiHelper.updatenovoPreco(input.value, cell._cell.row.data) 
            const totalizadoresApi = await apiHelper.totalizadores(selectedfiltro);
            setTotalizadores(totalizadoresApi.data)
            var adapted = adapter.adapter(newRow.data.total, id, newRow.data.formatos, newRow.data.concorrentes);
            
            adapted.checkbox = 1;
            
            if (input.value === cell._cell.row.data.preco_default){
              adapted.checkbox = 0;
            }
            const aberto = cell._cell.row.modules;
            cell._cell.row.table.updateRow(id, adapted)
            cell._cell.row.modules = aberto;
            el.scrollTop = scrollTopo;  

            
            //quando esta no fim da tabela, precisa esperar o component terminar de renderizar a tabela antes de
            //marcar o checkbox e o clicar no proximo input
            setTimeout(function () {
              
              if(valor === ""){
                document.querySelector(`input[data-id="${id}"]`).checked = false
              } else {
                document.querySelector(`input[data-id="${id}"]`).checked = true
              }

              if(document.querySelector(`[data-price-position="${id+1}"]`)) {
                document.querySelector(`[data-price-position="${id+1}"]`).parentNode.parentNode.querySelector('div[tabulator-field="preco_min_max"]').click(); 
              }
            }, 50);

          } catch (error) {
          }

          
        }else{  
          cancel();
      }
    }

    if(e.keyCode == 27){
      cancel();
    }
    });
    return input;
  };

  var editorFator = function(cell, onRendered, success, cancel){

    var cellValue = cell.getValue();
    var input = document.createElement("input");

    input.setAttribute("type", "number");
    input.setAttribute("maxlength", "6");

    input.style.padding = "4px";
    input.style.width = "100%";
    input.style.boxSizing = "border-box";

    input.value = cellValue;

    onRendered(function(){
        input.focus();
        input.style.height = "100%";
    });

    async function onChange(){

    }

    //submit new value on blur or change
    input.addEventListener("change", onChange);
    input.addEventListener("blur", onChange);

    //submit new value on enter
    input.addEventListener("keydown", async function(e){
        if(e.keyCode == 13){
          document.activeElement.parentNode.parentNode.nextSibling.querySelector('div[tabulator-field="fator_atacado_simulador"]').click();
          e.preventDefault();
          if(input.value != cellValue && (input.value > -1 && input.value < 2.6)) {
            success(input.value);
            var id = cell._cell.row.data.id;
            var newRow = await apiHelper.updateVendaEstimada(input.value, cell._cell.row.data) 
            var adapted = adapter.adapter(newRow.data.total, id);
            cell._cell.row.table.updateRow(id, adapted)
          }else{  
              cancel();
          }
        }

        if(e.keyCode == 27){
            cancel();
        }
      });
      
      return input;
    };
    
  async function atualizaTotalizadores(){
    
      const totalizadoresApi = await apiHelper.totalizadores(selectedfiltro);

      setTotalizadores({...totalizadoresApi.data})
  }

    const columns = [
      {
        formatter: checkboxFormatter,
        titleFormatter: "rowSelection",
        hozAlign: "center", frozen:true,
        headerSort: false,
        cellClick: function(e, cell) {
          var valor = 0;
          if(e.target.checked){
            valor= 1;
          }
          salvar(cell, valor)
        }
      },
      { title: "<br><br>Cluster", field: "cluster_simulador", hozAlign:"center", width: 100, frozen:true},
      { title: "<br>Código <br> Principal <br>Proporcional", field: "cod_pai_proporcao", hozAlign:"center", width: 100, frozen:true },
      { title: "<br><br>Código SKU", field: "codigo_filhos", hozAlign:"center", width: 80, frozen:true },
      { title: "<br><br>Descrição Produto", field: "descricao_produto", hozAlign:"center", width: 300, frozen:true },
      { title: "<br>Dias <br> Estoque", field: "dias_de_estoque", hozAlign:"center", width: 70 },
      { title: "<br>Preço <br>Regular<br> Atual", field: "preco_atual_varejo", hozAlign:"center", width: 90 },
      { title: "Novo <br>Preço<br> Regular<br> Varejo", field: "preco_min_max", hozAlign:"center", width: 90,  editor: editorNovoPreco, formatter:printIcon},
      { title: "<br>Margem <br>Atual", field: "margem_atual_regular", hozAlign:"center", width: 70, formatter: precosPorcent  },
      { title: "<br>Margem<br> Nova", field: "margem_nova", hozAlign:"center", width: 70, formatter: precosPorcent  },
      { title: "<br>Margem<br> Custo<br> Futuro", field: "margem_nova_30d", hozAlign:"center", width: 70, formatter: precosPorcent  },
      { title: "<br>Variação<br> Novo<br> Preço", field: "var_novo_preco", hozAlign:"center", width: 90, formatter: precosPorcent },
      { title: "<br>Nome<br> Concorrente", field: "nome_do_concorrente", hozAlign:"center", width: 150 },
      { title: "<br><br>Formato", field: "formato", hozAlign:"center", width: 120 },
      { title: "<br>Índice<br> Regular<br> Novo", field: "indice_sugerido_formato", hozAlign:"center", width: 100, formatter: precosPorcent },
      
      { title: "<br><br>S1", field: "preco_semana1", hozAlign:"center", width: 70, formatter: precosFormatter },
      { title: "<br><br>S2", field: "preco_semana2", hozAlign:"center", width: 70, formatter: precosFormatter },
      { title: "<br><br>S3", field: "preco_semana3", hozAlign:"center", width: 70, formatter: precosFormatter },
      { title: "<br><br>S4", field: "preco_semana4", hozAlign:"center", width: 70, formatter: precosFormatter },

      { title: "<br>Foto <br>Fora do <br>Range S1", field: "foto_range_semana1", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Fora do <br>Range S2", field: "foto_range_semana2", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Fora do <br>Range S3", field: "foto_range_semana3", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Fora do <br>Range S4", field: "foto_range_semana4", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      
      { title: "<br>Foto<br> Promoção S1", field: "foto_promo_semana1", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto<br> Promoção S2", field: "foto_promo_semana2", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto<br> Promoção S3", field: "foto_promo_semana3", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto<br> Promoção S4", field: "foto_promo_semana4", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      
      { title: "<br>Foto <br>Validade S1", field: "foto_validade_semana1", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Validade S2", field: "foto_validade_semana2", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Validade S3", field: "foto_validade_semana3", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Validade S4", field: "foto_validade_semana4", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      
      { title: "<br>Foto <br>Ruptura S1", field: "foto_ruptura_semana1", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Ruptura S2", field: "foto_ruptura_semana2", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      { title: "<br>Foto <br>Ruptura S3", field: "foto_ruptura_semana3", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      {title: "<br>Foto <br>Ruptura S4", field: "foto_ruptura_semana4", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      
      { title: "<br>Foto <br>Produto S1", field: "foto_do_produto_semana1", hozAlign:"center", width: 100, formatter: fotoDoProduto },    
      { title: "<br>Foto <br>Produto S2", field: "foto_do_produto_semana2", hozAlign:"center", width: 100, formatter: fotoDoProduto },    
      { title: "<br>Foto <br>Produto S3", field: "foto_do_produto_semana3", hozAlign:"center", width: 100, formatter: fotoDoProduto },    
      { title: "<br>Foto <br>Produto S4", field: "foto_do_produto_semana4", hozAlign:"center", width: 100, formatter: fotoDoProduto },
      
      { title: "<br><br>Sensibilidade", field: "sensibilidade_simulador", hozAlign:"center", width: 110, formatter:cores },
      { title: "<br><br>Categoria", field: "nome_secao", hozAlign:"center", width: 100 },
      { title: "<br><br>Ocasião", field: "nome_departamento", hozAlign:"center", width: 100 },
      { title: "<br><br>Formatos", field: "nome_grupo", hozAlign:"center", width: 100 },
      { title: "<br><br>Linha", field: "nome_subgrupo", hozAlign:"center", width: 120 },
      { title: "<br>Papel <br>Categoria", field: "papel_categoria_simulador", hozAlign:"center", width: 100 },
      { title: "Quantidade<br> de Vendas<br> Regular<br> Atual", field: "vendas", hozAlign:"center", width: 120 },
      { title: "<br><br>Fator", field: "fator_atacado_simulador", hozAlign:"center", width: 90, editor: editorFator, formatter: fatorFormatter },
      { title: "Quantidade<br> de Vendas<br> Regular <br>Estimada", field: "qtde_vendas_estimada", hozAlign:"center", width: 120 },
      { title: "<br>Diferença<br> Unitário R$", field: "diferenca_preco_unitario", hozAlign:"center", width: 100 },
      { title: "<br>Diferença <br>Total R$", field: "diferenca_preco_total", hozAlign:"center", width: 120 },
      { title: "<br>Preço <br>Inicial", field: "preco_atual_varejo", hozAlign:"center", width: 100 },
      { title: "<br>Política<br> Pricing/ Mix", field: "politica_pricing", hozAlign:"center", width: 100 },
      { title: "<br>Margem<br> Mínima<br> Máxima", field: "margem_min_max", hozAlign:"center", width: 100 },
      { title: "<br>Preço <br>Escala", field: "sugestao_escala", hozAlign:"center", width: 100 },
      { title: "<br><br>Arredondamento", field: "sugestao_arredondamento", hozAlign:"center", width: 110 },
      { title: "<br>Preço <br>Tolerância", field: "tolerancia", hozAlign:"center", width: 100  },
      { title: "<br>Preço <br>Mínimo<br> Máximo", field: "preco_min_max2", hozAlign:"center", width: 100 },
      { title: "<br>Preço <br>Proporcional", field: "sugestao_proporcionalidade", hozAlign:"center", width: 100 },
      { title: "<br>Preço <br>Final", field: "sugestao_final", hozAlign:"center", width: 100 },
      { title: "<br>Fator<br> Propor", field: "ftp_fator_max", hozAlign:"center", width: 120  },
      { title: "<br>Nome<br> Escala", field: "nome_escala", hozAlign:"center", width: 100 },
      { title: "<br>Posição <br>Escala", field: "posicao_escala", hozAlign:"center", width: 100 },
      { title: "<br>Porcentagem <br>Escala", field: "porcentagem_escala", hozAlign:"center", width: 100 },
      { title: "<br><br>Custo", field: "custo_do_produto", hozAlign:"center", width: 100 },
      { title: "<br><br>Custo Futuro", field: "custo_futuro", hozAlign:"center", width: 100 },
      { title: "<br>Imposto + <br>PL", field: "impostos_simulador", hozAlign:"center", width: 100, formatter: precosPorcent },  
      { title: "<br>Peso<br>Concorrente", field: "peso", hozAlign:"center", width: 100 },  
  ];

  let options = {
    layout: "fitColumns",
    dataTree: true,
    dataTreeElementColumn: "formato",
    dataTreeStartExpanded:[false, false] //start with first level expanded, second level collapsed
  };

  async function resetarTabela () {
    setLoader (true);
    const novaChave = chaveDaTabela+1;
    setChaveDaTabela(novaChave)
    limparSearchDoX ()
    setLoader (false);
  }

  async function limparSearchDoX () {
    setSearch("")
    document.getElementById("search-formulario").value = "";
  }

  async function resetarFiltros (){
    setSelectedfiltro(apiHelper.getFiltrosDefault());
    setTb([apiHelper.getFiltrosDefault()]);
    setLoader (true);
    const filtrosApi = await apiHelper.filtroDependentes(apiHelper.getFiltrosDefault());
    setTb(filtrosApi.data)  
    const totalizadoresApi = await apiHelper.totalizadores(apiHelper.getFiltrosDefault());
    setSelectAll(false);
    setTotalizadores({...totalizadoresApi.data})
    setarTabela();
    setLoader (false);     
  }

  async function rowSelectionChanged(data, rows){
    rows.forEach(function(row){
      row.reformat();
    });
  }

  async function selectableCheck(row){
    return row.selectedfiltro;
  }

  async function itensEditados (){
    setLoader (true);      
    const editados = await apiHelper.apiListaItens();
    var tabela = [];
    var adapted = {};
    editados.data.map((row, index) => {
      adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
      tabela.push (adapted)
    })
    setRows(tabela);        
    setLoader(false);    
    setEditados(true);
  }

  async function updateTable() {
    setLoader(true);
    const tabelaApi = await apiHelper.tabela(selectedfiltro);
    var tabela = [];
    var adapted = {};
    tabelaApi.data.map((row, index) => {
      adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
      tabela.push (adapted)
    })
    const totalizadoresApi = await apiHelper.totalizadores(selectedfiltro);
    setTotalizadores(totalizadoresApi.data)
    setRows(tabela)
    setLoader(false)
  }

  async function filtroBusca (e){        
    const inputText=e.target.value; 
    setSearch(inputText)        
    if(e.key !== 'Enter') {
      return;
    }
    setLoader(true);  
   
    const data = await apiHelper.search(selectedfiltro, inputText);    
    var tabela = [];
    var adapted = {};
    data.data.map((row, index) => {
      adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
      tabela.push (adapted)
    })
    setRows(tabela);        
    setLoader(false);
  }

  async function limparCamposQueNaoTiveremSelecionados () {
    setLoader(true);      
    const resetados = await apiHelper.resetarExportados();
    atualizaTotalizadores()  
    document.querySelector(".modal_close").click();
    setSelectAll(false);
    var tabela = []
    rows.map((row, index) => {
      tabela.push ({...row, checkbox: 0})
    })
    setRows(tabela);
    setLoader(false); 
  }

  async function criarTabelaDownload() {
    const dadosExportarApi = await apiHelper.tabelaDownload();
    setTabelaDownload(dadosExportarApi.data)
  }

  async function exportarDados (){
    const data = dataBR()
    if(!data) {
      return;
    }
    
    setLoader (true);      
    setExportados(true);
    const exportados = await apiHelper.apiexportarItens(data);
    const listaExportados = await apiHelper.apiItensExportados(data);
    
    var tabela = [];
    var adapted = {};
    listaExportados.data.map((row, index) => {
      adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
      tabela.push (adapted)
    })
    setRows(tabela);        
    document.querySelector(".modal_close").click();
    setLoader(false);  
    criarTabelaDownload();
  }


  function dataBR() {
    const dataCSV = document.querySelector('#data').value
    if(document.querySelector('#data').value==="") {
      Toastify({
        text: "Selecione a data para exportação",
        duration: 6000, 
        newWindow: true,
        close: true,
        stopOnFocus: true, 
        onClick: function(){          
        }
      }).showToast();
      return false;
    }
    const data = new Date(dataCSV)
    const dataDeExportacao = new Date(data.setDate(data.getDate() + 1))
    const dataExpCSV = dataDeExportacao.toLocaleDateString()
    return dataExpCSV;
}

  async function handleFilters(chave, valor) {
    if(valor.length === 0 ) {
      valor = null;  
    }
    selectedfiltro[chave] = valor     
    setSelectedfiltro({...selectedfiltro});
    const filtrosApi = await apiHelper.filtroDependentes(selectedfiltro);
    setTb(filtrosApi.data)    
  }

  
  async function filtroBandeira (e = null){
    const listaExportados = await apiHelper.paiseFilhos(selectedfiltro);
    
    var tabela = [];
    var adapted = {};
    listaExportados.data.map((row, index) => {
      adapted =  adapter.adapter(row.total, index, row.formatos, row.concorrentes);
      tabela.push (adapted)
    })
    setRows(tabela);       
  }

  
  function renderCSV (){
    if (exportados === true){
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-9 csv_col'>
              <h3 className='validados_null'>Relatório de Itens Exportados</h3>   
            </div>
            <div className='col-3 csv_col direita' onClick={criarTabelaDownload}>   
              {exportados === true  &&  <CsvDownloader  
              datas={tabelaDownload} 
              wrapColumnChar=""
              filename= {"exportacao_preco_" +  dataBR ().replaceAll("/", "-") + ".csv"}
              separator=";"
              style={{ 
              boxShadow:"inset 0px 1px 0px 0px #e184f3",
              backgroundColor:"red",
              borderRadius:"6px",
              border:"red",
              display:"inline-block",
              cursor:"pointer","color":"#ffffff",
              fontSize:"15px",
              padding:"6px 24px",
              height:"40px",
              lineHeight:"middle",
              marginTop: "12px",
              width: "320px",
              textDecoration:"none",
              textAlign:"center"                                
              }}
              >Exportar CSV</CsvDownloader >}
            </div>
          </div> 
        </div>
      )
    }
    if (editados === true){
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col'>
              <h3 className='validados_null'>Relatório de Itens Validados</h3>
            </div>
          </div> 
        </div>    
      )
    }
  }

  async function checkboxMultiplo (e) {
    setSelectAll(e.target.checked)
    var tabela = [];
    var checkin = 0
    if (e.target.checked){
      checkin=1
    }
    rows.map((row, index) => {
      tabela.push ({...row, checkbox: checkin})
    })
    setRows(tabela);
    await apiHelper.checkboxes(selectedfiltro, e.target.checked)
    atualizaTotalizadores();
  }


  function renderCheckboxes (){
    if (selectedfiltro.secao != null || selectedfiltro.departamento != null || selectedfiltro.grupo != null
      || selectedfiltro.sub_grupo != null || selectedfiltro.fornecedor != null || selectedfiltro.papel_categoria != null
      || selectedfiltro.sensibilidade != null || selectedfiltro.escala != null || selectedfiltro.tem_pesquisa != null){
      return (
        <input type='checkbox' className="checkbos_seletctall" checked={selectAll}  onClick={(e) => checkboxMultiplo(e)}></input>
      )
   } 
  }

  function renderLoarder() {
    if(loader) {
      return (
        <div className="loader_table_background">
          <div className="loader_table"></div>
        </div>
      )
    }
    return <></>;
  }
      
  function renderBackgroundColor(){
    if (exportados === true){
      return 'cinza_ selectall';
    }
    return "selectall";
  }

  function renderTabela(){
    // if(!rows.length && validados === false) {
    //   return <h3 className='validados_null box--com--sombra'>Não existem produtos validados.</h3>
    // }
    return (
      <div className={renderBackgroundColor()}>
        {renderCheckboxes()}
        <ReactTabulator
          data={rows}
          columns={columns}
          height="60vh"
          options={options}
          rowSelectionChanged={rowSelectionChanged}
          selectableCheck={selectableCheck}
          key={chaveDaTabela}
        />
      </div>
    )
  }
  return (
    <>

      {renderLoarder()}
      <form>
        <FiltroDropdown 
          filtros={tb}
          filtrosDefault={selectedfiltro}
          handle={handleFilters}
          updateTable={updateTable}
          resetarFiltros={resetarFiltros}
          filtroBusca={filtroBusca}
          itensEditados={itensEditados}
        />
        <FiltroDropdownBigNumbers 
          filtros={tb}
          filtrosDefault={selectedfiltro}
          totalizadores={totalizadores}          
          handleFilters={setarTabelaFiltrada}          
          renderCSV={renderCSV}
          salvar={salvar}
          filtroBandeira={filtroBandeira}
        />
        {renderTabela()}    
      </form>
      
      <Modal 
        resetarTabela={resetarTabela} 
        exportarDados={exportarDados}
        limparCamposQueNaoTiveremSelecionados={limparCamposQueNaoTiveremSelecionados}
      />
    </>
  )



    
}


export default Swift;