import React, { useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const Jql = (props) => {
  const user = props.user;
  const [actualJql, setActualJql] = React.useState(
    user?.orderBy ? user?.orderBy : "order by"
  );

  const fields = [
    { id: "cluster crescente", display: "cluster_simulador asc" },
    { id: "cluster decrescente", display: "cluster_simulador desc" },
    {
      id: "codigo principal proporcional crescente",
      display: "cod_pai_proporcao asc",
    },
    {
      id: "codigo principal proporcional decrescente",
      display: "cod_pai_proporcao desc",
    },
    { id: "codigo pai crescente", display: "codigo_pai asc" },
    { id: "codigo pai decrescente", display: "codigo_pai desc" },
    { id: "codigo sku crescente", display: "codigo_filhos asc" },
    { id: "codigo sku decrescente", display: "codigo_filhos desc" },
    { id: "descricao produto crescente", display: "descricao_produto asc" },
    { id: "descricao produto decrescente", display: "descricao_produto desc" },
    { id: "status produto crescente", display: "status_produto asc" },
    { id: "status produto decrescente", display: "status_produto desc" },
    {
      id: "preco vigente varejo crescente",
      display: "preco_vigente_varejo asc",
    },
    {
      id: "preco vigente varejo decrescente",
      display: "preco_vigente_varejo desc",
    },
    { id: "novo preco regular varejo crescente", display: "preco_min_max asc" },
    {
      id: "novo preco regular varejo decrescente",
      display: "preco_min_max desc",
    },
    { id: "custo crescente", display: "custo_do_produto asc" },
    { id: "custo decrescente", display: "custo_do_produto desc" },
    { id: "impostos crescente", display: "impostos_simulador asc" },
    { id: "impostos decrescente", display: "impostos_simulador desc" },
    { id: "margem objetiva crescente", display: "margem_objetiva_produto asc" },
    {
      id: "margem objetiva decrescente",
      display: "margem_objetiva_produto desc",
    },
    { id: "margem atual crescente", display: "margem_atual_regular asc" },
    { id: "margem atual decrescente", display: "margem_atual_regular desc" },
    { id: "margem nova crescente", display: "margem_nova asc" },
    { id: "margem nova decrescente", display: "margem_nova desc" },
    { id: "sensibilidade crescente", display: "sensibilidade_fornecedor asc" },
    {
      id: "sensibilidade decrescente",
      display: "sensibilidade_fornecedor desc",
    },
    { id: "variacao novo preco crescente", display: "var_novo_preco asc" },
    { id: "variacao novo preco decrescente", display: "var_novo_preco desc" },
    {
      id: "preco concorrente pricipal crescente",
      display: "preco_primario asc",
    },
    {
      id: "preco concorrente pricipal decrescente",
      display: "preco_primario desc",
    },
    {
      id: "preco concorrente secundario crescente",
      display: "preco_secundario asc",
    },
    {
      id: "preco concorrente secundario decrescente",
      display: "preco_secundario desc",
    },
    {
      id: "preco concorrente terciario crescente",
      display: "preco_terciario asc",
    },
    {
      id: "preco concorrente terciario decrescente",
      display: "preco_terciario desc",
    },
    {
      id: "preco concorrente quartenario crescente",
      display: "preco_quaternario asc",
    },
    {
      id: "preco concorrente quartenario decrescente",
      display: "preco_quaternario desc",
    },
    { id: "preco concorrente quinario crescente", display: "preco_quinto asc" },
    {
      id: "preco concorrente quinario decrescente",
      display: "preco_quinto desc",
    },
    { id: "preco concorrente senario crescente", display: "preco_sexto asc" },
    {
      id: "preco concorrente senario decrescente",
      display: "preco_sexto desc",
    },
    {
      id: "preco concorrente setenario crescente",
      display: "preco_setimo asc",
    },
    {
      id: "preco concorrente setenario decrescente",
      display: "preco_setimo desc",
    },
    {
      id: "preco concorrente octionario crescente",
      display: "preco_octonario asc",
    },
    {
      id: "preco concorrente octionario decrescente",
      display: "preco_octonario desc",
    },
    {
      id: "preco concorrente estudo crescente",
      display: "preco_concorrente_estudo asc",
    },
    {
      id: "preco concorrente estudo decrescente",
      display: "preco_concorrente_estudo desc",
    },
    {
      id: "preco medio concorrente crescente",
      display: "preco_medio_conc asc",
    },
    {
      id: "preco medio concorrente decrescente",
      display: "preco_medio_conc desc",
    },
    { id: "indice meta crescente", display: "indice_meta asc" },
    { id: "indice meta decrescente", display: "indice_meta desc" },
    {
      id: "indice regular atual crescente",
      display: "indice_regular_atual asc",
    },
    {
      id: "indice regular atual decrescente",
      display: "indice_regular_atual desc",
    },
    {
      id: "indice novo preco regular crescente",
      display: "indice_novo_preco_regular asc",
    },
    {
      id: "indice novo preco regular decrescente",
      display: "indice_novo_preco_regular desc",
    },
    { id: "departamento crescente", display: "nome_departamento asc" },
    { id: "departamento decrescente", display: "nome_departamento desc" },
    { id: "secao crescente", display: "nome_secao asc" },
    { id: "secao decrescente", display: "nome_secao desc" },
    { id: "grupo crescente", display: "nome_grupo asc" },
    { id: "grupo decrescente", display: "nome_grupo desc" },
    { id: "subgrupo crescente", display: "nome_subgrupo asc" },
    { id: "subgrupo decrescente", display: "nome_subgrupo desc" },
    {
      id: "papel categoria crescente",
      display: "papel_categoria_simulador asc",
    },
    {
      id: "papel categoria decrescente",
      display: "papel_categoria_simulador desc",
    },
    { id: "quantidade vendas regular atual crescente", display: "vendas asc" },
    {
      id: "quantidade vendas regular atual decrescente",
      display: "vendas desc",
    },
    {
      id: "quantidade vendas regular estimada crescente",
      display: "qtde_vendas_estimada asc",
    },
    {
      id: "quantidade vendas regular estimada decrescente",
      display: "qtde_vendas_estimada desc",
    },
    {
      id: "diferenca unitario crescente",
      display: "diferenca_preco_unitario asc",
    },
    {
      id: "diferenca unitario decrescente",
      display: "diferenca_preco_unitario desc",
    },
    { id: "diferenca total crescente", display: "diferenca_total asc" },
    { id: "diferenca total decrescente", display: "diferenca_total desc" },
    { id: "preco inicial crescente", display: "preco_atual_varejo asc" },
    { id: "preco inicial decrescente", display: "preco_atual_varejo desc" },
    { id: "politica pricing/mix crescente", display: "politica_pricing asc" },
    {
      id: "politica pricing/mix decrescente",
      display: "politica_pricing desc",
    },
    { id: "margem minima maxima crescente", display: "margem_min_max asc" },
    { id: "margem minima maxima decrescente", display: "margem_min_max desc" },
    { id: "preco escala crescente", display: "sugestao_escala asc" },
    { id: "preco escala decrescente", display: "sugestao_escala desc" },
    { id: "arredondamento crescente", display: "arredondamento asc" },
    { id: "arredondamento decrescente", display: "arredondamento desc" },
    { id: "preco toleranca crescente", display: "preco_toleranca asc" },
    { id: "preco toleranca decrescente", display: "preco_toleranca desc" },
    { id: "preco minimo maximo crescente", display: "preco_min_max asc" },
    { id: "preco minimo maximo decrescente", display: "preco_min_max desc" },
    { id: "preco proporcional crescente", display: "preco_proporcional asc" },
    {
      id: "preco proporcional decrescente",
      display: "preco_proporcional desc",
    },
    { id: "preco final crescente", display: "sugestao_final asc" },
    { id: "preco final decrescente", display: "sugestao_final desc" },
    { id: "fator propor min crescente", display: "ftp_fator asc" },
    { id: "fator propor min decrescente", display: "ftp_fator desc" },
    { id: "fator propor max crescente", display: "ftp_fator_max asc" },
    { id: "fator propor max decrescente", display: "ftp_fator_max desc" },
    { id: "nome escala crescente", display: "nome_escala asc" },
    { id: "nome escala decrescente", display: "nome_escala desc" },
    { id: "posicao escala crescente", display: "posicao_escala asc" },
    { id: "posicao escala decrescente", display: "posicao_escala desc" },
    { id: "porcentagem escala crescente", display: "porcentagem_escala asc" },
    {
      id: "porcentagem escala decrescente",
      display: "porcentagem_escala desc",
    },
  ];

  const [selectedFields, setSelectedFields] = useState(
    user?.orderBy
      ? user?.orderBy
          ?.split("order by")[1]
          .split(",")
          .map((item) => item.trim())
      : []
  );

  const saveOrUpdate = async () => {
    // const command = actualJql.replace(/\[/g, "").replace(/\]/g, "");
    const command = `order by ${selectedFields.join(", ")}`;
    const data = await fetch(`${user.apiurl}jql/${user.dbschema}`, {
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        order: command,
        user: user.user.id,
      }),
    });
    window.location.reload();
  };

  const removeJql = async () => {
    const data = await fetch(
      `${user.apiurl}jql/delete/${user.dbschema}/${user.user.id}`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "GET",
      }
    );
    window.location.reload();
  };

  return (
    <>
      <div className="modal-body">
        {/* <span
          onClick={() => {
          }}
        >
          Para começar a preencher os campos basta digitar @
        </span> */}

        <Select
          multiple
          value={selectedFields}
          onChange={(e) => {
            setSelectedFields(e.target.value);
          }}
          placeholder="Selecionar Ordenação"
        >
          {fields.map((field) => (
            <MenuItem key={field.id} value={field.display}>
              {field.id}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="modal-footer">
        <div className="col-md-4">
          <button className="btn btn-dark" onClick={() => saveOrUpdate()}>
            {user?.orderBy ? "Atualizar" : "Salvar"}
          </button>
        </div>
        <div className="col-md-4">
          {user?.orderBy && (
            <button className="btn btn-dark" onClick={() => removeJql()}>
              Remover
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Jql;
