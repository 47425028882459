import RangeSlider from "../RangeSlider";
import DateRange from "../DateRange";
import React from "react";

export function renderDropDownModule(
  languages,
  lang,
  showSlider,
  slider,
  handleFiltrosSlider,
  user,
  filtrarRangeData,
  setSliderDate,
  filtrarSlider,
  sliderValores,
) {
  if (!showSlider) {
    return;
  }

  if (!slider?.dataInicial instanceof Date) {
    return;
  }

  return (
    <div className="drop slider-item">
      <p className="filtro_menu">
        &nbsp; {languages[lang].diferenca_margem_regular}
      </p>
      <button
        type="button"
        className="btn btn-info btn_slider"
        onClick={(e) => filtrarSlider(1)}
      >
        Buscar
      </button>
      <RangeSlider
        sliderNumero="1"
        min={slider?.diferenca_total_minimo}
        labelMin={parseFloat(slider?.diferenca_total_minimo)
          .toFixed(1)
          .toLocaleString(undefined, { maximumFractionDigits: 2 })}
        max={slider.diferenca_total_maximo}
        labelMax={parseFloat(slider.diferenca_total_maximo)
          .toFixed(1)
          .toLocaleString(undefined, { maximumFractionDigits: 2 })}
        handleFiltrosSlider={handleFiltrosSlider}
        inicial={sliderValores[0].abaixo_de}
        final={sliderValores[0].acima_de}
      />
      <p className="filtro_menu">
        &nbsp; {languages[lang].margem_regular_nova}
      </p>
      <button
        type="button"
        className="btn btn-info btn_slider"
        onClick={(e) => filtrarSlider(2)}
      >
        Buscar
      </button>
      <RangeSlider
        sliderNumero="2"
        min={slider.nova_margem_minimo}
        labelMin={parseFloat(slider.nova_margem_minimo)
          .toFixed(1)
          .toLocaleString(undefined, { maximumFractionDigits: 2 })}
        max={slider.nova_margem_maximo}
        labelMax={parseFloat(slider.nova_margem_maximo)
          .toFixed(1)
          .toLocaleString(undefined, { maximumFractionDigits: 2 })}
        porcentagem={true}
        handleFiltrosSlider={handleFiltrosSlider}
        inicial={sliderValores[1].abaixo_de}
        final={sliderValores[1].acima_de}
      />
      <p className="filtro_menu">&nbsp; {languages[lang].variacao_preco}</p>
      <button
        type="button"
        className="btn btn-info btn_slider"
        onClick={(e) => filtrarSlider(3)}
      >
        Buscar
      </button>
      <RangeSlider
        sliderNumero="3"
        min={slider.variacao_novo_preco_minimo}
        labelMin={parseFloat(slider.variacao_novo_preco_minimo)
          .toFixed(1)
          .toLocaleString(undefined, { maximumFractionDigits: 2 })}
        max={slider.variacao_novo_preco_maximo}
        labelMax={parseFloat(slider.variacao_novo_preco_maximo)
          .toFixed(1)
          .toLocaleString(undefined, { maximumFractionDigits: 2 })}
        handleFiltrosSlider={handleFiltrosSlider}
        inicial={sliderValores[2].abaixo_de}
        final={sliderValores[2].acima_de}
      />
      {user.dbschema === "bigbox" || user.dbschema === "redetop" ? (
        <div>
          <p className="filtro_menu data_range">&nbsp; Data Última Entrada</p>
          <button
            type="button"
            className="btn btn-info btn_slider"
            onClick={filtrarRangeData}
          >
            Buscar
          </button>
          <div className="container">
            <div className="dataleft">
              <DateRange
                data={slider?.dataInicial}
                handle={setSliderDate}
                chave="data_ultimo_custo_minimo"
              />
            </div>
            <div className="dataleft">
              <DateRange
                className="data_final"
                data={slider?.dataFinal}
                handle={setSliderDate}
                chave="data_ultimo_custo_maximo"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
