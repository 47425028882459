import React, { useContext, useState, useEffect } from "react";

import bgImage from "./assets/bg.png";
import card1 from "./assets/btn_01.png";
import card2 from "./assets/btn_02.png";
import card3 from "./assets/btn_03.png";
import logo from "./assets/logo_pricemet.png";
import GlobalState from "../../contexts/GlobalState";

export default function Varejo() {
  const state = useContext(GlobalState)[0];
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    state?.value?.user?.id === 45 && setIsValid(true);
  }, [state]);

  async function linkToDashboard(email) {
    const requestClick = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    };

    const response = await (
      await fetch(`${process.env.REACT_APP_API}/api/v2/qlik-url`, requestClick)
    ).json();
    if (response) {
      return window.open(response.url);
    } else {
      alert(`HTTP-Error: ${response.status} - ${response.statusText}`);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 4.4rem)",
        marginTop: "-1.2rem",
        backgroundImage: `url(${bgImage})`,
        objectFit: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "60%",
          position: "relative",
          objectFit: "cover",
        }}
      >
        <img src={card1} alt="card" style={{ height: "100%" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            position: "absolute",
            bottom: "3rem",
          }}
        >
          <a
            href="/tabela"
            style={{
              color: "white",
              fontSize: "3rem",
              padding: "1rem 4rem",
              borderRadius: "4rem",
              textDecoration: "none",
              backgroundColor: "#253b6d",
            }}
          >
            Gestão de Preços
          </a>
        </div>
      </div>
      <div
        style={{
          height: "60%",
          position: "relative",
          objectFit: "cover",
        }}
      >
        <img src={card2} alt="card" style={{ height: "100%" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            position: "absolute",
            bottom: "3rem",
          }}
        >
          {isValid && (
            <a
              href="https://demo.prixsia.com.br/authentication-demo"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                fontSize: "3rem",
                padding: "1rem 4rem",
                borderRadius: "4rem",
                textDecoration: "none",
                backgroundColor: "#253b6d",
              }}
            >
              Promo
            </a>
          )}
          {!isValid && (
            <a
              href="#"
              rel="noopener noreferrer"
              style={{
                color: "white",
                fontSize: "3rem",
                padding: "1rem 4rem",
                borderRadius: "4rem",
                textDecoration: "none",
                backgroundColor: "#253b6d",
                opacity: "0.2",
              }}
            >
              Promo
            </a>
          )}
        </div>
      </div>
      <div
        style={{
          height: "60%",
          position: "relative",
          objectFit: "cover",
        }}
      >
        <img src={card3} alt="card" style={{ height: "100%" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            position: "absolute",
            bottom: "3rem",
          }}
        >
          <div
            onClick={() => linkToDashboard(state.value.user.email)}
            style={{
              cursor: "pointer",
              fontSize: "3rem",
              padding: "1rem 4rem",
              color: "white",
              borderRadius: "4rem",
              textDecoration: "none",
              backgroundColor: "#253b6d",
            }}
          >
            Analytics
          </div>
        </div>
      </div>
      <img
        src={logo}
        alt="logo"
        style={{ position: "absolute", bottom: "0", height: "5rem" }}
      />
    </div>
  );
}
