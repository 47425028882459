import axios from "axios";

if (!process.env.REACT_APP_API) {
  throw new Error("DEV Alert: You must inform API Base URL in .env file");
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 60 * 1000, // Timeout
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
