import * as Calculadora from "../../Helpers/CalculadoraPricing";
import { SetasNovoPrecoAtacadoModule } from "../../components/Table/setasNovoPrecoAtacado";
import { SetasNovoPrecoModule } from "../../components/Table/setasNovoPreco";
export function rowsAdapterModule(rows, atacado = false) {
  rows.map((row, index) => {
    let valor = row.sugestao_final; //! var
    let valor_atacado = row.novo_preco_atacado; //! var
    if (row.preco_decisao) {
      valor = row.preco_decisao;
    }
    if (row.preco_decisao_atacado) {
      valor_atacado = row.preco_decisao_atacado;
    }

    if (isNaN(valor)) {
      valor = row[index].sugestao_final;
      rows[index].preco_decisao_atacado =
        rows[index].preco_decisao_atacado_default;
      rows[index].preco_decisao_atacado =
        rows[index].preco_decisao_atacado_default;
    }
    if (isNaN(valor_atacado)) {
      valor_atacado = rows[index].novo_preco_atacado;
    }

    const dataString = row.data_ultimo_custo.split("/");
    rows[
      index
    ].data_ultimo_custo_ordernacao = `${dataString[2]}/${dataString[1]}/${dataString[0]}`;

    rows[index].pesquisasExibiPosicao = {};
    rows[index].variacao_preco_novo = rows[index].var_novo_preco;
    rows[index].sugestao_sistema_atacado_default =
      rows[index].preco_decisao_atacado;
    rows[index].indiceatual = rows[index].indice_regular_atual;
    rows[index].indice_novo_preco = rows[index].indice_novo_preco_regular;
    rows[index].diferenca_unitario = rows[index].diferenca_preco_unitario;
    rows[index].diferencatotal = rows[index].diferenca_preco_total;
    rows[index].preco_medio_conc = Calculadora.PrecoMedioConc(row);
    rows[index].qtde_vendas_estimada = rows[index].qtde_vendas_estimada;
    rows[index].margem_objetiva_produto = parseFloat(
      row.margem_objetiva_produto
    );

    rows[index].seta = SetasNovoPrecoModule(valor, row);
    rows[index].seta_atacado = SetasNovoPrecoAtacadoModule(valor_atacado, row);
  });
  return rows;
}
