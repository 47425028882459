import React from "react";
export function renderConcorrenteModule(row, posicao, user) {
  if (!!row.pesquisasExibiPosicao[posicao] === false) {
    return;
  }
  if (row.pesquisas.length === 0) {
    return (
      <p className="tooltiptext concorrente box--com--sombra">
        Não existe histórico de pesquisas para esse item.
      </p>
    );
  }
  return (
    <p className="tooltiptext concorrente box--com--sombra">
      Concorrente: {row.pesquisas[0].nome_concorrente_pesquisa}
      <br></br>
      {row.pesquisas.map((precoConc, index) => (
        <>
          <hr className="linha"></hr>
          Data da Pesquisa: {precoConc.data_pesquisa}
          <br></br>
          Valor:{" "}
          <span
            className={
              precoConc.promocao_s_ou_n.toLowerCase() + "concorrente_cor"
            }
          >
            {precoConc.preco_pesquisa}
            <br></br>
          </span>
          Promoção: {precoConc.promocao_s_ou_n}
          <br></br>
          {["giassi", "floresta"].includes(user) &&
            `Marca: ${precoConc.marca !== undefined ? precoConc.marca : ""}`}
          {["peruzzo", "lopes"].includes(user) &&
            `Preço Atacado: ${
              precoConc.preco_atacado_concorrente !== (undefined || null)
                ? precoConc.preco_atacado_concorrente
                : ""
            }`}
          <br></br>
          {["peruzzo", "lopes"].includes(user) &&
            `Quantidade: ${
              precoConc.qtde_atacado !== (undefined || null)
                ? precoConc.qtde_atacado
                : ""
            }`}
          {user === "vivenci" &&
            `Preço Fidelidade: ${
              precoConc.preco_fidelidade !== (undefined || null)
                ? precoConc.preco_fidelidade
                : ""
            }`}
          <hr className="linha"></hr>
        </>
      ))}
    </p>
  );
}
