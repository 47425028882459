import React, { useState } from "react";
import "./App.css";
import GlobalState from "./contexts/GlobalState";
import Controller from "./components/Controller";
import { I18n } from "aws-amplify";
import { Hub, Logger } from "aws-amplify";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//import { createTheme, ThemeProvider } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";
// import "toastify-js/src/toastify.css";
import { AuthProvider } from "./contexts/AuthContext";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ptBR
);

const logger = new Logger("My-Logger");

const listener = async (data) => {
  switch (data.payload.event) {
    case "signIn":
      window.location.href = "/agendamentos";
      break;
    case "signUp":
      window.location.href = "/agendamentos";
      break;
    case "signOut":
      window.location.href = "/";
      break;
    case "signIn_failure":
      logger.error("user sign in failed");
      break;
    default:
      console.error("error");
  }
};

Hub.listen("auth", listener);

I18n.setLanguage("br");
const dict = {
  br: {
    "Sign In": "Acessar",
    "Sign in to your account": "Acessar sua conta",
    "Sign in with AWS": "Acessar com conta da aws",
    "Incorrect username or password": "Senha ou usuário inválido",
    "Reset password": "Redefinir senha",
    "Forgot your password?": "Esqueceu sua senha? ",
    "No account?": " ",
    "Create account": " ",
    "Password ": "Senha *",
    "Username ": "Usuário *",
    "Enter your username": "Insira seu Usuário",
    "Enter your password": "Digite sua Senha",
    "Create a new account": "Criar uma Conta Nova",
    "Username *": "Usuário ",
    Username: "Usuário ",
    "Phone Number *": "Telefone",
    "Password *": "Senha",
    Password: "Senha",
    "Email Address *": "E-mail",
    Email: "E-mail",
    "(555) 555-1212": "(xx) 9999-9999",
    "Have an account?": "Possui uma Conta?",
    "Sign in": "Acessar",
    "Create Account": "Criar Conta",
    "Confirm Sign up": "Confirmar Inscrição",
    "Confirmation Code": "Código de Verificação",
    "Enter your code": "Digite seu Código",
    "Lost your code? ": "Esqueceu seu Código?",
    "Resend Code": "Reenviar Código",
    "Back to Sign In": "Acessar Conta",
    Confirm: "Confirmar",
    "Reset your password": "Redefinir Senha",
    "Send Code": "Enviar Código",
    "Password did not conform with policy: Password not long enough":
      "Sua senha deve conter no mínimo 8 caracteres ",
    "Incorrect username or password.": "Senha incorreta.",
    "Add Profile Photo": "Adicionar foto de perfil",
    "Preview the image before upload": " ",
    "Tap to image select": "Clique para selecionar a imagem",
    "User does not exist.": "Usuário não encontrado",
    "Change Password": "Alterar senha",
    "New password": "Nova senha",
    CHANGE: "Alterar",
    "Enter your new password": "Digite sua nova senha",
  },
};

I18n.putVocabularies(dict);

function App() {
  const [state, setState] = useState({});
  return (
    <AuthProvider>
      <GlobalState.Provider value={[state, setState]}>
        <ThemeProvider theme={theme}>
          <Controller />
        </ThemeProvider>
      </GlobalState.Provider>
      {/* // <Home></Home> */}
    </AuthProvider>
  );
}

export default App;
