import React from "react";
import Jql from "./Table/jql";
import SelectAllTransferList from "./Transfer";
import Carousel from "./Carousel";

function Modal(props) {
  const [photos, setPhotos] = React.useState();
  const competitors = props.photoCarousel && Object.keys(props.photoCarousel);

  return (
    <>
      <div className="background-modal"></div>

      <div
        className="modal fade"
        id="modalSalvar"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal_tit">Salvar alterações?</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button type="button" className="btn btn-primary">
                Confimar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalLimpar"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal_tit">Limpar todas as alterações do dia?</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={props.limparCamposQueNaoTiveremSelecionados}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalLimparBloqueados"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal_tit">
                Limpar todos itens bloqueados do dia?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={props.apiLimpezaBloqueados}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalDefault"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal_tit">Exibir tabela em padrão inicial?</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={props.resetarTabela}
              >
                Confimar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalTodas"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title modal_titulo" id="exampleModalLabel">
                Deseja exportar todas os itens alterados?
              </h5>
              {/* <ListaEditados/> */}
              <button
                type="button"
                className="close modal_close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <label htmlFor="birthday">
                As exportações serão válidas a partir de: &nbsp;
              </label>
              <input type="date" id="data" name="data" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  props.exportarDados();
                  // props.user.dbschema === "asun" && props.exportarDadosApiAsun();
                  // props.user.dbschema === "semar" &&
                  //   props.exportarDadosApiSemar();
                }}
              >
                Exportar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="startDownload"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title modal_titulo" id="exampleModalLabel">
                Download Tabela de Preços
              </h5>
              <button
                type="button"
                className="close modal_close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Arquivo sendo gerado.</p>
              <p>Seu download iniciará em instantes...</p>
              {/* <sub>Após o download essa janela pode ser fechada.</sub> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="order-by"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title modal_titulo" id="exampleModalLabel">
                Ordenar Tabela Por:
              </h5>
              <button
                type="button"
                className="close modal_close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Jql user={props.user} />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="transfer"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ marginTop: "10rem" }}
      >
        <div
          id="modal-transfer"
          className="modal-dialog modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title modal_titulo" id="exampleModalLabel">
                Ordenar
              </h5>
              <button
                id="modal-order-close"
                type="button"
                className="close modal_close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <SelectAllTransferList
              filtroBandeira={props.filtroBandeira}
              user={props.user}
            />
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="photo-carousel"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ marginTop: "10rem" }}
      >
        <div
          id="modal-transfer"
          className="modal-dialog modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3>Fotos de Concorrentes</h3>
              <button
                id="modal-order-close"
                type="button"
                className="close modal_close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setPhotos([])}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {competitors && competitors.length > 0 ? (
              <Carousel
                photoCarousel={props.photoCarousel && props.photoCarousel}
                photos={photos}
                setPhotos={setPhotos}
                competitors={competitors}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <h3>Não existem fotos para esse concorrente</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
