import React from "react";
import { sanitalize } from "../../shared/utils/sanitalize";

export function SetasNovoPrecoAtacadoModule(valor, row) {
  const preco_atual_atacado = sanitalize(row.preco_regular_atacado);
  const valor_alterado = sanitalize(valor);
  if (valor_alterado > preco_atual_atacado) {
    return (
      <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
    );
  }
  if (valor_alterado < preco_atual_atacado) {
    return (
      <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
    );
  }
  if (valor_alterado == preco_atual_atacado) {
    return (
      <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
    );
  }
}
