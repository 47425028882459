import React from "react";
import { sanitalize } from "../../shared/utils/sanitalize";

export function SetasNovoPrecoModule(valor, row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const preco_decisao = sanitalize(row.preco_decisao);
  const sugestao_final = sanitalize(row.sugestao_final);
  const price_to_compare = preco_decisao ? preco_decisao : sugestao_final;
  
  if (valor > preco_atual_varejo) {
    return (
      <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
    );
  }
  if (valor < preco_atual_varejo) {
    return (
      <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
    );
  }
  if (valor == preco_atual_varejo) {
    return (
      <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
    );
  }
  if (preco_atual_varejo > price_to_compare) {
    return (
      <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
    );
  }
  if (preco_atual_varejo < price_to_compare) {
    return (
      <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
    );
  }
  if (preco_atual_varejo === price_to_compare) {
    return (
      <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
    );
  }
}
