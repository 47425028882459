import { flagsCamilo } from "../constants/flagscamilo.constants";

export function possuiBandeiraCamilo(schema, selectedfiltro, flag) {
  const dbschema = ["camilo", "camilo_campeao"].includes(schema);
  if (dbschema && flag) {
    return selectedfiltro?.bandeira?.includes(flag);
  } else {
    return flagsCamilo.some((flagName) =>
      selectedfiltro?.bandeira?.includes(flagName)
    );
  }
}
