import React, { useContext, useEffect } from "react";
import {useAuth} from '../contexts/AuthContext'
import GlobalState from "../contexts/GlobalState";


function Header({changeLanguage}) {
  const [state, setState] = useContext(GlobalState);
const {user} =useAuth()
  useEffect(() => {
    async function fetchUser() {
      

      const response = await fetch(
        process.env.REACT_APP_API+"/api/users/config/" + user.email

        //"https://apipp.prixsia.com/api/users/config/" +
        //  Auth.user.attributes.email
      );
      const data = await response.json();
      await setState((state) => ({ ...state, value: data.data }));
    }

    fetchUser();
  }, [setState, user.email]);

  function openNav() {
    const element = document.getElementById("mySidenav");
    const wrapContent = document.getElementById("wrap--content");
    if (!element) {
      return;
    }
    element.classList.toggle("open");
    wrapContent.classList.toggle("wrap--content--open");
  }

  function renderImg64() {
    if (state.value) {
      return (
        <img
          className="cliente"
          src={"data:image/jpeg;base64," + state.value.empresa.logo}
          alt={`logo-${state.value.empresa.nome.toLowerCase()}`}
        ></img>
      );
    }
  }

  return (
    <nav className="header">
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 col-md-3">
            <div className="pagina">
              <img
                style={{ cursor: "pointer" }}
                className="prixsia_img"
                onClick={openNav}
                src="/image/prixsia_menu.png"
                alt="some value"
              ></img>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <div className="cliente_logo">{renderImg64()}</div>
          </div>
          <div className="col-2 col-offset-2">
            <div className="offset-md-8 p-3 mt-4">
              <span className="" 
                style={{cursor: 'pointer'}}
                onClick={() => changeLanguage('pt')}
              >
                <span class="fi fi-br"></span>
                </span> 
              &nbsp;
              <span className="" 
                style={{cursor: 'pointer'}}
                onClick={() => changeLanguage('es')}
              >
                <span class="fi fi-es"></span>
              </span>
            </div>
          </div>
          <div className="col-3 col-md-3">
            <div className="usuario">
              {state.value && <p>Olá, {state.value.user.name}</p>}
              {state.value && (
                <img
                  className="avatar"
                  alt={`logo-${state.value.empresa.nome.toLowerCase()}`}
                  src={"data:image/jpeg;base64," + state.value.user.image}
                ></img>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
