import React from "react";
import language from '../../languages.json'

export function renderExportButtonModule(user, lang) {

  if (user.user.idexport === "") {
    return;
  }
  return (
    <div className="col mt-2 mt-xl-0 align-self-center">
      <button
        type="button"
        className="btn btn-danger btn_actions vermelho_f"
        style={{ width: "100%" }}
        data-toggle="modal"
        data-target="#modalTodas"
      >
        {language[lang].exportar_itens_selecionados}
      </button>
    </div>
  );
}
