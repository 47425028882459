function formatterPrice(price) {
  if(!price || price === "") {
    return '<span></span>';
  }
  return parseFloat(price).toFixed(2);
}

export function getChildren(linha) {
    var children = [];
    if(!linha.formatos) {
      return [];
    }
    linha.formatos.map((formato) => {
      
      const rowAdapted =          {
        cluster_simulador: formato.cluster_simulador,
        codigo_pai: formato.codigo_pai,            
        formato: formato.formato,            
        preco_semana1: parseFloat(formato.indice_semana_1*100).toFixed(1)+ "%",
        preco_semana2: parseFloat(formato.indice_semana_2*100).toFixed(1)+ "%",
        preco_semana3: parseFloat(formato.indice_semana_3*100).toFixed(1)+ "%",
        preco_semana4: parseFloat(formato.indice_semana_4*100).toFixed(1)+ "%",
        indice_sugerido_formato: parseFloat(formato.indice_sugerido_formato*100).toFixed(1),
      } ;
      var concorrenteChildren = [];
      linha.concorrentes.map((concorrente) => {
        if(formato.formato !== concorrente.formato) {
          return;
        }

        const concorrenteAdapted = {
          nome_do_concorrente: concorrente.nome_concorrente,
          cluster_simulador: concorrente.cluster_simulador,
          codigo_pai: concorrente.codigo_pai,            
          formato: concorrente.formato,            
          preco_semana1: formatterPrice(concorrente.preco_semana_1),
          preco_semana2: formatterPrice(concorrente.preco_semana_2),
          preco_semana3: formatterPrice(concorrente.preco_semana_3),
          preco_semana4: formatterPrice(concorrente.preco_semana_4),
          foto_range_semana1: concorrente.foto_range_semana1,
          foto_range_semana2: concorrente.foto_range_semana2,
          foto_range_semana3: concorrente.foto_range_semana3,
          foto_range_semana4: concorrente.foto_range_semana4,
          foto_promo_semana1: concorrente.foto_promo_semana1,
          foto_promo_semana2: concorrente.foto_promo_semana2,
          foto_promo_semana3: concorrente.foto_promo_semana3,
          foto_promo_semana4: concorrente.foto_promo_semana4,
          foto_validade_semana1: concorrente.foto_validade_semana1,
          foto_validade_semana2: concorrente.foto_validade_semana2,
          foto_validade_semana3: concorrente.foto_validade_semana3,
          foto_validade_semana4: concorrente.foto_validade_semana4,
          foto_ruptura_semana1: concorrente.foto_ruptura_semana1,
          foto_ruptura_semana2: concorrente.foto_ruptura_semana2,
          foto_ruptura_semana3: concorrente.foto_ruptura_semana3,
          foto_ruptura_semana4: concorrente.foto_ruptura_semana4,
          foto_do_produto_semana1: concorrente.foto_do_produto_semana1,
          foto_do_produto_semana2: concorrente.foto_do_produto_semana2,
          foto_do_produto_semana3: concorrente.foto_do_produto_semana3,
          foto_do_produto_semana4: concorrente.foto_do_produto_semana4,      
          peso: concorrente.peso,
          indice_sugerido_formato: parseFloat(concorrente.indice_sugerido_formato*100).toFixed(1) 
          
        } ;
        concorrenteChildren.push(concorrenteAdapted);
      });
      rowAdapted._children = concorrenteChildren
      children.push(rowAdapted);
    });
    return children;
}


export function adapter(row, index, formatos = null, concorrentes = null) {
    var fatorDaApi = row.fator_atacado_simulador;
    var precoApi = row.preco_min_max;
    if(row.fator_multiplicacao && row.fator_multiplicacao !== "0") {
      fatorDaApi = row.fator_multiplicacao;
    }
    if(row.preco_decisao && row.preco_decisao !== "0") {
      precoApi = row.preco_decisao;
    }

    try {    
      var adapted =  {
        id: index,
        checkbox: row.analizado,
        cluster_simulador: row.cluster_simulador,
        codigo_pai: row.codigo_pai,
        flag_pai_ou_filho: row.flag_pai_ou_filho,
        cod_pai_proporcao: row.cod_pai_proporcao,
        codigo_filhos: row.codigo_filhos,
        descricao_produto: row.descricao_produto,
        dias_de_estoque: row.dias_de_estoque,
        preco_atual_varejo: row.preco_atual_varejo,
        preco_min_max: parseFloat(precoApi).toFixed(2),
        preco_default: row.preco_min_max,
        var_novo_preco: row.var_novo_preco,
        nome_do_concorrente: row.nome_do_concorrente,
        formato: row.formato,
        preco_semana1: parseFloat(row.preco_semana1*100).toFixed(1)+ "%",
        preco_semana2: parseFloat(row.preco_semana2*100).toFixed(1)+ "%",
        preco_semana3: parseFloat(row.preco_semana3*100).toFixed(1)+ "%",
        preco_semana4: parseFloat(row.preco_semana4*100).toFixed(1)+ "%",
        foto_range_semana1: row.foto_range_semana1,
        foto_range_semana2: row.foto_range_semana2,
        foto_range_semana3: row.foto_range_semana3,
        foto_range_semana4: row.foto_range_semana4,
        foto_promo_semana1: row.foto_promo_semana1,
        foto_promo_semana2: row.foto_promo_semana2,
        foto_promo_semana3: row.foto_promo_semana3,
        foto_promo_semana4: row.foto_promo_semana4,
        foto_validade_semana1: row.foto_validade_semana1,
        foto_validade_semana2: row.foto_validade_semana2,
        foto_validade_semana3: row.foto_validade_semana3,
        foto_validade_semana4: row.foto_validade_semana4,
        foto_ruptura_semana1: row.foto_ruptura_semana1,
        foto_ruptura_semana2: row.foto_ruptura_semana2,
        foto_ruptura_semana3: row.foto_ruptura_semana3,
        foto_ruptura_semana4: row.foto_ruptura_semana4,
        foto_do_produto_semana1: row.foto_do_produto_semana1,
        foto_do_produto_semana2: row.foto_do_produto_semana2,
        foto_do_produto_semana3: row.foto_do_produto_semana3,
        foto_do_produto_semana4: row.foto_do_produto_semana4,
        foto_preco: row.foto_preco,
        margem_atual_regular: row.margem_atual_regular,
        margem_nova: row.margem_nova,
        margem_nova_30d: row.margem_nova_30d,
        indice_sugerido_formato: parseFloat(row.indice_sugerido_formato*100).toFixed(1),
        sensibilidade_simulador: row.sensibilidade_simulador,
        nome_departamento: row.nome_departamento,
        nome_secao: row.nome_secao,
        nome_subgrupo: row.nome_subgrupo,
        nome_grupo: row.nome_grupo,
        papel_categoria_simulador: row.papel_categoria_simulador,
        vendas: row.vendas,
        fator_atacado_simulador: fatorDaApi,
        qtde_vendas_estimada: row.qtde_vendas_estimada,
        diferenca_preco_unitario: row.diferenca_preco_unitario,
        diferenca_preco_total: row.diferenca_preco_total,
        preco_atual_varejo2: row.preco_atual_varejo,
        politica_pricing: row.politica_pricing,
        margem_min_max: row.margem_min_max,
        sugestao_escala: row.sugestao_escala,
        tolerancia: row.tolerancia,
        sugestao_arredondamento: row.sugestao_arredondamento,
        preco_min_max2: row.preco_min_max,
        sugestao_proporcionalidade: row.sugestao_proporcionalidade,
        sugestao_final: row.sugestao_final,
        ftp_fator_max: row.ftp_fator_max,
        nome_escala: row.nome_escala,
        posicao_escala: row.posicao_escala,
        porcentagem_escala: row.porcentagem_escala,
        custo_do_produto: row.custo_do_produto,
        custo_futuro: row.custo_futuro,
        impostos_simulador: row.impostos_simulador,       
      }
    } catch (error) {      
      console.error(error)
    }
    if(formatos && formatos.length > 0) {
      adapted._children = getChildren({formatos: formatos,concorrentes: concorrentes});
    }
    return adapted;
  }