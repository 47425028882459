import React from "react";
import { mostraZero } from "../../shared/utils/mostraZero";
import { sanitalize } from "../../shared/utils/sanitalize";
import { pesoFilho } from "../../shared/utils/pesoFilho";

export function renderInputModule(
  row,
  index,
  bloqueados,
  exportar,
  editados,
  filhos,
  bindDosValores,
  proxCampo
) {
  if (bloqueados === false) {
    if (
      (exportar === true && row.analizado == 1) ||
      editados === true ||
      bloqueados === true ||
      row.analizado == 2
    ) {
      return (
        <span className="input_filhos">
          {mostraZero(sanitalize(row.preco_decisao)) ||
            mostraZero(sanitalize(row.preco_min_max)) ||
            0}{" "}
        </span>
      );
    }
    if (
      row.flag_pai_ou_filho === "FILHO" ||
      filhos === "FILHO" ||
      exportar === true ||
      bloqueados === true ||
      row.analizado == 2
    ) {
      return (
        <span className="input_filhos">
          {" "}
          {mostraZero(sanitalize(row.preco_decisao)) ||
            mostraZero(sanitalize(row.sugestao_final)) ||
            0}{" "}
        </span>
      );
    }
  }
  return (
    <>
      <input
        className={
          pesoFilho(row.flag_pai_ou_filho) + " type_valor " + "render_input_1"
        }
        type="number"
        onKeyPress={(e) => proxCampo(e, index, false, "render_input_1")}
        onChange={(e) => bindDosValores(e, index)}
        placeholder={mostraZero(sanitalize(row.sugestao_final)) || 0}
        value={row.preco_decisao}
        autoComplete="off"
        // onKeyDown={(evt) =>
        //   ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
        // }
        name="valor-input"
        required
        size="5"
        key={row.id + "rwx"}
      />
    </>
  );
}
