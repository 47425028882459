import React, { useState } from "react";
import MultipleSelect from "./Mult";
import "react-tabulator/lib/styles.css";

function RenderDropdown(props, language) {
  const [showFilter, setShowFilter] = useState(false);
  const [showSliders, setShowSliders] = useState(false);
  const [search, setSearch] = useState("");
  const [lang, setLang] = useState(
    window.sessionStorage.getItem("language") || "pt"
  );

  function renderFilters(filter, descricao) {
    if (!props.filtros || !props.filtros[0][filter]) {
      return (
        <select
          className="opcao"
          name={filter}
          id={filter}
          multiple
          value={props.filtros[filter] || ""}
        >
          <option value="">{descricao}</option>
        </select>
      );
    }
    return (
      <MultipleSelect
        data={props.filtros[0][filter]}
        handle={props.handle}
        descripton={descricao}
        filter={filter}
      />
    );
  }

  function busca(e) {
    const inputText = e.target.value;
    setSearch(inputText);
    if (e.key === "Enter") {
      e.preventDefault();
      props.filtroBusca(e);
    }
  }

  async function limparSearchDoX() {
    setSearch("");
    document.getElementById("search-formulario").value = "";
    props.resetarFiltros();
  }

  function renderX() {
    if (search === "") {
      return;
    }
    return (
      <span className="search_x" onClick={limparSearchDoX}>
        X
      </span>
    );
  }

  function renderPopup() {
    if (!showFilter) {
      return;
    }

    return (
      <div className="">
        <section className="container-flex">
          <div className="item">
            {renderFilters("bandeira", language[lang].bandeira)}
            {renderFilters("cluster", language[lang].cluster)}
          </div>
          <div className="item">
            {renderFilters("secao", "Categoria")}
            {renderFilters("departamento", "Ocasião")}
          </div>
          <div className="item">
            {renderFilters("grupo", "Formato")}
            {renderFilters("sub_grupo", "Linha")}
          </div>
          <div className="item">
            {renderFilters("fornecedor", "Fornecedor")}
            {renderFilters("papel_categoria", language[lang].papel_categoria)}
          </div>
          <div className="item">
            {renderFilters("sensibilidade", language[lang].sensibilidade)}
            {renderFilters("escala", language[lang].escala)}
            <button
              type="button"
              className="btn btn-dark"
              onClick={props.updateTable}
            >
              {language[lang].filtrar_tabela}
            </button>
          </div>
        </section>
      </div>
    );
  }

  function renderSliders() {
    if (!showSliders) {
      return;
    }

    return (
      <div className="">
        <p className="filtro_menu">
          &nbsp; {language[lang].diferenca_margem_regular}
        </p>
        <button type="button" className="btn btn-info btn_slider">
          Buscar
        </button>
        {/* <RangeSlider 
                    sliderNumero="1"
                    min={slider.diferenca_total_minimo} 
                    labelMin={parseFloat(slider.diferenca_total_minimo).toLocaleString(undefined, {maximumFractionDigits: 2})}
                    max={slider.diferenca_total_maximo} 
                    labelMax={parseFloat(slider.diferenca_total_maximo).toLocaleString(undefined, {maximumFractionDigits: 2})}
                    handleFiltrosSlider={handleFiltrosSlider}
                    inicial={sliderValores[0].abaixo_de}    
                    final={sliderValores[0].acima_de}    
                /> */}
        <p className="filtro_menu">
          &nbsp;{language[lang].margem_regular_nova}
        </p>
        <button type="button" className="btn btn-info btn_slider">
          Buscar
        </button>
        {/* <RangeSlider 
                    sliderNumero="2" 
                    min={slider.nova_margem_minimo} 
                    labelMin={parseFloat(slider.nova_margem_minimo).toFixed(1).toLocaleString(undefined, {maximumFractionDigits: 2})}
                    max={slider.nova_margem_maximo} 
                    labelMax={parseFloat(slider.nova_margem_maximo).toFixed(1).toLocaleString(undefined, {maximumFractionDigits: 2})}
                    porcentagem = {true}
                    handleFiltrosSlider={handleFiltrosSlider}
                    inicial={sliderValores[1].abaixo_de}    
                    final={sliderValores[1].acima_de}  
                /> */}
        <p className="filtro_menu">&nbsp; {language[lang].variacao_preco}</p>
        <button type="button" className="btn btn-info btn_slider">
          Buscar
        </button>
        {/* <RangeSlider 
                    sliderNumero="3" 
                    min={slider.variacao_novo_preco_minimo} 
                    labelMin={parseFloat(slider.variacao_novo_preco_minimo).toFixed(1).toLocaleString(undefined, {maximumFractionDigits: 2})}
                    max={slider.variacao_novo_preco_maximo} 
                    labelMax={parseFloat(slider.variacao_novo_preco_maximo).toFixed(1).toLocaleString(undefined, {maximumFractionDigits: 2})}
                    handleFiltrosSlider={handleFiltrosSlider}
                    inicial={sliderValores[2].abaixo_de}    
                    final={sliderValores[2].acima_de}  
                /> */}
      </div>
    );
  }

  return (
    <div className="filtro">
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 col-md-2">
            <div className="filtro_titulo">
              <img
                className="filtro_tit_img"
                alt="some value"
                src="/image/filtro.png"
              ></img>
              <div className="dropdown">
                <p
                  className="filtro_tit"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  Filtro
                  <span className="glyphicon glyphicon-menu-down filtro_seta"></span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-8 col-md-8 center">
            <div className="item">
              <div className="form-group">
                <input
                  className="search"
                  id="search-formulario"
                  onKeyDown={busca}
                  placeholder="Item"
                />
                {renderX()}
              </div>
            </div>
          </div>
          <div className="col-2 col-md-2 right">
            <div className="dropdown_filtro">
              <span className="glyphicon glyphicon-indent-left slider_icon"></span>
              <p
                className="filtro_tit"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setShowSliders(!showSliders)}
              >
                Outliers
                <span className="glyphicon glyphicon-menu-down filtro_seta"></span>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{renderPopup()}</div>
        </div>
        <div className="row">
          <div className="col-12">{/* {renderSliders()} */}</div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="butt">
              <button
                type="button"
                className="btn btn-secondary btn_actions btn_size"
                data-toggle="modal"
                data-target="#modalLimpar"
              >
                {language[lang].limpar_alteracoes}
              </button>
            </div>
          </div>
          <div className="col">
            <div className="butt">
              <button
                type="button"
                className="btn btn-secondary btn_actions btn_size"
                onClick={props.resetarFiltros}
              >
                {language[lang].limpar_filtros}
              </button>
            </div>
          </div>
          <div className="col">
            <div className="butt">
              <button
                type="button"
                className="btn btn-secondary btn_actions btn_size"
                data-toggle="modal"
                data-target="#modalDefault"
                onClick={props.resetarTabela}
              >
                {language[lang].tabela_padrao}
              </button>
            </div>
          </div>
          <div className="col">
            <div className="butt">
              <button
                type="button"
                className="btn btn-secondary btn_actions btn_size"
                onClick={props.itensEditados}
              >
                {language[lang].itens_validados}
              </button>
            </div>
          </div>
          <div className="col">
            <div className="butt">
              <button
                type="button"
                className="btn btn-danger btn_actions vermelho_f btn_size export_btn"
                data-toggle="modal"
                data-target="#modalTodas"
                onClick={props.exportarDados}
              >
                {language[lang].exportar_itens_selecionados}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderDropdown;
