import React from "react";
import { mostraZero } from "../../shared/utils/mostraZero";
import { sanitalize } from "../../shared/utils/sanitalize";
import { pesoFilho } from "../../shared/utils/pesoFilho";

export function renderInputAtacadoModule3(
  row,
  index,
  bloqueados,
  exportar,
  editados,
  filhos,
  bindDosValores,
  proxCampo
) {
  const fator_atacado = Number(row.fator_3 ?? "0");

  if (bloqueados === false) {
    if (
      (exportar === true && row.analizado == 1) ||
      editados === true ||
      bloqueados === true ||
      row.analizado === 2
    ) {
      return (
        <span className="input_filhos">
          {mostraZero(sanitalize(row.novo_preco_atacado_3)) || (
            <input
              className={
                pesoFilho(row.flag_pai_ou_filho) +
                " type_valor " +
                "render_input_3"
              }
              type="number"
              value="0"
              autoComplete="off"
              name="valor-input"
              required
              disabled
              size="5"
              key={row.id + "rwx"}
              style={{
                border: "none",
                backgroundClip: "transparent",
                paddingRight: "2rem",
              }}
            />
          )}
        </span>
      );
    }

    if (
      row.flag_pai_ou_filho === "FILHO" ||
      filhos === "FILHO" ||
      exportar === true ||
      bloqueados === true ||
      row.analizado == 2 ||
      fator_atacado <= 0
    ) {
      return (
        <span className="input_filhos">
          {" "}
          {mostraZero(sanitalize(row.novo_preco_atacado_3)) || (
            <input
              className={
                pesoFilho(row.flag_pai_ou_filho) +
                " type_valor " +
                "render_input_3"
              }
              type="number"
              value="0"
              autoComplete="off"
              name="valor-input"
              required
              disabled
              size="5"
              key={row.id + "rwx"}
              style={{
                border: "none",
                backgroundClip: "transparent",
                paddingRight: "2rem",
              }}
            />
          )}
        </span>
      );
    }
  }
  return (
    <>
      <input
        className={
          pesoFilho(row.flag_pai_ou_filho) + " type_valor " + "render_input_3"
        }
        type="number"
        onKeyPress={(e) => proxCampo(e, index, true, "render_input_3")}
        onChange={(e) => bindDosValores(e, index, true)}
        placeholder={mostraZero(sanitalize(row.novo_preco_atacado_3)) || 0}
        value={row.preco_decisao_atacado_3 || ""}
        autoComplete="off"
        name="valor-input"
        required
        size="5"
        key={row.id + "rwx"}
      />
    </>
  );
}
