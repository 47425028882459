export function cor(sensib) {
  var cor = "";
  switch (sensib) {
    case "SENSÍVEL":
      cor = "amarelo";
      break;
    case "SENSIBLE":
      cor = "amarelo";
      break;
    case "NOTAVEL":
      cor = "amarelo";
      break;
    case "NOTÁVEL":
      cor = "amarelo";
      break;
    case "SUPER SENSÍVEL":
      cor = "vermelho";
      break;
    case "SÚPER SENSIBLE":
      cor = "vermelho";
      break;
    case "SUPER NOTÁVEL":
      cor = "vermelho";
      break;
    case "ULTRA NOTAVEL":
      cor = "vermelho";
      break;
    case "CARRINHO":
      cor = "vermelho";
      break;
    case "PRIMEIRO PRECO":
      cor = "cinza";
      break;
    case "MONITORAVEL":
      cor = "azul_claro";
      break;
    case "SUPERIOR NO SENSIBLE":
      cor = "azul_claro";
      break;
    case "TOP NÃO SENSÍVEL":
      cor = "azul_claro";
      break;
    case "TOP NÃO NOTAVEL":
      cor = "azul_claro";
      break;
    case "MONITORÁVEL":
      cor = "azul_claro";
      break;

    default:
      break;
  }
  return cor;
}
