import { flags } from "../constants/flags.constants";

export function possuiBandeira(selectedfiltro, flag) {
  if (flag) {
    return selectedfiltro?.bandeira?.includes(flag);
  } else {
    return flags.some((flagName) =>
      selectedfiltro?.bandeira?.includes(flagName)
    );
  }
}
